import { Routes, Route, useLocation } from "react-router-dom";
import Signup from "./Pages/Signup/Signup";
import Login from "./Pages/Login/Login";
import OTPPage from "./Pages/OtpPage/OTPPage";
import Home from "./Pages/Home/Home";
// import LeaderBoardDetailsPage from "./Pages/Home/LeaderBoardDetailsPage";

import MainLayout from "./Components/Layout/MainLayout";
// import BiggestWinner from "./Pages/BiggestWinner/BiggestWinner";
// import BiggestLooser from "./Pages/BiggestLooser/BiggestLooser";
// import GameResult from "./Pages/GameResult/GameResult";
import React, { useState, Suspense } from "react";
import { auth } from "./Utils/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
// import Landing from "./Pages/LandingPage/Landing";

import Wallet from "./Pages/Wallet/Wallet";

// import { accessLocationAPI } from "./Services/Service";
import PercentageTable from "./Pages/Wallet/PercentageTable";
// import PageDetails from "./Components/PageDetails/PageDetails";
// import { useQuery } from "react-query";
// import DetailedResult from "./Pages/GameResult/DetailedResult";

// import MultiLeaderBoard from "./Pages/GameResult/MultiLeaderBoard";
import Notification from "./Pages/Notification/Notification";
import Profile from "./Pages/Profile/Profile";
import NotFound from "./Pages/404/NotFound";
import SignupDetails from "./Pages/Signup/SignupDetails";
import WithdrawalPage from "./Pages/Deposite/WithdrawalPage";
import Degentime from "./Pages/Degentime/Degentime";
import LoginWithPhone from "./Pages/Login/LoginWithPhone";
import SignUpWithPhone from "./Pages/Signup/SignUpWithPhone";
import SignupOTPVerifyPage from "./Pages/Signup/SignupOTPVerify";
// import LogoLoader from "./Components/Loader/LogoLoader";
import { AuthContext } from "./context/AuthContext";
import SelectCryptosPage from "./Pages/Game/SelectCryptosPage";
// import CommingSoonPage from "./Pages/CommingSoonPage";
import Faq from "./Pages/Faq/Faq";
import BiggestWinnerLandingPage from "./Pages/NewLandingPage/BiggestWinnerLandingPage";
import Loader from "./Components/Loader/Loader";
import NewLandingPage from "./Pages/NewLandingPage/NewLandingPage";
import BiggestLooserLandingPage from "./Pages/NewLandingPage/BiggestLooserLandingPage";
import LandingLayout from "./Components/Layout/LandingLayout";
import TermsAndCondition from "./Pages/NewLandingPage/TermsAndCondition";
import PrivacyPolicy from "./Pages/NewLandingPage/PrivacyPolicy";
import USDCDepositePage from "./Pages/Deposite/DepositePage";
import WETHDepositePage from "./Pages/Deposite/WETHDepositePage";
import RoadMap from "./Pages/NewLandingPage/RoadMap";
import CHAMPSWithdrawalPage from "./Pages/Deposite/ChampsWithdrawal";
import DegentimeLanding from "./Pages/NewLandingPage/DegentimeLanding";
import NotificationProvider from "./context/NotificationContext";
import HowToPlayPage from "./Pages/NewLandingPage/HowToPlayPage";
import HowToPlay from "./Pages/Home/HowToPlay";
import AuthLayout from "./Components/Layout/AuthLayout";
import AboutUsPage from "./Pages/NewLandingPage/AboutUsPage";
import DrawerProvider from "./context/DrawerContext";
import { Web3OnboardProvider } from "@web3-onboard/react";
import { web3Onboard } from "./lib/blocknative_web3_onboard";

const DetailedResult = React.lazy(() =>
  import("./Pages/GameResult/DetailedResult")
);
const BiggestWinner = React.lazy(() =>
  import("./Pages/BiggestWinner/BiggestWinner")
);

const BiggestLooser = React.lazy(() =>
  import("./Pages/BiggestLooser/BiggestLooser")
);

const GameResult = React.lazy(() => import("./Pages/GameResult/GameResult"));

const LeaderBoardDetailsPage = React.lazy(() =>
  import("./Pages/Home/LeaderBoardDetailsPage")
);

const MultiLeaderBoard = React.lazy(() =>
  import("./Pages/GameResult/MultiLeaderBoard")
);

function App() {
  // const userId = sessionStorage.getItem("userId");
  // const navigate = useNavigate();
  // const [userIP, setUserIP] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [country, setCountry] = useState("India");
  // const [blockedCountries, setBlockedCountries] = useState(["India", "RU"]);
  const [phoneresult, setPhoneResult] = useState(null);
  const [user, setUser] = useState();
  const [userBalance, setUserBalance] = useState();
  const [referralCode, setReferralCode] = useState("");

  // function accessLocation() {
  //   accessLocationAPI().then((res) => {
  //     console.log("res", res.data);
  //     setBlockedCountries(res.data.map((x) => x.country));
  //   });
  // }
  // useEffect(() => {
  //   accessLocation();
  //   fetch("https://api.ipify.org?format=json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       fetch(`https://ipapi.co/${data.ip}/json/`)
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setCountry(data.country_name);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching geolocation data:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching IP address:", error);
  //     });

  // setLoading(false);
  // }, [country]);

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      const token = await user.getIdToken(true);
      sessionStorage.setItem("token", token);
      console.log("logged in and token set to sessionStorage");
      // Additional logic or token handling can be done here
    } else {
      // User is signed out
      console.log("logged out");
      // navigate("/");
      // Additional logic when the user is not authenticated
    }
  });

  // useEffect(() => {
  //   const handleWindowClose = (e) => {
  //     e.preventDefault();
  //     signOut(auth).then(() => {
  //       // localStorage.removeItem("userId")
  //       // localStorage.removeItem("userName")
  //       // localStorage.removeItem("token")
  //       // sessionStorage.clear()
  //       // navigate("/")
  //     }); // Call your logout function here
  //     // Optionally, you can show a custom confirmation message to the user before logging out:
  //     // e.returnValue = ''; // This will show a default browser confirmation dialog
  //     // return 'Are you sure you want to leave the page?'; // You can customize the message here
  //   };

  //   window.addEventListener("beforeunload", handleWindowClose);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleWindowClose);
  //   };
  // }, []);

  // if (!blockedCountries.includes(country)) {
  //   return (
  //     <div style={{ margin: "10% 40%" }}>
  //       <h2>COUNTRY NOT SUPPORTED</h2>
  //       <p>
  //         You are attempting to access CryptoChamp from a location where we do
  //         not operate. CryptoChamp is available in the US, Canada, and other
  //         select jurisdictions..
  //       </p>
  //     </div>
  //   );
  // }

  // loading ? (
  //   <>
  //     <LogoLoader />
  //   </>
  // ) : (
  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <AuthContext.Provider
        value={{
          phoneresult,
          setPhoneResult,
          user,
          setUser,
          referralCode,
          setReferralCode,
          userBalance,
          setUserBalance,
        }}
      >
        <DrawerProvider>
          <NotificationProvider>
            <Routes>
              {/* <Route path="/" element={<NewLandingPage />} /> */}
              <Route element={<LandingLayout />}>
                <Route path="/" element={<NewLandingPage />} />
                <Route
                  path="/terms-and-condition"
                  element={<TermsAndCondition />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/how-to-play" element={<HowToPlayPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
              </Route>
              {/* <Route path="/" element={<CommingSoonPage />} /> */}
              <Route path="/howtoplay" element={<HowToPlay />} />
              {/* Route with referral code */}
              <Route element={<AuthLayout />}>
                <Route path="/signup/*" element={<Signup />} />
                <Route path="/signup" element={<SignupWithReferral />} />
                <Route path="/login" element={<Login />} />
              </Route>
              <Route path="/faq" element={<Faq />} />
              <Route path="/roadmap" element={<RoadMap />} />
              <Route path="/login-with-phone" element={<LoginWithPhone />} />
              <Route path="/signup-with-phone" element={<SignUpWithPhone />} />
              <Route path="/login-verification" element={<OTPPage />} />/
              <Route
                path="/signup-otp-verify"
                element={<SignupOTPVerifyPage />}
              />
              <Route path="/signup-details" element={<SignupDetails />} />
              <Route
                path="/landing/biggestwinner"
                element={<BiggestWinnerLandingPage />}
              />
              <Route
                path="/landing/biggestloser"
                element={<BiggestLooserLandingPage />}
              />
              <Route
                path="/landing/cryptoparlay"
                element={<DegentimeLanding />}
              />
              <Route element={<MainLayout />}>
                <Route path="/home" element={<Home />} />

                <Route
                  path="/biggestwinner"
                  element={
                    <Suspense
                      fallback={
                        <>
                          <Loader />
                        </>
                      }
                    >
                      <BiggestWinner />{" "}
                    </Suspense>
                  }
                />

                <Route
                  path="/biggestloser"
                  element={
                    <Suspense
                      fallback={
                        <>
                          <Loader />
                        </>
                      }
                    >
                      <BiggestLooser />
                    </Suspense>
                  }
                />
                <Route
                  path="/mygames"
                  element={
                    <Suspense
                      fallback={
                        <>
                          <Loader />
                        </>
                      }
                    >
                      <GameResult />
                    </Suspense>
                  }
                />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/wallet/edit-profile" element={<Profile />} />
                <Route path="/notifications" element={<Notification />} />
                <Route path="/PercentageTable" element={<PercentageTable />} />
                <Route path="/deposit/usdc" element={<USDCDepositePage />} />
                <Route path="/deposit/weth" element={<WETHDepositePage />} />
                <Route path="/withdraw" element={<WithdrawalPage />} />
                <Route
                  path="/champs-withdraw"
                  element={<CHAMPSWithdrawalPage />}
                />

                <Route path="/cryptoparlay" element={<Degentime />} />
                <Route path="/select-cryptos" element={<SelectCryptosPage />} />

                <Route
                  path="/leaderboard-details/:id"
                  element={
                    <Suspense
                      fallback={
                        <>
                          <Loader />
                        </>
                      }
                    >
                      <LeaderBoardDetailsPage />
                    </Suspense>
                  }
                />

                {/* <Route path="/detailedresult" element={<DetailedResult />}/> */}
                <Route
                  path="/detailedresult"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <DetailedResult />
                    </Suspense>
                  }
                />
                <Route
                  path="/multiplayer-leaderboard"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <MultiLeaderBoard />
                    </Suspense>
                  }
                />
                <Route
                  path="/headtohead-leaderboard"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <MultiLeaderBoard />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </NotificationProvider>
        </DrawerProvider>
      </AuthContext.Provider>
    </Web3OnboardProvider>
  );
  // );
}

const SignupWithReferral = () => {
  const location = useLocation();
  // Extracting the referral code from the query parameter
  const referredCode = new URLSearchParams(location.search).get("referral");

  // Render Signup component with or without referral code
  return <Signup referredCode={referredCode} />;
};

export default App;
