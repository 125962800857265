import React, { useContext, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import "../../Styles/register.css";
import { auth, googleAuth } from "../../Utils/firebaseConfig";
import "react-phone-input-2/lib/style.css";

import {
  checkEmail,
  checkWalletAddress,
  createCustomToken,
  updateMagicWalletAddress,
} from "../../Services/Service";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { signInWithCustomToken } from "firebase/auth";
import { AuthContext } from "../../context/AuthContext";
import { FooterSection } from "../../Components/Auth/FooterSection";
import SocialAuthButton from "../../Components/Auth/SocialAuthButton";
import { makeMagic, magicLoginWithODIC } from "../../lib/magic";
import { getIdToken } from "firebase/auth";
import { useConnectWallet } from "@web3-onboard/react";

const Signup = ({ referredCode }) => {
  const navigate = useNavigate();
  console.log(referredCode, "referredCode");
  const [loginType, setloginType] = useState();
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const { setReferralCode, referralCode } = useContext(AuthContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  // const [magic, setMagic] = useState(null);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  useEffect(() => {
    setIsMetamaskInstalled(!!window.ethereum);
    // setMagic(makeMagic);
  }, []);

  useEffect(() => {
    if (referredCode === null || referredCode === undefined) {
      setReferralCode("");
    } else {
      setReferralCode(referredCode);
    }
  }, [referredCode, referralCode, setReferralCode]);

  const handleGoogleSignup = async () => {
    try {
      const googleData = await googleAuth();
      if (googleData) {
        // const isLoggedIn = await makeMagic.user.isLoggedIn();
        // if (isLoggedIn === false) {
        await makeMagic.openid.loginWithOIDC({
          jwt: googleData?.user.accessToken,
          providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
        });
        // } else {
        //   console.log("Magic User already loggedIn");
        // }

        const email = googleData.user.email;
        const uniqueEmail = await checkEmail(email);
        console.log(uniqueEmail);
        if (uniqueEmail.data) {
          return toast.error("Account already exists,Please login to continue");
        } else {
          // localStorage.setItem("token",googleData.user.accessToken)
          console.log(googleData, "google data");
          sessionStorage.setItem("token", googleData.user.accessToken);
          // setEmail(email);
          setloginType("Email");
          console.log(loginType);
          // setSignupDetails(true);
          return navigate("/signup-details", {
            state: { phone: "", email: email, loginType: "Email" },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMetamaskSignup = async () => {
    try {
      if (!isMetamaskInstalled) {
        return toast.error("Metamask is not installed.");
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const singner = provider.getSigner();
      const address = await singner.getAddress();
      console.log(address);
      const uniqueAddress = await checkWalletAddress(address);
      console.log(uniqueAddress.data);
      if (uniqueAddress.data?.data) {
        return toast.error("Account already exists, Please login.");
      } else {
        const customToken = await createCustomToken(address);
        const accessToken = customToken.data?.data;

        const token = await signInWithCustomToken(auth, accessToken);

        console.log(token.user.accessToken, "accessToken");
        await makeMagic.openid.loginWithOIDC({
          jwt: token.user.accessToken,
          providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
        });

        sessionStorage.setItem("token", token.user.accessToken);
        setloginType("MetaMask");
        // setSignupDetails(true);
        return navigate("/signup-details", {
          state: { loginWalletAddress: token.user?.uid, loginType: "MetaMask" },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const connectBlockNativeWallet = async () => {
    try {
      if (wallet) disconnect(wallet);
      const walletConnect = await connect();
      console.log(walletConnect[0]?.accounts[0], "connectwallet");
      console.log(walletConnect, "connectwallet");

      const walletData = walletConnect[0];
      const metaData = walletData?.accounts[0];

      console.log(walletData?.label, "LABEL");

      if (metaData?.address) {
        const address = metaData?.address;
        const uniqueAddress = await checkWalletAddress(address);
        console.log(uniqueAddress.data);
        if (uniqueAddress.data?.data) {
          return toast.error("Account already exists, Please login.");
        } else {
          const customToken = await createCustomToken(address);
          const accessToken = customToken.data?.data;

          const token = await signInWithCustomToken(auth, accessToken);

          console.log(token.user.accessToken, "accessToken");
          await makeMagic.openid.loginWithOIDC({
            jwt: token.user.accessToken,
            providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
          });

          sessionStorage.setItem("token", token.user.accessToken);
          setloginType(walletData?.label);
          // setSignupDetails(true);
          return navigate("/signup-details", {
            state: {
              loginWalletAddress: token.user?.uid,
              loginType: walletData?.label,
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={isSmall && "center"}
        justifyContent={"center"}
        gap={{ xs: 2, md: 4 }}
      >
        <SocialAuthButton
          text={"Signup with Gmail"}
          src={"/Images/login-g.png"}
          onClick={handleGoogleSignup}
          width={"40px"}
          paddingY={1}
        />
        <SocialAuthButton
          text={"Signup with Phone"}
          src={"/Images/login-p.png"}
          onClick={() => navigate("/signup-with-phone")}
          width={"22px"}
          paddingY={2}
        />
        <SocialAuthButton
          text={"Signup with Wallet"}
          src={"/Images/login-m.png"}
          onClick={connectBlockNativeWallet}
          width={"28px"}
          paddingY={2}
        />
        {/* <SocialAuthButton
          text={"Signup with Metamask"}
          src={"/Images/login-m.png"}
          onClick={handleMetamaskSignup}
          width={"28px"}
          paddingY={2}
        /> */}
      </Box>

      <FooterSection
        text={"Already have an account? "}
        link={"Login"}
        onClick={"/login"}
      />
    </>
  );
};

export default Signup;
