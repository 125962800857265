import { Button } from "@mui/material";
import React from "react";

const SocialAuthButton = ({ text, src, onClick, width, paddingY }) => {
  return (
    <>
      {" "}
      <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          // minWidth: "300px",
          maxWidth: "550px",
          paddingY: paddingY,
          paddingX: { md: 10, sm: 8 },
          color: "#fffff",
          fontWeight: 500,
          textTransform: "none",
          borderRadius: "16px",
          background: "#011F2D",
          gap: 1,
          "&:hover": {
            border: 1,
            borderColor: "#11B1F4",
            background: "none",
          },
        }}
        variant="contained"
        onClick={onClick}
      >
        <img style={{ width: width }} src={src} alt="login" /> {text}
      </Button>
    </>
  );
};

export default SocialAuthButton;
