import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import {
  Badge,
  ClickAwayListener,
  Paper,
  Slide,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import LiveTelegramChat from "./liveChat";
import { BorderClearRounded } from "@mui/icons-material";
import { io } from "socket.io-client";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import { DrawerContext } from "../../context/DrawerContext";

// Custom styled Drawer
const CustomDrawer = styled(Drawer)(({ theme, drawerWidth }) => ({
  width: theme.breakpoints.down("xs") ? drawerWidth : drawerWidth,
  backgroundColor: "transparent",
  borderColor: "transparent",
  flexShrink: 0,
  overflow: "auto",
  "& .MuiDrawer-paper": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    width: theme.breakpoints.down("xs") ? drawerWidth : drawerWidth,
    boxSizing: "border-box",
    // top: theme.mixins.toolbar.minHeight + 60,
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    right: theme.breakpoints.down("sm") ? 30 : 30,
    // right: { xs: 0, md: 30, lg: 30, xl: 30 },
    bottom: 30,
    borderRadius: "30px",
    overflow: "auto",
  },
}));

// const commonWidth = 400;

// const userId = sessionStorage.getItem("token");

// const socket = io("http://localhost:3002/live-chat", {
//   query: { token: `${userId}` }, // Send the token as part of the connection query
// }); // Adjust the port if necessary

export default function LiveChatDrawer({
  open,
  handleLiveChatOpen,
  openFloating,
}) {
  const [drawerWidth, setDrawerWidth] = useState(400); // Initial width
  const [smallScreen, setSmallScreen] = useState(false); // Initial width
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { isHomePage } = React.useContext(DrawerContext);

  useEffect(() => {
    // setDrawerWidth(isSmallScreen ? "90%" : 400);
    setSmallScreen(isSmallScreen);
  });

  // console.log(isHomePage, "IS HOME PAGE");
  return (
    // <div>
    //   <CustomDrawer
    //     variant="persistent"
    //     // variant="temporary"
    //     anchor="right"
    //     open={open === true ? true : false}
    //     drawerWidth={drawerWidth}
    //     // style={{ right: 30 }}
    //   >
    //     <LiveTelegramChat
    //       handleLiveChatOpen={handleLiveChatOpen}
    //       // socket={socket}
    //     />
    //   </CustomDrawer>
    // </div>

    /* <Slide direction="left" in={open} mountOnEnter unmountOnExit appear={true}>
      <Stack
        sx={{
          position: "fixed",
          top: smallScreen ? "110px" : "118px",
          right: smallScreen ? 0 : 30,
          width: smallScreen ? "100%" : drawerWidth,
          height: "100%",
          bgcolor: "transparent",
          overflow: "hidden",
          maxHeight: "calc(100vh - 118px)", // Subtract the top offset from viewport height
          zIndex: (theme) => theme.zIndex.drawer,
          transition: (theme) =>
            theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        <Box flexGrow={1} overflow="auto">
          <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} />
        </Box>
      </Stack>
    </Slide> */

    // {openFloating === true ? (
    //   <Slide direction="left" in={open} mountOnEnter unmountOnExit appear={true}>
    //   <Stack
    //     sx={{
    //       position: "fixed",
    //       top: smallScreen ? "110px" : "118px",
    //       right: smallScreen ? 0 : 30,
    //       width: smallScreen ? "100%" : drawerWidth,
    //       height: "100%",
    //       bgcolor: "transparent",
    //       overflow: "hidden",
    //       maxHeight: "calc(100vh - 118px)", // Subtract the top offset from viewport height
    //       zIndex: (theme) => theme.zIndex.drawer,
    //       transition: (theme) =>
    //         theme.transitions.create(["width", "margin"], {
    //           easing: theme.transitions.easing.sharp,
    //           duration: theme.transitions.duration.enteringScreen,
    //         }),
    //     }}
    //   >
    //     <Box flexGrow={1} overflow="auto">
    //       <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} />
    //     </Box>
    //   </Stack>
    // </Slide>
    // )  :(
    //   <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} />
    // )}

    // <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} />

    <>
      {isHomePage === false ? (
        <Paper
          elevation={4}
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
            padding: "16px",
            color: "#fff",
          }}
        >
          <Slide
            direction="left"
            in={open}
            mountOnEnter
            unmountOnExit
            appear={true}
          >
            <Stack
              sx={{
                position: "fixed",
                top: smallScreen ? "105px" : "118px",
                right: smallScreen ? 0 : 30,
                width: smallScreen ? "100%" : drawerWidth,
                height: "100%",
                // bgcolor: "transparent",
                overflow: "hidden",
                maxHeight: "calc(100vh - 118px)", // Subtract the top offset from viewport height
                zIndex: (theme) => theme.zIndex.drawer,
                transition: (theme) =>
                  theme.transitions.create(["width", "margin"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
              }}
            >
              <Box flexGrow={1} overflow="auto">
                <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} />
              </Box>
            </Stack>
          </Slide>
        </Paper>
      ) : (
        <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} />
      )}

      {/* <LiveTelegramChat handleLiveChatOpen={handleLiveChatOpen} /> */}
    </>
  );
}
