import React from "react";
import HowToPlayPage from "../NewLandingPage/HowToPlayPage";
import BackArrowButton from "../../Components/Landing/components/BackArrowButton";

const HowToPlay = () => {
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#0F103C",
      }}
    >
      <BackArrowButton />
      <HowToPlayPage />
    </div>
  );
};

export default HowToPlay;
