import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, DialogActions, DialogContentText, alpha } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BetaTestingPopup = () => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          backgroundColor: alpha("#000000", 0.8),
        }}
        // PaperProps={{ backgroundColor: "transparent" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#0F103C",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingY: 2,
            gap: 1,
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              padding: 1,
              display: { xs: "none", sm: "flex", md: "flex", xl: "flex" },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            fontSize={{ md: 28, sm: 24, xs: 16 }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{}}
          >
            Beta Testing in Progress
          </Typography>
          {/* <DialogContent> */}
          <DialogContentText
            sx={{
              textAlign: "center",
              fontSize: { xs: 10, sm: 14, md: 16 },
              marginX: 1,
            }}
            id="alert-dialog-slide-description"
          >
            We are currently conducting beta testing for our new features. Thank
            you for your participation and feedback!
          </DialogContentText>
          {/* </DialogContent> */}
          <DialogActions>
            <Button
              sx={{
                paddingY: { md: "6px", xs: "6px" },
                color: "white",
                fontWeight: 500,
                paddingX: { md: "15px", xs: "10px" },
                textTransform: "none",
                fontFamily: "Space Grotesk",
                borderRadius: "10px",
                fontSize: { xs: "8px", md: "14px" },
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                "&:hover": {
                  backgroundColor: " #10ADEF95",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              Got It!
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default BetaTestingPopup;
