import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {" "}
      <div
        style={{
          width: "300px",
          position: "absolute",
          // borderRadius: "50%",
          borderBottomRightRadius: "30%",
          overflow: "hidden",
          top: 120,
          display: isSmallScreen ? "none" : "block",
          background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
          filter: "blur(146px)",
          height: "208px",
          opacity: "0.6",
        }}
      />
      <Container>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: { xs: 4, md: 8 },
            marginBottom: { xs: 8, md: 12 },
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "28px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={1.2}
            sx={{ color: "#FFFFFF" }}
          >
            Privacy Policy
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={10}>
          <Typography
            variant="caption"
            fontSize={{ md: "40px", sm: "24px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={{ xs: 1, md: 1.2 }}
            sx={{ color: "#FFFFFF" }}
          >
            Introduction
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 2 },
              fontSize: { xs: "14px", md: "18px" },
            }}
            fontFamily={"Space Grotesk"}
          >
            [Crypto Champs] (the “Company”) is committed to maintaining robust
            privacy protections for its users. Our Privacy Policy (“Privacy
            Policy”) is designed to help you understand how we collect, use and
            safeguard the information you provide to us and to assist you in
            making informed decisions when using our Service.
            <br />
            <br />
            For purposes of this Agreement, “Site” refers to the Company’s
            website, which can be accessed at [www.cryptochamps.io].
            <br />
            <br />
            The terms “we,” “us,” and “our” refer to the Company. “You” refers
            to you, as a user of our Site or our Service. By accessing our Site
            or our Service, you accept our Privacy Policy and Terms of Use
            (found here: [www.cyrptochamps.io/Terms]), and you consent to our
            collection, storage, use and disclosure of your Personal Information
            as described in this Privacy Policy.
          </Typography>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          marginTop={{ xs: 6, md: 10 }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "40px", sm: "24px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={{ xs: 1, md: 1.2 }}
            sx={{ color: "#FFFFFF" }}
          >
            Information We Collect
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{ color: "#FFFFFF", lineHeight: { xs: 1.6, md: 2 } }}
          >
            We collect “Non-Personal Information” and “Personal Information.”
            Non-Personal Information includes information that cannot be used to
            personally identify you, such as anonymous usage data, general
            demographic information we may collect, referring/exit pages and
            URLs, platform types, preferences you submit and preferences that
            are generated based on the data you submit and number of clicks.
            Personal Information includes your email [iemail address,crypto
            wallet addfress.], which you submit to us through the registration
            process at the Site.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            // marginTop: 8,
            marginBottom: { md: 12, xs: 2 },
          }}
        >
          {" "}
          {/* <Button
            sx={{
              paddingY: "10px",
              color: "white",
              fontWeight: 400,
              paddingX: "25px",
              textTransform: "none",
              fontFamily: "Space Grotesk",
              borderRadius: "64px",
              marginTop: 4,
              background:
                "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
              "&:hover": {
                backgroundColor: " #10ADEF95",
              },
            }}
            variant="contained"
            onClick={() => {}}
          >
            + Read More
          </Button> */}
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
