import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Avatar,
  Box,
  Button,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { deleteInvitation } from "../../Services/Service";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const InvitationConfirmPopup = ({
  invitationId,
  recieveUserName,
  recieveProfilePic,
  gameInstanceId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  console.log(gameInstanceId, "instanceId");

  const handleDeclineInvitation = async () => {
    try {
      const response = await deleteInvitation(invitationId);
      if (response.data.success === true) {
        toast.success(response.data.message);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {" "}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          "& .MuiDialog-paper": {
            borderRadius: "25px",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            background:
              "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)",
            padding: 2,
            width: isMobile ? "220px" : "400px",
            height: isMobile ? "290px" : "400px",
            position: "relative",
            borderRadius: "25px",
            gap: isMobile ? 1 : 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "30px", md: "3opx" },
              fontWeight: "600",
              fontFamily: "Poppins",
              color: "white",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            Game Invitation
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              paddingY: 1,
              width: isMobile ? "100%" : "70%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Avatar
                src={user?.profilePic}
                sx={{ width: isMobile ? 30 : 65, height: isMobile ? 30 : 65 }}
                variant="circular"
              />
              <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                {user?.username}
              </Typography>
            </div>
            <span
              style={{
                color: "white",
                fontWeight: 600,
                fontSize: isMobile ? "12px" : "24px",
              }}
            >
              VS
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Avatar
                src={recieveProfilePic}
                sx={{ width: isMobile ? 30 : 65, height: isMobile ? 30 : 65 }}
                variant="circular"
              />
              <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                {recieveUserName}
              </Typography>
            </div>
          </Box>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "8px auto",
            }}
          >
            <Typography sx={{ fontWeight: 400, fontSize: 12 }}>
              {recieveUserName} Invites you to play
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
              {gameInstanceId?.game.winnerType}
            </Typography>
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: isMobile ? "100%" : "80%",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Button
              sx={{
                backgroundColor: "#F41111",
                color: "white",
                width: "100%",
                textTransform: "none",
                paddingX: { xs: 2, sm: 4, md: 4 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 14 },
                "&:hover": {
                  backgroundColor: alpha("#F41111", 0.5),
                },
              }}
              onClick={handleDeclineInvitation}
            >
              Decline
            </Button>
            <Button
              sx={{
                backgroundColor: "#00FF66",
                color: "#0A739F",
                width: "100%",
                textTransform: "none",
                paddingX: { xs: 2, sm: 4, md: 4 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 14 },
                "&:hover": {
                  backgroundColor: alpha("#00FF66", 0.5),
                },
              }}
              onClick={() => {
                navigate("/select-cryptos", {
                  state: {
                    rowData: gameInstanceId,
                    invitationId: invitationId,
                  },
                });
                handleClose();
              }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default InvitationConfirmPopup;
