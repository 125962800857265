import { CircularProgress, Stack } from "@mui/material";
import React from "react";

const Loader = ({ marginBottom }) => {
  return (
    <Stack
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      width={"100%"}
    >
      <CircularProgress sx={{ marginBottom: marginBottom, color: "#11B1F4" }} />
    </Stack>
  );
};

export default Loader;
