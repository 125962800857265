import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import TopPlayers from "./TopPlayers";
import LandingButton from "./LandingButton";
import { appBarZindex } from "./LandingHeader";
import LandingHeader from "../Landing/LandingHeader";
// import "../../index.css";

const HeroComponent = ({ homeData }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const upMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isDeskTop = useMediaQuery(theme.breakpoints.up("xl"));

  const navigate = useNavigate();

  const zIndex = 100;

  return (
    <>
      {" "}
      {isSmall ? (
        <>
          <Box>
            <Box
              // sx={{
              //   display: "flex",
              //   flexDirection: "column",
              //   alignItems: "center",
              //   justifyContent: "center",
              //   width: "100%",
              //   marginTop: isSmall ? 2 : 4,
              // }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: 400,
                backgroundImage: "url(Images/landingTopImage.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
                "&::before": {
                  content: '""', // Required for the pseudo-element
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  // backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay with 50% opacity
                  // background:
                  // "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)", // Dark overlay with 50% opacity
                  background:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)",
                  zIndex: 0, // Ensure the overlay is on top of the image
                },
                zIndex: 0, // Content inside the Box should be above the overlay
                padding: 2,
              }}
            >
              <nav style={{ width: "100%", marginBottom: 10, zIndex: 2 }}>
                <LandingHeader />
              </nav>

              {/* <Typography
                variant="caption"
                fontSize={{ sm: "30px", xs: "28px" }}
                fontFamily={"Space Grotesk"}
                lineHeight={1.2}
                fontWeight={500}
                sx={{
                  color: "#FFFFFF",
                  width: { xs: "100%", md: "60%" },
                  zIndex: appBarZindex,
                  textAlign: "center",
                }}
              >
                Welcome to the world of{" "}
                <Typography
                  variant="caption"
                  fontSize={{ md: "70px", sm: "30px", xs: "28px" }}
                  fontFamily={"Space Grotesk"}
                  lineHeight={1.4}
                  fontWeight={500}
                  sx={{ color: "#0EA1EE" }}
                >
                  CHAMPS
                </Typography>
              </Typography> */}

              <Typography
                className="bebas-neue-regular"
                variant="caption"
                fontSize={"1.5rem"}
                // fontFamily={"Space Grotesk"}
                // fontFamily="Montserrat"
                // lineHeight={1.2}
                fontFamily={'"Bebas Neue", system-ui'}
                fontWeight={800}
                // whiteSpace={"nowrap"}
                sx={{
                  color: "#FFFFFF",
                  // width: "60%",
                  fontFamily: '"Bebas Neue", system-ui',
                  zIndex: appBarZindex,
                  textAlign: mobile && "center",
                }}
              >
                Welcome to the world of{" "}
              </Typography>
              <Typography
                variant="caption"
                fontSize={{ md: "50px", sm: "2rem", xs: "28px" }}
                // fontFamily={"Space Grotesk"}
                fontFamily={'"Bebas Neue", system-ui'}
                lineHeight={1.0}
                fontWeight={800}
                sx={{ color: "#0EA1EE", zIndex: appBarZindex }}
              >
                CHAMPS
              </Typography>

              {/* <Box
                sx={{
                  display: "flex",
                  width: {
                    xs: "300px",
                    sm: "500px",
                  },
                  // marginTop: "70px",
                  height: { sm: "480px", xs: "280px" },
                  position: "relative",
                  marginBottom: { md: 10 },
                  marginTop: { xs: 4 },
                }}
              >
                <div
                  className="white-gradient"
                  style={{
                    zIndex: appBarZindex - 1,
                  }}
                />

                <Box
                  sx={{
                    width: mobile
                      ? "238px"
                      : isTablet
                      ? "438px"
                      : isDeskTop
                      ? "638px"
                      : "400px",
                    height: mobile
                      ? "238px"
                      : isTablet
                      ? "438px"
                      : isDeskTop
                      ? "638px"
                      : "400px",
                    border: 1,
                    borderColor: "#296590",
                    borderRadius: "360px",
                    position: "absolute",
                    // top: -70,
                    right: "14%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    zIndex: zIndex,
                  }}
                >
                  <img
                    src="/Images/champs-lpp.png"
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                      // marginBottom: "1px",
                      zIndex: appBarZindex,
                    }}
                  />

                  <Box
                    sx={{
                      border: 1,
                      borderStyle: "dotted",
                      borderColor: "#296590",
                      borderRadius: "360px",
                      position: "absolute",
                      height: "80%",
                      width: "80%",
                      // backgroundSize: 8,
                      left: { sm: 38, xs: 22 },
                    }}
                  />

                  <img
                    src="/Images/champs-coin.png"
                    alt=""
                    style={{
                      position: "absolute",

                      width: mobile
                        ? "200px"
                        : isTablet
                        ? "350px"
                        : isDeskTop
                        ? "450px"
                        : "350px",

                      top: mobile ? -10 : upMobile ? -60 : 20,
                      right: mobile
                        ? 0
                        : upMobile
                        ? -150
                        : isTablet
                        ? -140
                        : -160,

                      left: mobile && 110,
                    }}
                  />
                </Box>
              </Box> */}

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LandingButton
                text={"Get Started"}
                onClick={() => navigate("/signup")}
              />

                {homeData?.topPlayers === undefined ||
                homeData?.topPlayers?.length === 0 ? (
                  <></>
                ) : (
                  <TopPlayers data={homeData?.topPlayers} />
                )}
              </Box> */}

              {/* <Typography
                sx={{
                  fontFamily: "Space Grotesk",
                  alignSelf: "start",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", md: "18px" },
                  textAlign: "center",
                  lineHeight: 2,
                  fontWeight: 400,
                  width: { xs: "100%", md: "60%" },
                  marginTop: 2,
                }}
              >
                Crypto Champs is the 1st and ONLY Fantasy Crypto Web3 platform
                to bring you realtime contests to compete against other crypto
                enthusiast 24 hours a day
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LandingButton
                  text={"Get Started"}
                  onClick={() => navigate("/signup")}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: 800,
              backgroundImage: "url(Images/landingTopImage.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              position: "relative",
              "&::before": {
                content: '""', // Required for the pseudo-element
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                // backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay with 50% opacity
                // background:
                // "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)", // Dark overlay with 50% opacity
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%)",
                zIndex: 1, // Ensure the overlay is on top of the image
              },
              zIndex: 0, // Content inside the Box should be above the overlay
            }}
          >
            <nav style={{ width: "100%", zIndex: 2 }}>
              <LandingHeader />
            </nav>

            {/* <Box
              sx={{
                width: "100%",
                display: "flex",
                marginX: "auto",
                marginTop: mobile ? 2 : 5,
              }}
            > */}
            {/* <img
              src="Images/landingTopImage.png"
              alt=""
              style={{
                position: "absolute",

                width: mobile
                  ? "200px"
                  : isTablet
                  ? "350px"
                  : isDeskTop
                  ? "450px"
                  : "350px",

                top: mobile ? -10 : upMobile ? -60 : 20,
                right: mobile ? 0 : upMobile ? -150 : isTablet ? -140 : -160,

                left: mobile && 110,
              }}
            /> */}
            {/* <Stack
                direction={{ md: "row", xs: "column-reverse" }}
                justifyContent={{
                  md: "space-between",
                  sm: "center",
                  xs: "center",
                }}
                width={"100%"}
                alignItems={"center"}
                // gap={2}
              > */}
            <Box
              sx={{
                width: "88%",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
                // marginTop: 6,
                // backgroundColor: "#ffffff",
                // marginX: 20,
                // maxWidth: "1400px",
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column", // Responsive flex direction
                  alignItems: "start",
                  // backgroundColor: "#ffffff",
                  // justifyContent: mobile ? "center" : "space-between", // Center on mobile, space-between on desktop
                  // width: "100%",
                  // zIndex: 1, // Adjust based on your needs
                }}
              >
                <Typography
                  variant="caption"
                  fontSize={{ md: "3.5rem", sm: "2rem", xs: "28px" }}
                  // fontFamily={"Space Grotesk"}
                  fontFamily={'"Bebas Neue", system-ui'}
                  // lineHeight={1.2}
                  fontWeight={800}
                  sx={{
                    color: "#FFFFFF",
                    // width: "60%",
                    zIndex: appBarZindex,
                    textAlign: mobile && "center",
                  }}
                >
                  Welcome to the world of{" "}
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ md: "3.5rem", sm: "2rem", xs: "28px" }}
                  // fontFamily={"Space Grotesk"}
                  fontFamily={'"Bebas Neue", system-ui'}
                  lineHeight={1.0}
                  fontWeight={800}
                  sx={{ color: "#0EA1EE", zIndex: appBarZindex }}
                >
                  CHAMPS
                </Typography>

                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" }, // Adjust button alignment
                    alignItems: "flex-end",
                    justifyContent: { md: "space-between", xs: "center" },
                    // width: "15%"
                    // mt: { xs: 2, md: 0 }, // Add margin-top on small screens to separate text from button
                  }}
                > */}

                {/* </Box> */}
              </Box>

              <LandingButton
                text={"Get Started"}
                onClick={() => navigate("/signup")}
              />

              {/* <Box
                sx={{
                  display: mobile ? "flex" : "none",
                  width: {
                    md: "500px",
                    xs: "300px",
                    lg: "600px",
                  },
                  // marginTop: "70px",
                  height: { md: "500px", sm: "480px", xs: "280px" },
                  position: "relative",
                  marginBottom: { md: 10 },
                  marginTop: { xs: 4 },
                }}
              >
                <div
                  className="white-gradient"
                  style={{
                    zIndex: appBarZindex - 1,
                  }}
                /> */}

              {/* <Box
                    sx={{
                      width: mobile
                        ? "238px"
                        : isTablet
                        ? "438px"
                        : isDeskTop
                        ? "638px"
                        : "400px",
                      height: mobile
                        ? "238px"
                        : isTablet
                        ? "438px"
                        : isDeskTop
                        ? "638px"
                        : "400px",
                      border: 1,
                      borderColor: "#296590",
                      borderRadius: "360px",
                      position: "absolute",
                      // top: -70,
                      right: "24%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      zIndex: zIndex,
                    }}
                  >
                    <img
                      src="/Images/champs-lpp.png"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        // marginBottom: "1px",
                        zIndex: appBarZindex,
                      }}
                    />

                    <Box
                      sx={{
                        border: 1,
                        borderStyle: "dotted",
                        borderColor: "#296590",
                        borderRadius: "360px",
                        position: "absolute",
                        height: "80%",
                        width: "80%",
                        // backgroundSize: 8,
                        left: { md: 42, sm: 38, xs: 22 },
                      }}
                    />

                    <img
                      src="/Images/champs-coin.png"
                      alt=""
                      style={{
                        position: "absolute",

                        width: mobile
                          ? "200px"
                          : isTablet
                          ? "350px"
                          : isDeskTop
                          ? "450px"
                          : "350px",

                        top: mobile ? -10 : upMobile ? -60 : 20,
                        right: mobile
                          ? 0
                          : upMobile
                          ? -150
                          : isTablet
                          ? -140
                          : -160,

                        left: mobile && 110,
                      }}
                    />
                  </Box> */}
              {/* </Box> */}

              {/* <Box
                  sx={{
                    display: mobile ? "flex" : "none",
                    alignItems: "center",
                    marginBottom: 2,
                    justifyContent: { md: "space-between", sm: "start" },
                    width: "100%",
                  }}
                >
                  {homeData?.topPlayers === undefined ||
                  homeData?.topPlayers?.length === 0 ? (
                    <></>
                  ) : (
                    <TopPlayers data={homeData?.topPlayers} />
                  )}
                </Box> */}
              {/* <Typography
                  sx={{
                    fontFamily: "Space Grotesk",
                    alignSelf: "start",
                    color: "#FFFFFF",
                    fontSize: { xs: "14px", md: "18px" },
                    textAlign: mobile && "center",
                    lineHeight: mobile && 2,
                    fontWeight: 400,
                    width: { xs: "100%", md: "60%" },
                    marginTop: 2,
                  }}
                >
                  Crypto Champs is the 1st and ONLY Fantasy Crypto Web3 platform
                  to bring you realtime contests to compete against other crypto
                  enthusiast 24 hours a day
                </Typography> */}
              {/* <Box
                sx={{
                  display: mobile ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LandingButton
                  text={"Get Started"}
                  onClick={() => navigate("/signup")}
                />
              </Box> */}
              {/* <Box
                  sx={{
                    display: mobile ? "none" : "flex",
                    flexDirection: { sm: "column", md: "row" },
                    alignItems: "center",

                    justifyContent: { md: "space-between", sm: "start" },
                    width: "100%",
                  }}
                >
                  <LandingButton
                    text={"Get Started"}
                    onClick={() => navigate("/signup")}
                  />
                  {homeData?.topPlayers === undefined ||
                  homeData?.topPlayers?.length === 0 ? (
                    <></>
                  ) : (
                    <TopPlayers data={homeData?.topPlayers} />
                  )}
                </Box> */}
            </Box>
            {/* </Stack> */}
            {/* </Box> */}
          </Box>
        </>
      )}
    </>
  );
};

export default HeroComponent;
