import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    height: 16,
    width: 16,
    borderRadius: 50,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MyProfile = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  return (
    <Box
      sx={{
        // height: "100%",
        background: "#03364C",
        borderRadius: "27px",
        padding: 2,
        paddingY: 6,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <>
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h5" fontWeight={500} sx={{}}>
              My Profile
            </Typography>
            <IconButton
              sx={{
                background: "#064865",
                width: 38,
                height: 38,
                padding: 1,
                "&:hover": {
                  background: "#11B1F4",
                },
              }}
              onClick={() => navigate("/wallet/edit-profile")}
            >
              <BiEdit />
            </IconButton>
          </Stack>

          <Stack
            direction={"column"}
            alignItems={"center"}
            marginTop={4}
            gap={1}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt="Remy Sharp"
                sx={{
                  width: 100,
                  height: 100,
                }}
                src={user?.profilePic}
              />
            </StyledBadge>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Typography variant="body1" fontWeight={500} fontSize={16}>
                  {user.username}
                </Typography>
                {/* <Avatar
                  src="/Images/badge2.png"
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                /> */}
              </Box>

              {/* <Typography
                variant="caption"
                fontWeight={500}
                fontSize={14}
                color={"#FFD54B"}
              >
                Level 2
              </Typography> */}
            </Box>
          </Stack>

          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                fontWeight: 500,
                marginBottom: 1,
              }}
            >
              Username
            </Typography>

            <TextField
              id="outlined-basic"
              variant="outlined"
              value={user?.username}
              InputLabelProps={{
                style: { color: "white" }, // Set the label color to white
              }}
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              sx={{
                "& fieldset": { border: "none" },
                backgroundColor: "#011F2D",
                borderRadius: "8px",
                width: "100%",
              }}
              autoComplete="off"
              disabled={true}
            />
          </Box>

          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                fontWeight: 500,
                marginBottom: 1,
              }}
            >
              Email
            </Typography>

            <TextField
              id="outlined-basic"
              variant="outlined"
              value={user?.email}
              InputLabelProps={{
                style: { color: "white" }, // Set the label color to white
              }}
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              sx={{
                "& fieldset": { border: "none" },
                backgroundColor: "#011F2D",
                borderRadius: "8px",
                width: "100%",
              }}
              autoComplete="off"
              disabled={true}
            />
          </Box>

          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                fontWeight: 500,
                marginBottom: 1,
              }}
            >
              Phone
            </Typography>

            <TextField
              id="outlined-basic"
              value={user?.phone}
              InputLabelProps={{
                style: { color: "white" }, // Set the label color to white
              }}
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              sx={{
                "& fieldset": { border: "none" },
                backgroundColor: "#011F2D",
                borderRadius: "8px",
                width: "100%",
              }}
              autoComplete="off"
              disabled={true}
            />
          </Box>

          {/* <Box>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                fontWeight: 500,
                marginBottom: 1,
                marginTop: 2,
              }}
            >
              Linked Accounts
            </Typography>

            <Stack direction={"row"} gap={1} marginBottom={4} marginTop={2}>
              <Box
                width={50}
                height={50}
                borderRadius={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  background: "#011F2D",
                }}
              >
                <img
                  style={{ width: "45px" }}
                  src="/Images/login-g.png"
                  alt="login"
                />
              </Box>
              <Box
                width={50}
                height={50}
                borderRadius={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  background: "#011F2D",
                }}
              >
                <img
                  style={{ width: "28px" }}
                  src="/Images/login-m.png"
                  alt="login"
                />
              </Box>
            </Stack>
          </Box> */}
        </>
        {/* )} */}
      </>
    </Box>
  );
};

export default MyProfile;
