import React from "react";
import LandingHeaderCryptoCard from "../common/LandingHeaderCryptoCard";
import { AppBar, Box, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const GameListHeader = ({ cryptos }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <AppBar
        position="fixed"
        component="nav"
        sx={{
          // zIndex: theme.zIndex.drawer + 1,
          background: "#03364C",
          height: "100px",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ marginBottom: 1 }}>
          <img
            style={{
              width: "180px",
              marginTop: "25px",
              height: "65px",
              cursor: "pointer",
            }}
            src="/Images/chmaps-logo.png"
            alt=""
            onClick={() => navigate("/")}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: isMediumUp ? "85%" : "70%",
              marginTop: 1,
              alignItems: "center",
              gap: "10px",
              position: "relative",
            }}
          >
            {!isSmallScreen && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginLeft: !isMediumScreen ? 5 : 2,
                  position: "relative",
                  overflowX: "scroll",
                  backgroundColor: "#064865",
                  borderRadius: "36px",
                  "&::-webkit-scrollbar": { display: "none" },
                  alignItems: "center",
                  marginTop: 1,
                  padding: 1,
                }}
              >
                {cryptos?.length === 0 || cryptos === undefined ? (
                  <></>
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        marginLeft: 1,
                        minWidth: 100,
                      }}
                      noWrap
                    >
                      Price Updates:
                    </Typography>

                    <>
                      {cryptos?.map((data) => (
                        <LandingHeaderCryptoCard data={data} />
                      ))}
                    </>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default GameListHeader;
