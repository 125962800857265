import { ethers } from "ethers";
import { makeMagic } from "../lib/magic";
import {
  getBlockChianConfig,
  requestToken,
  updateUserBalance,
} from "../Services/Service";

import toast from "react-hot-toast";
import { commonData } from "../constants/common";
import USDCToken from "../Contract/USDC.json";

export const userBalanceUpdate = async () => {
  try {
    const userId = sessionStorage.getItem("userId");
    const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);

    // ⭐️ After user is successfully authenticated
    const signer = provider.getSigner();

    // ⭐️ After user is successfully authenticated
    const address = await signer.getAddress();

    const maticBalance = await signer.getBalance(); // Balance is in wei

    // Get user's balance in ether
    const formattedMaticBal = ethers.utils.formatEther(maticBalance);

    console.log("MATIC BALANCE => ", formattedMaticBal);

    const result = await getBlockChianConfig();
    const blockChain = result.data;

    const usdcAddress = blockChain.paymentMethod.find(
      (e) => e.symbol === "USDC"
    );

    if (!usdcAddress?.contractAddress)
      toast.error("Something went wrong! Please try again. c");

    console.log(usdcAddress?.contractAddress);

    const usdcContract = new ethers.Contract(
      usdcAddress?.contractAddress,
      USDCToken,
      signer
    );

    const balance = await usdcContract.balanceOf(address);
    const formattedUSDCBal = ethers.utils.formatUnits(
      balance,
      commonData.usdcDecimals
    );

    console.log("USDC BALANCE => ", formattedUSDCBal);

    await updateUserBalance({
      userId: userId,
      token: parseFloat(formattedUSDCBal?.toString()),
      maticToken: parseFloat(formattedMaticBal?.toString()),
    });

    if (
      parseFloat(formattedMaticBal?.toString()) > 0.1
      // &&
      // requestSended === false
    ) {
      const request = await requestToken({
        userId: userId,
        currentMatic: parseFloat(formattedMaticBal?.toString()),
        currentUsdc: parseFloat(formattedUSDCBal?.toString()),
        userFeedType: "user", // !important
        walletAddress: address,
      });
      // if (request?.success === true) {
      //   // console.log(request.data);
      //   setRequestSended(true);
      // }
    }
    return formattedUSDCBal;
  } catch (error) {
    throw error;
  }
};
