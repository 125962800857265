export const champsCovertDecimals = (token, decimal) => {
  let data;
  try {
    const x = parseFloat(token);
    data = parseFloat(x?.toFixed(decimal));
  } catch (error) {
    console.error(error);
    data = 0;
  }
  return data;
};
