import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DepositePaymentMethods from "./DepositPaymentMethods";
import UsdcWalletCard from "../../Components/wallet/UsdcWalletCard";
// import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { useQuery } from "react-query";
import {
  createBlockchainTransaction,
  getBlockChianConfig,
  updateUserBalance,
} from "../../Services/Service";
import Loader from "../../Components/Loader/Loader";
import toast from "react-hot-toast";
import detectEthereumProvider from "@metamask/detect-provider";
import ConfirmDialogue from "../../Components/Modals/ConfirmDialogue";
import WithdrawPopup from "../../Components/Popups/WithdrawPopup";
import { AuthContext } from "../../context/AuthContext";
import { makeMagic } from "../../lib/magic";
import { ethers } from "ethers";
import USDCToken from "../../Contract/USDC.json";
import { commonData } from "../../constants/common";
import { common } from "@mui/material/colors";
import { userBalanceUpdate } from "../../lib/blockchain_common_method";
// import { userBalanceUpdate } from "./tnx/transaction";

// const useStyles = makeStyles({
//   customNumberField: {
//     "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     '& input[type="number"]': {
//       "-moz-appearance": "textfield",
//     },
//   },
// });

const CARDS_OPTIONS = [
  { value: "ViSa1", label: "**** **** **** 1212 - Jimmy Holland" },
  { value: "ViSa2", label: "**** **** **** 2424 - Shawn Stokes" },
  { value: "MasterCard", label: "**** **** **** 4545 - Cole Armstrong" },
];

const WithdrawalPage = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [tokenAmount, setTokenAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [desWalAddress, setDesWalAddress] = useState(""); //destination wallet address
  const [connectedWallet, setConnectedWallet] = useState(null);
  const [config, setConfig] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [usdcAmount, setUsdcAmount] = useState(0);
  const { user, setUserBalance, userBalance } = useContext(AuthContext);
  const [transcId, setTranscId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // setUsdcAmount(
    //   user.walletData?.find((item) => item._id?.paymentMethod === "USDC")
    // );
    checkBalance();
  }, [user]);

  /*
  const PAYMENT_OPTIONS = [
    {
      value: "crypto_wallet",
      title: "Crypto Wallet",
      description: `Balance : ${
        usdcAmount?.totalAmount ? usdcAmount?.totalAmount?.toFixed(3) : 0
      } USDC`,
      icon: "/Images/crypto-wallet.png",
    },
    */
  const PAYMENT_OPTIONS = [
    {
      value: "crypto_wallet",
      title: "Crypto Wallet",
      description: `Balance : ${usdcAmount ? usdcAmount : 0} USDC`,
      icon: "/Images/crypto-wallet.png",
    },
    // {
    //   value: "credit_card",
    //   title: "Credit/Debit Card",
    //   description: "Visa or Master Card",
    //   icon: "/Images/credit-card.png",
    // },
  ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data: configData, isLoading: isConfigLoading } = useQuery(
    ["walletConfig"],
    async () => {
      try {
        await delay(800); // Simulate a delay of 2 seconds (2000 milliseconds)
        const result = await getBlockChianConfig();
        console.log(result.data, "blockCVhain");
        return result.data;
      } catch (error) {
        // seth2hLoading(false);
        console.log(error);
      }
    },
    {
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    setConfig(configData);
  }, [configData]);

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
    calculateTotalAmount(event.target.value);
  };

  const calculateTotalAmount = (value) => {
    if (config?.transactionCharge) {
      // Convert percentage string to a decimal
      const transactionCharge =
        parseFloat(config?.transactionCharge ?? 0) / 100;
      const amount = value - value * transactionCharge; // Reducing the specified percentage from the value
      setTotalAmount(amount);
    } else {
      setTotalAmount(value);
    }
  };

  const handDesWalAddressChange = (event) => {
    setDesWalAddress(event.target.value);
  };

  const handleDialogOpen = () => {
    if (ethers.utils.isAddress(desWalAddress) === false) {
      return toast.error("Please enter a valid address.");
    }

    if (parseFloat(usdcAmount) <= 0) {
      return toast.error("Insufficient amount to withdraw.");
    }

    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const connectToWallet = async () => {
    try {
      const provider = await detectEthereumProvider();

      if (provider) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        const address = await accounts[0];
        console.log(address);
        setConnectedWallet(address);
        return accounts[0];
      }
    } catch (error) {
      console.log("Error connecting to wallet:", error);
    }
  };

  useEffect(() => {
    // const connect = async () => await connectToWallet();
    // connect();
  }, []);

  const handleWithdrawButtonClick = async () => {
    const notification = toast.loading("Withdraw your tokens...");
    try {
      // await connectToWallet();

      if (!connectedWallet) {
        toast.error("Wallet Not Found", {
          id: notification,
        });
        return;
      }

      // if (config?.MiniToken >= user?.totalToken)
      if (config?.MiniToken >= (user?.customToken ?? 0)) {
        setDialogOpen(false);
        return toast.error("You don't have minimum amount", {
          id: notification,
        });
      }

      const dataOne = {
        walletAddress: connectedWallet,
        userId: userId,
        token: tokenAmount,
        paymentMethod: "USDC",
        transactionType: "ManuelWithdrawal",
        exchangedToken: tokenAmount,
      };

      const res = await createBlockchainTransaction(dataOne);

      console.log("res", res.data?._id);
      setTranscId(res.data?._id);
      setDialogOpen(false);
      toast.success("Suceessfully applyed withdraw amount.", {
        id: notification,
      });
      setIsSuccessDialogOpen(true);
    } catch (e) {
      toast.error("Something went wrong!", {
        id: notification,
      });
    }
  };

  const checkBalance = async () => {
    try {
      const usdcBalance = await userBalanceUpdate();
      setUsdcAmount(usdcBalance);
      setUserBalance(usdcBalance);
    } catch (error) {
      console.error(`Error from check balance${error}`);
    }
  };

  //New
  const handleCustomWithdrawButtonClick = async () => {
    const notification = toast.loading("Withdraw your tokens...");

    setIsLoading(true);

    try {
      if (ethers.utils.isAddress(desWalAddress) === false) {
        setIsLoading(false);
        return toast.error("Please enter a valid address.", {
          id: notification,
        });
      }

      if (parseFloat(usdcAmount) <= 0) {
        setIsLoading(false);
        return toast.error("Insufficient amount to withdraw.");
      }

      if (parseFloat(tokenAmount) <= 0) {
        setIsLoading(false);
        return toast.error("Please enter an amount greater than zero.", {
          id: notification,
        });
      }

      const result = await getBlockChianConfig();
      const blockChain = result.data;
      setConfig(result.data);

      calculateTotalAmount(tokenAmount);

      const amountToSend = ethers.utils.parseUnits(
        `${totalAmount}`,
        commonData.usdcDecimals
      );

      const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);

      // ⭐️ After user is successfully authenticated
      const signer = provider.getSigner();

      const address = await signer.getAddress();

      // console.log(blockChain);
      const usdcAddress = blockChain?.paymentMethod?.find(
        (e) => e.symbol === "USDC"
      );

      if (!usdcAddress?.contractAddress) {
        setIsLoading(false);
        return toast.error("Something went wrong! Please try again. c", {
          id: notification,
        });
      }

      console.log(usdcAddress?.contractAddress, "contractAddress");

      const usdcContract = new ethers.Contract(
        usdcAddress?.contractAddress,
        USDCToken,
        signer
      );

      const gasPrice = await provider.getGasPrice();
      const gasPriceWithBuffer = gasPrice.mul(120).div(100);
      console.log(
        ethers.utils.formatEther(gasPriceWithBuffer.toString()),
        "gas price with buffer"
      );

      const gasLimit = await usdcContract.estimateGas.transfer(
        desWalAddress,
        amountToSend
      );

      const gasLimitWithBuffer = gasLimit.mul(120).div(100); // 120% of the estimate
      console.log(
        ethers.utils.formatEther(gasLimitWithBuffer.toString()),
        "gasLimitWithBuffer"
      );

      console.log(desWalAddress, totalAmount);

      const tnx = await usdcContract.transfer(desWalAddress, amountToSend, {
        gasPrice: gasPriceWithBuffer,
        gasLimit: gasLimitWithBuffer,
      });

      const waitTnx = await tnx.wait();
      console.log("Transaction mined:", waitTnx);

      setTranscId(waitTnx?.transactionHash);

      if (!waitTnx?.transactionHash && waitTnx?.status !== 1) {
        throw new Error("withdraw transaction is failed");
      }

      await transferFeeToAdmin(provider, usdcContract, address);

      setDialogOpen(false);
      setIsSuccessDialogOpen(true);
      setIsLoading(false);

      // Display a success message once the transaction is confirmed
      return toast.success(
        "Withdrawal successful! Your funds have been transferred.",
        {
          id: notification,
        }
      );
    } catch (error) {
      // Log the error and display an appropriate error message to the user
      console.error("Transaction error:", error);
      setIsLoading(false);
      // Check for specific error types or provide a general error message
      if (error.code === "INSUFFICIENT_FUNDS") {
        toast.error(
          "Insufficient funds for gas fee. Please check your balance.",
          {
            id: notification,
          }
        );
      } else if (error.code === "NETWORK_ERROR") {
        toast.error("Network error. Please try again later.", {
          id: notification,
        });
      } else {
        toast.error("Something went wrong! Please try again.", {
          id: notification,
        });
      }
    }
    setIsLoading(false);
  };

  const transferFeeToAdmin = async (provider, usdcContract, address) => {
    try {
      const tnxFeeForAdmin = parseFloat(tokenAmount) - parseFloat(totalAmount);
      console.log(tnxFeeForAdmin, "tnxFeeForAdmin");

      const finalTnxFeeForAdmin = tnxFeeForAdmin.toFixed(5);

      if (finalTnxFeeForAdmin <= 0) return true;
      const feeAmount = ethers.utils.parseUnits(
        `${finalTnxFeeForAdmin}`,
        commonData.usdcDecimals
      );

      const gasPrice = await provider.getGasPrice();
      const gasPriceWithBuffer = gasPrice.mul(120).div(100);
      console.log(
        ethers.utils.formatEther(gasPriceWithBuffer.toString()),
        "gas price with buffer"
      );

      const gasLimit = await usdcContract.estimateGas.transfer(
        config?.tnxFeeWalAddress,
        feeAmount
      );

      const gasLimitWithBuffer = gasLimit.mul(120).div(100); // 120% of the estimate
      console.log(
        ethers.utils.formatEther(gasLimitWithBuffer.toString()),
        "gasLimitWithBuffer"
      );

      console.log(config?.tnxFeeWalAddress, "Admin sender address");

      if (tnxFeeForAdmin > 0) {
        const tnx = await usdcContract.transfer(
          config?.tnxFeeWalAddress,
          feeAmount,
          {
            gasPrice: gasPriceWithBuffer,
            gasLimit: gasLimitWithBuffer,
          }
        );
        const waitTnx = await tnx.wait();
        if (waitTnx?.transactionHash && waitTnx?.status === 1) {
          const dataOne = {
            walletAddress: address,
            userId: userId,
            token: tnxFeeForAdmin,
            paymentMethod: "USDC",
            transactionType: "Transaction gas fee", //case-sensitive,
            exchangedToken: tnxFeeForAdmin,
            transactionHash: waitTnx?.transactionHash,
          };

          const res = await createBlockchainTransaction(dataOne);
          console.log(res.data);
          return true;
        } else {
          throw new Error(`withdraw Gas fee failed - ${waitTnx}`);
        }
      }
    } catch (error) {
      throw new Error(`Error from Withdraw - ${error}`);
    }
  };

  // const { data, isLoading } = useQuery(
  //   ["overviewWallet"],
  //   async () => {
  //     try {
  //       await delay(400);
  //       const walletOverView = await getWalletOverview({});
  //       console.log(data);
  //       return walletOverView?.data?.data;
  //     } catch (error) {
  //       // seth2hLoading(false);
  //     }
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  const handleDeposit = async (e) => {
    const destination = "0xDe40A2025D6D33275EaAcC57410aC8E7c57026cD";
    const txOptions = {
      gasLimit: 21000, // Set the gas limit
    };
    const amount = "1";

    await makeMagic.wallet.showSendTokensUI();
    // await makeMagic.wallet.showUI();
    // console.log("TRANSACTION => ", transaction);
  };

  if (isConfigLoading) {
    return <Loader />;
  }
  return (
    <>
      <WithdrawPopup
        open={isSuccessDialogOpen}
        // open={true}
        setOpen={setIsSuccessDialogOpen}
        amount={tokenAmount}
        id={transcId}
        symbol={"USDC"}
      />

      {/* <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            width={{ sm: "100%" }}
            sx={{
              overflow: "hidden",
              background: "#03364C",
              borderRadius: "27px",
              // padding: 4,
              // height: "100%",
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              // gap: 2,
            }}
          >
            <Typography variant="h6" fontWeight={500} sx={{ paddingX: 2 }}>
              Magic wallet withdraw
            </Typography>

            <Box padding={2}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Wallet Address : {user?.customWalletAddress}
              </Typography>
            </Box>

            <Box paddingX={2} marginTop={4}>
              <Button
                sx={{
                  color: "white",
                  width: "100%",
                  paddingY: 2,
                  borderRadius: "8px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  textTransform: "none",
                  fontWeight: 600,
                }}
                onClick={async () => {
                  await handleDeposit();
                }}
              >
                Withdraw
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid> */}

      <Grid container display={"flex"} spacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "#03364C",
              borderRadius: "27px",
              // padding: 4,
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              paddingBottom: 6,
            }}
          >
            {/* //TODO: magic withdraw */}
            {/* <Typography variant="h5" fontWeight={500} sx={{ paddingX: 2 }}>
              Withdraw
            </Typography>

            <Box padding={2} width={{ sm: "100%", md: "80%" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 2,
                }}
              >
                Current Balance
              </Typography>

              <UsdcWalletCard data={user} disabled={true} />
            </Box> */}

            <Box padding={2}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Enter Amount
              </Typography>

              <Box
                display={"flex"}
                sx={{
                  background: "#011F2D",
                  borderRadius: "8px",
                  alignItems: "center",
                  padding: isSmallScreen ? 0 : 1,
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  // className={classes.customNumberField}
                  variant="outlined"
                  value={tokenAmount}
                  onChange={handleTokenAmountChange}
                  placeholder="Enter token amount"
                  InputProps={{ inputProps: { min: "0" } }}
                  inputProps={{
                    style: {
                      color: "white",
                      width: "300px",
                      fontWeight: 600,
                      fontSize: 20,
                    },
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#011F2D",
                    borderRadius: "8px",
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                <Typography marginRight={2}>USDC</Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{
                  pt: 2,
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Destination
              </Typography>

              <Box
                display={"flex"}
                sx={{
                  background: "#011F2D",
                  borderRadius: "8px",
                  alignItems: "center",
                  padding: isSmallScreen ? 0 : 1,
                }}
              >
                <TextField
                  type="string"
                  fullWidth
                  // className={classes.customNumberField}
                  variant="outlined"
                  value={desWalAddress}
                  onChange={handDesWalAddressChange}
                  placeholder="Enter wallet address"
                  InputProps={{ inputProps: { min: "0" } }}
                  inputProps={{
                    style: {
                      color: "white",
                      width: "300px",
                      fontWeight: 600,
                      fontSize: 20,
                    },
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#011F2D",
                    borderRadius: "8px",
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                {/* <Typography marginRight={2}>USDC</Typography> */}
              </Box>
            </Box>

            <Typography variant="caption" paddingX={2}>
              Select Receiving Account
            </Typography>
            <DepositePaymentMethods
              cardOptions={CARDS_OPTIONS}
              paymentOptions={PAYMENT_OPTIONS}
              isheaderShow={false}
            />

            {/* //TODO: magic withdraw */}
            {/* <Box padding={2}>
              <Button
                sx={{
                  color: "white",
                  width: "100%",
                  paddingY: 2,
                  borderRadius: "8px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  textTransform: "none",
                  fontWeight: 600,
                }}
                onClick={async () => {
                  // await makeMagic.wallet.showSendTokensUI();
                  handleDeposit();
                }}
              >
                Magic Withdraw
              </Button>
            </Box> */}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "#03364C",
              borderRadius: "27px",
              padding: 2,
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              paddingBottom: 12,
              height: "100%",
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{}}>
              Withdrawal Summary
            </Typography>

            <Box
              sx={{
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                height: 65,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 500,
                  fontSize: isSmallScreen ? 14 : 22,
                }}
              >
                {
                  /* {tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0} */ tokenAmount ??
                    0.0
                }
                <Typography
                  variant="caption"
                  marginLeft={1}
                  sx={{
                    fontSize: isSmallScreen ? 8 : 12,
                  }}
                >
                  USDC
                </Typography>
              </Typography>
            </Box>

            <Box
              sx={{
                background: "#011F2D",
                borderRadius: "19px",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginTop: 3,
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Frequency
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  One Time
                </Typography>
              </Stack>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  ${tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Transaction Charges
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  {configData?.transactionCharge ?? 0}%
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                  color={"white"}
                >
                  {/* {tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0} */}
                  {totalAmount ? Number(totalAmount) : 0.0}
                </Typography>
              </Stack>
            </Box>

            <Button
              sx={{
                color: "white",
                width: "100%",
                paddingY: 2,
                borderRadius: "8px",
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                textTransform: "none",
                fontWeight: 600,
              }}
              onClick={() => handleDialogOpen()}
              disabled={
                !tokenAmount || !desWalAddress || parseFloat(tokenAmount) <= 0
              }
            >
              Confirm Withdrawal
            </Button>
            {/* <Button
              sx={{
                color: "white",
                width: "100%",
                paddingY: 2,
                borderRadius: "8px",
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                textTransform: "none",
                fontWeight: 600,
              }}
              onClick={async () => {
                await makeMagic.wallet.showSendTokensUI();
                // handleDeposit();
              }}
            >
              Magic Withdraw
            </Button> */}
          </Box>
        </Grid>
      </Grid>

      <ConfirmDialogue
        isLoading={isLoading}
        open={isDialogOpen}
        handleClose={handleDialogClose}
        title="Confirmation"
        content={`You want to withdraw $${Number(tokenAmount).toFixed(
          2
        )} USDC? `}
        handleConfirm={handleCustomWithdrawButtonClick}
      />
    </>
  );
};

export default WithdrawalPage;
