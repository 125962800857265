import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { addUserSelectedCryptos } from "../../Services/Service";
import { useTheme } from "@emotion/react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";

const SuggestionCryptoCard = ({
  data,
  setSuggestions,
  setQuery,
  setTerm,
  setOpen,
  selectedCryptos,
  setSelectedCryptos,
}) => {
  const userId = sessionStorage.getItem("userId");
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const { mutate } = useMutation(
    async (newCrypto) => {
      console.log(newCrypto);
      const res = await addUserSelectedCryptos(newCrypto.body);
      if (res.data.data === "UPDATED") {
        setSelectedCryptos([...selectedCryptos, newCrypto.data]);
        setTerm("");
        setQuery("");
        setSuggestions([]);
        setOpen(false);
        // return refetch();
        return toast.success("Successfully added.!");
      } else if (res.data.data === "ALREADY_UPDATED") {
        return toast.success("Successfully added.!");
      } else {
        return toast.error("Something went wrong.");
      }
    }
    // {
    // onSuccess: () => {
    //   // queryClient.invalidateQueries("selectedCryptos");
    //   // queryClient.invalidateQueries("searchCryptos");
    //   // refetch();
    //   toast.success("Successfully added.!");
    // // },
    // onError: () => {
    //   toast.error("Something went wrong.");
    // },
    // }
  );

  const handleSubmit = (data) => {
    mutate({
      body: {
        user: userId,
        cryptoCurrency: data._id,
      },
      data: data,
    });
  };

  console.log(data, "data");
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#022737",
          padding: 2,
          borderRadius: "16px",
          border: 1,
          borderColor: "#11B1F4",
          minWidth: "230px",
          display: "flex",
          height: "68px",
          position: "relative",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          "&:hover": {
            background: "#11B1F4",
            cursor: "pointer",
          },
        }}
        onClick={() => handleSubmit(data)}
      >
        {data.isExist === true ? (
          <>
            {" "}
            <Button
              sx={{ position: "absolute", top: -16, right: -28 }}
              // onClick={() => handleSubmit(data)}
            >
              <img
                width="20px"
                height="20px"
                src="/Images/deselect.png"
                alt="power-off-button"
              />
            </Button>
          </>
        ) : (
          <Button
            sx={{ position: "absolute", top: -16, right: -28 }}
            // onClick={() => handleSubmit(data)}
          >
            <img
              width="20px"
              height="20px"
              src="/Images/plus-symbol.png"
              alt="power-off-button"
            />
          </Button>
        )}

        <Box sx={{ display: "flex", gap: 1 }}>
          <img
            src={data.image}
            style={{ width: "40px", height: "40px" }}
            alt=""
          />
          <Box>
            <Typography sx={{ fontWeight: 600, fontSize: isSmall && 14 }}>
              {data.symbol}
            </Typography>
            <Typography
              variant="caption"
              fontSize={isSmall ? 10 : 14}
              color={"gray"}
            >
              {data.name}
            </Typography>
          </Box>
        </Box>

        {/* <img src="/Images/line-chart.png" style={{ width: "50px" }} alt="" /> */}

        <Box>
          <Typography>{data.price?.toFixed(4)}</Typography>
          <Typography sx={{ color: "#AAAAAA" }}>{data.percentage}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default SuggestionCryptoCard;
