import { IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./roadmap_new.css";
import { useNavigate } from "react-router-dom";

const RoadMap = () => {
  const navigate = useNavigate();
  return (
    <div
      className="roadMapPage"
      style={{
        // position: "relative",
        backgroundImage: "url(Images/landingPage/roadMap-bg1.png)",
        backgroundSize: "cover", // Ensure the image covers the entire div
        backgroundPosition: "center", // Center the image in the div
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
        height: "100%", // Make sure the div takes up the full viewport height
        width: "100%", // Make sure the div takes up the full width
        // background: "linear-gradient(#042935 100%, #051016 100%)",
        // objectFit: "fill",
      }}
    >
      <div
        className="roadMapPage"
        style={{
          // position: "absolute",
          backgroundImage: "url(Images/landingPage/roadMap-bg2.png)",
          backgroundSize: "cover", // Ensure the image covers the entire div
          backgroundPosition: "center", // Center the image in the div
          backgroundRepeat: "no-repeat", // Prevent the image from repeating
          height: "100%", // Make sure the div takes up the full viewport height
          width: "100%", // Make sure the div takes up the full width
        }}
      >
        {/* <div
          style={{
            height: "100%", // Make sure the div takes up the full viewport height
            width: "100%", // Make sure the div takes up the full width
            background: "linear-gradient(#042935 100%, #051016 100%)",
            opacity: 0.4,
          }}
        ></div> */}
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => navigate(-1)}
          sx={{ position: "absolute", top: 45, left: { md: 25, xs: 5 } }}
        >
          <ArrowBackIcon
            sx={{
              color: "#11b1f4",
            }}
            fontSize="large"
          />
        </IconButton>
        <Typography
          sx={{
            color: "#11B1F4",
            fontWeight: 600,
            fontSize: { md: "48px", xs: "30px" },
            paddingY: 6,
          }}
          textAlign={"center"}
        >
          Road Map
        </Typography>
        {/* <VerticalTimeline lineColor="#11B1F4">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          //   date="2010 - 2014"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          // icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            My Random High School, Random Place, Random State
          </h3>
          <p> High School Diploma</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2014 - 2018"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          // icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            My Cool University, Vancouver, British Columbia
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>

          <p> Computer Science</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          //   date="2018 - 2020"
          iconStyle={{
            background:
              "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
            color: "#11B1F4",
          }}
          // icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Back End Engineer - Google
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4>
          <p>Developed the backend infrastructure for 3 projects.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2020 - present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          // icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Full Stack Engineer - Twitch
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4>
          <p>
            Helped the team launch 2 major features by working both in the front
            end and back end.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline> */}

        {/* <div className="container-rm">
        <ul>
          <li>
            <h3 className="title-rm">Frontent</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
              ratione sapiente sit nulla quisquam tempore beatae accusantium
              dolor pariatur eveniet
            </p>
            <a href="#">Read More</a>
            <span className="circle"></span>

            <span className="date">Jan 2024</span>
          </li>

          <li>
            <h3 className="title-rm">Frontent</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
              ratione sapiente sit nulla quisquam tempore beatae accusantium
              dolor pariatur eveniet
            </p>
            <a href="#">Read More</a>
            <span className="circle"></span>

            <span className="date">Jan 2024</span>
          </li>
          <li>
            <h3 className="title-rm">Frontent</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
              ratione sapiente sit nulla quisquam tempore beatae accusantium
              dolor pariatur eveniet
            </p>
            <a href="#">Read More</a>
            <span className="circle"></span>

            <span className="date">Jan 2024</span>
          </li>

          <li>
            <h3 className="title-rm">Frontent</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem
              ratione sapiente sit nulla quisquam tempore beatae accusantium
              dolor pariatur eveniet
            </p>
            <a href="#">Read More</a>
            <span className="circle"></span>

            <span className="date">Jan 2024</span>
          </li>
        </ul>
      </div> */}

        {/* <section class="timeline-section"> */}
        <div class="timeline-items">
          <div class="timeline-item">
            <div className="timeline-dot-wrapper">
              <div class="timeline-dot"></div>
            </div>
            {/* <div class="timeline-date">2015</div> */}
            <div class="timeline-content">
              <h3>Q3 24</h3>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Marketing Push</p>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Third Party Payment Intigration</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>New Game Crypto Parlay</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>UI/UX Enhancement</p>
              </div>
              <div
                style={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                  gap: "4px",
                  // flexWrap: "wrap",
                }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column", // Correct property name
                    justifyContent: "center",
                    alignItems: "flex-start", // Change to flex-start for left alignment
                    flexShrink: 0, // Prevent text from shrinking
                  }}
                >
                  <p style={{ margin: 0, whiteSpace: "nowrap" }}>
                    CHAMPS Token Pre-Sale Countdown
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="timeline-item">
            <div className="timeline-dot-wrapper">
              <div class="timeline-dot"></div>
            </div>
            {/* <div class="timeline-date">2015</div> */}
            <div class="timeline-content">
              <h3>Q4 24</h3>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Onboard Additional Tokens</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Live Chat Help/Support</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Strategic Partnerships</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>CHAMPS Token Launch</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Strategic Partnerships</p>
              </div>
            </div>
          </div>
          <div class="timeline-item">
            <div className="timeline-dot-wrapper">
              <div class="timeline-dot"></div>
            </div>
            {/* <div class="timeline-date">2015</div> */}
            <div class="timeline-content">
              <h3>Q1 25</h3>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Enhance Game Functionality</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Additional Partnerships</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>CHAMPS Air Drops</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Affiliate Program</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Staking + Dynamic NFT rev Share</p>
              </div>
            </div>
          </div>
          <div class="timeline-item">
            <div className="timeline-dot-wrapper">
              <div class="timeline-dot"></div>
            </div>
            {/* <div class="timeline-date">2015</div> */}
            <div class="timeline-content">
              <h3>Q2 25</h3>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Enhance Game Functionality</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>Additional Partnerships</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>New Game “Last Man Standing”</p>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <img
                  src="/Images/logo1.png"
                  style={{ width: "26px", height: "24px" }}
                  alt=""
                />
                <p>AI Integration</p>
              </div>
            </div>
          </div>
        </div>
        {/* </section> */}
      </div>
    </div>
  );
};

export default RoadMap;
