import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { getBiggestWinnerRules, getTodayWinners } from "../../Services/Service";
import { useQuery } from "react-query";
import TodayWinners from "../../Components/Game/TodayWinners";
import GameRules from "../../Components/Game/GameRules";

const BiggestWinnerBanner = ({ landing }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);
  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // const fetchBiggestWinnerRules = async () => {
  //   try {
  //     // await delay(800);
  //     const response = await getBiggestWinnerRules();
  //     return response.data?.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchTodayWinners = async () => {
    try {
      const response = await getTodayWinners("Biggest Winner");
      return response.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: todayWinners,
    // isLoading: isTodayWinnersLoading
  } = useQuery(
    {
      queryKey: ["todayWinners"],
      queryFn: fetchTodayWinners,
      enabled: true,

      refetchOnWindowFocus: false,
    }
    // {
    //   staleTime: Infinity,
    //   cacheTime: 0,
    // }
  );

  // const { data, isLoading, refetch } = useQuery({
  //   queryKey: ["biggestwinnerRules"],
  //   queryFn: fetchBiggestWinnerRules,
  //   enabled: true,
  //   refetchOnWindowFocus: false,
  //   cacheTime: 10 * 1000,
  // });

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    const fetchBiggestWinnerRules = async () => {
      try {
        setLoading(true);
        // await delay(800);
        await getBiggestWinnerRules()
          .then((response) => setRules(response.data?.data))
          .catch((error) => console.log(error))
          .finally(() => setLoading(false));
        // setRules(response.data?.data);
        // return response.data?.data;
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchBiggestWinnerRules();
  }, []);

  console.log(rules, "BW");
  console.log(todayWinners, "TW");

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          borderRadius: "22px",
          width: "100%",
          height:
            isMobile && landing
              ? "317.67px"
              : isMobile && !landing
              ? "700px"
              : "317.67px",
          position: "relative",
        }}
      >
        {/* <img
          style={{
            display: isMobile ? "none" : "block",
            objectFit: "cover",
            borderRadius: "22px",
          }}
          width={"100%"}
          height={"100%"}
          src="/Images/banner-bw.png"
          alt=""
        /> */}
        <video
          ref={videoRef}
          style={{
            display: isMobile ? "none" : "block",
            objectFit: "cover",
          }}
          className="background-video"
          loop
          muted
        >
          <source src="/video/Bigwinner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <Box
          sx={{
            background: "transparent",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            borderRadius: "22px",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: landing ? "end" : "space-between",
            alignItems: isMobile ? "center" : "unset",
            padding: { md: 2, xs: 0 },
            // gap: isMobile ? 3 : 0,
          }}
        >
          {/* Left */}

          {/* {isLoading ? (
            <>
              {" "}
              <Skeleton
                sx={{
                  display: landing ? "none" : "block",
                  bgcolor: "black",
                  width: isMobile ? "100%" : "300px",
                  height: "100%",
                  borderRadius: "25px",
                  paddingX: 2,
                }}
                variant="rectangular"
              />
            </>
          ) : ( */}
          <GameRules landing={landing} data={rules} loading={loading} />
          {/* )} */}

          {/*right  */}
          <TodayWinners todayWinners={todayWinners} />
        </Box>
      </Box>
    </>
  );
};

export default BiggestWinnerBanner;
