import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SignupDetails from "./SignupDetails";
import RegisterSidebar from "../../Components/Sidebar/RegisterSidebar";
import {
  RecaptchaVerifier,
  signInWithCustomToken,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  checkEmail,
  checkNumber,
  checkWalletAddress,
  createCustomToken,
} from "../../Services/Service";
import { googleAuth } from "../../Utils/firebaseConfig";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Utils/firebaseConfig";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PhoneInput from "react-phone-input-2";
import AvatarGroups from "../../Components/AvatarGroup";
import BackButton from "../../Components/BackButton";
import toast from "react-hot-toast";
import { ethers } from "ethers";

const SignUpWithPhone = () => {
  const navigate = useNavigate();
  const [singupDetails, setSignupDetails] = useState(false);
  // const [numberState, setnumberState] = useState("initial");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loginType, setloginType] = useState("");
  // const [otp, setOtp] = useState("");
  // const [phoneresult, setPhoneResult] = useState(null);
  // const [error, setError] = useState("");

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const isxSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);

  useEffect(() => {
    setIsMetamaskInstalled(!!window.ethereum);
  }, []);

  const handleGoogleSignup = async () => {
    const googleData = await googleAuth();
    if (googleData) {
      const email = googleData.user.email;
      const uniqueEmail = await checkEmail(email);
      console.log(uniqueEmail, "uniqueEmail");
      if (uniqueEmail.data) {
        toast.error("Account already exists,Please login to continue");
      } else {
        // localStorage.setItem("token",googleData.user.accessToken)
        sessionStorage.setItem("token", googleData.user.accessToken);
        setEmail(email);
        setloginType("Email");
        setSignupDetails(true);
      }
    }
  };

  const onSignup = async (event) => {
    event.preventDefault();
    // const modifiedString = phone.substring(2);
    console.log(phone);
    const uniqueNumber = await checkNumber(phone);
    console.log(typeof phone);
    console.log(uniqueNumber);

    if (uniqueNumber.data) {
      return toast.error("Account already exists,Please login to continue");
    } else {
      try {
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,

            "recaptcha-container",
            {
              size: "invisible",
            }
          );
        } else {
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,

            "recaptcha-container",
            {
              size: "invisible",
            }
          );
        }

        let recaptchaVerifier = window.recaptchaVerifier;

        const formatPh = "+" + phone;

        let confirmation = await signInWithPhoneNumber(
          auth,
          formatPh,
          recaptchaVerifier
        );

        if (confirmation) {
          window.confirmationResult = confirmation;
          // setPhoneResult(confirmation);
          // setnumberState("otp");
          toast.success("OTP sent successfully");
          navigate("/signup-otp-verify", {
            state: { phone: phone },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleMetamaskSignup = async () => {
    try {
      if (!isMetamaskInstalled) {
        return toast.error("Metamask is not installed.");
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const singner = provider.getSigner();
      const address = await singner.getAddress();
      console.log(address);
      const uniqueAddress = await checkWalletAddress(address);
      console.log(uniqueAddress.data);
      if (uniqueAddress.data?.data) {
        return toast.error("Account already exists, Please login.");
      } else {
        const customToken = await createCustomToken(address);
        const accessToken = customToken.data?.data;
        const token = await signInWithCustomToken(auth, accessToken);
        sessionStorage.setItem("token", token.user.accessToken);
        setloginType("MetaMask");
        setSignupDetails(true);
        return navigate("/signup-details", {
          state: { loginWalletAddress: token.user?.uid, loginType: "MetaMask" },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#03364c",
      }}
    >
      <Grid container>
        <RegisterSidebar src={"/Images/signup-im.png"} />

        <Grid
          item
          xs={12}
          lg={6}
          sx={{ paddingY: 10 }}
          className="register-right-side"
        >
          {singupDetails ? (
            <SignupDetails phone={phone} email={email} loginType={loginType} />
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={5}
              marginBottom={10}
              padding={isSmall && 1}
            >
              <BackButton />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isSmall && "center",
                  // alignItems: "center",
                  gap: "40px",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isxSmall ? "column" : "row",
                      alignItems: isxSmall ? "start" : "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        color: "white",
                        fontWeight: 300,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Hi,
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "white",
                        fontWeight: 700,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Welcome Back
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "white",
                        fontWeight: 300,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Ready to be a{" "}
                      <span style={{ color: "#10ADEF", fontWeight: "700" }}>
                        Champ
                      </span>{" "}
                      ?
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        // fontWeight: 300,
                        marginTop: 2,
                        marginBottom: 2,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Please enter your Mobile Number for continue!
                    </Typography>
                  </Box>
                </Box>

                <Stack
                  direction={isSmall ? "column" : "row"}
                  justifyContent={"space-between"}
                  marginRight={isSmall ? 0 : 10}
                  sx={{ maxWidth: "600px", width: "100%" }}
                  spacing={3}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={isSmall && "center"}
                    justifyContent={"center"}
                    gap={4}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          // fontWeight: 300,
                          marginBottom: 1,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Your phone number
                      </Typography>

                      {/* <input
                        style={{
                          border: "none",
                          backgroundColor: "#011F2D",
                          width: isxSmall ? "260px" : "360px",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                        placeholder="youremal@email.com"
                      /> */}

                      <PhoneInput
                        country={"us"}
                        inputProps={{
                          name: "phone",
                          required: true,
                          // autoFocus: true,
                        }}
                        fullWidth="true"
                        inputStyle={{
                          border: "none",
                          paddingLeft: "60px",
                          width: isxSmall ? "200px" : "330px",
                          background: "#011F2D",
                          color: "white",
                          padding: "30px",
                          fontSize: "16px",
                          marginLeft: "70px",
                          borderRadius: "8px",
                        }}
                        buttonStyle={{
                          background: "#011F2D",
                          border: "none",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                        containerStyle={{
                          background: "#03364C",
                        }}
                        disableDropdown
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </Box>
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: isxSmall ? "270px" : "400px",
                        paddingY: "10px",
                        color: "#fffff",
                        fontWeight: 900,
                        textTransform: "none",
                        borderRadius: "8px",
                        background: "#11B1F4",
                        "&:hover": {
                          backgroundColor: " #10ADEF95",
                        },
                      }}
                      variant="contained"
                      onClick={onSignup}
                    >
                      Verify
                    </Button>
                  </Box>

                  <Box display={!isSmall && "flex"}>
                    <Divider
                      orientation={isSmall ? "horizontal" : "vertical"}
                      sx={{
                        backgroundColor: " #11B1F4",
                        borderRightWidth: 1,
                        height: !isSmall && "255px",
                      }}
                    />

                    <Box
                      sx={{
                        marginLeft: 2,
                        display: "flex",
                        flexDirection: isSmall ? "row" : "column",
                        gap: isxSmall ? 0.5 : 2,
                        alignItems: "center",
                        justifyContent: isSmall ? "start" : "center",
                        marginTop: isSmall ? 2 : 0,
                        height: !isSmall && "250px",
                      }}
                    >
                      <Typography variant="caption" color={"white"}>
                        Or continue with
                      </Typography>

                      <Box
                        width={50}
                        height={50}
                        borderRadius={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={handleGoogleSignup}
                        sx={{
                          background: "#011F2D",
                          cursor: "pointer",
                          "&:hover": {
                            border: 1,
                            borderColor: "#11B1F4",
                            background: "none",
                          },
                        }}
                      >
                        <img
                          style={{ width: "45px" }}
                          src="/Images/login-g.png"
                          alt="login"
                        />
                      </Box>

                      <Box
                        width={50}
                        height={50}
                        bgcolor={"#011F2D"}
                        borderRadius={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          border: 1,
                          borderColor: "#11B1F4",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          style={{ width: "20px" }}
                          src="/Images/login-p.png"
                          alt="login"
                        />
                      </Box>
                      {/* <Box
                        width={50}
                        height={50}
                        borderRadius={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          background: "#011F2D",
                          cursor: "pointer",
                          "&:hover": {
                            border: 1,
                            borderColor: "#11B1F4",
                            background: "none",
                          },
                        }}
                        onClick={handleMetamaskSignup}
                      >
                        <img
                          style={{ width: "28px" }}
                          src="/Images/login-m.png"
                          alt="login"
                        />
                      </Box> */}
                    </Box>
                  </Box>
                </Stack>

                <div
                  id="recaptcha-container"
                  stlye={{ position: "absolute", zIndex: "999" }}
                ></div>

                {/* {numberState === "verify" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <PhoneInput
                    country={"us"}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    disableDropdown
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
  
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none", marginTop: "20px" }}
                    size="small"
                    onClick={handlePhoneLogin}
                    id="sign-in-button"
                  >
                    Verify
                  </Button>
                </div>
              ) : (
                ""
              )}
              {numberState === "otp" ? (
                <>
                  <h5 style={{ margin: 0 }}>Enter OTP received</h5>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{ width: "35px", height: "25px" }}
                  />
                  <Button variant="contained" onClick={handleOTPverify}>
                    check OTP
                  </Button>
                </>
              ) : (
                ""
              )} */}

                {/* <Box position={"relative"} sx={{}}> */}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={{ md: "row", xs: "column" }}
                  gap={2}
                  justifyContent={isSmall && "start"}
                >
                  <AvatarGroups />
                  <Box display={"flex"} flexDirection={"column"} marginLeft={3}>
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        // fontWeight: 300,
                        fontFamily: "Montserrat",
                        marginBottom: 1,
                      }}
                    >
                      Already have an account?{" "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => navigate("/login")}
                        sx={{ color: "white" }}
                      >
                        Login
                      </Link>
                    </Typography>
                    <Typography variant="caption" color={"white"}>
                      100+ Users joined last Month
                    </Typography>
                  </Box>
                </Box>
                {/* </Box> */}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpWithPhone;
