import { ethers } from "ethers";
import { makeMagic } from "./magic";
import feedAbi from "../Contract/feed_contract.json";

export const feedSmartContract = () => {
  const claimFund = async (
    contractAddress,
    walletAddress,
    provider,
    signer
  ) => {
    try {
      // const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);

      // // ⭐️ After user is successfully authenticated
      // const signer = provider.getSigner();

      console.log(walletAddress, "user wallet address - whitelistedAddresses");

      const feedContract = new ethers.Contract(
        contractAddress,
        feedAbi,
        signer
      );
      // Estimate the gas limit for the transaction
      let gasLimit;
      try {
        gasLimit = await feedContract.estimateGas.claim();
        console.log(gasLimit.toString(), "Estimated gas limit for claim");
      } catch (estimateError) {
        console.error(
          "Gas estimation failed, using manual gas limit:",
          estimateError
        );
        gasLimit = ethers.BigNumber.from("200000"); // Set a manual gas limit if estimation fails
        console.log(gasLimit.toString(), "Manual gas limit set");
      }

      const gasPrice = await provider.getGasPrice();
      console.log(gasPrice.toString(), "Current gas price");
      const gasPriceWithBuffer = gasPrice.mul(120).div(100); // Add a 20% buffer to the gas price
      console.log(gasPriceWithBuffer.toString(), "Gas price with 20% buffer");

      const claim = await feedContract.claim({
        gasLimit: gasLimit,
        gasPrice: gasPriceWithBuffer,
      });
      console.log(claim.hash, "Transaction hash from claim");

      const tnx = await claim.wait();
      console.log(tnx.transactionHash, "Transaction mined successfully");
      return tnx;
    } catch (error) {
      console.log(error, "Error during claim process");
      throw error;
    }
  };

  return {
    claimFund,
  };
};

export const { claimFund } = feedSmartContract();
