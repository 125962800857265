import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";
import { Box, CircularProgress, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ConfirmDialogue = ({
  open,
  handleClose,
  title,
  content,
  handleConfirm,
  isLoading = true,
}) => {
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        overflow: "hidden",
        boxShadow: "none",
        "& .MuiDialog-paper": {
          borderRadius: "20px",
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor: "white",
          padding: 2,
          width: isMobile ? "100%" : "350px",
          height: "100%",
          paddingY: 4,
          position: "relative",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "gray",
            margin: 1,
            "&:hover": {
              color: "#11B1F4",
              backgroundColor: "transparent",
            },
          }}
          size="medium"
          onClick={handleClose}
        >
          <CloseIcon fontSize="medium" sx={{ color: "gray" }} />
        </IconButton>
        <img
          style={{
            width: 65,
            height: 65,
            marginTop: 4,
          }}
          src="/Images/popups/confirm.png"
          alt=""
        />
        <DialogTitle
          sx={{
            color: "black",
            fontWeight: 600,
          }}
          id="responsive-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "gray",
            }}
            id="alert-dialog-slide-description"
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            sx={{
              backgroundColor: "#F41111",
              color: "white",
              textTransform: "none",
              paddingX: 2,
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: alpha("#F41111", 0.5),
              },
            }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {/* <Button */}
          <LoadingButton
            loading={isLoading ?? false}
            onClick={handleConfirm}
            sx={{
              backgroundColor: "#58B112",
              color: "white",
              textTransform: "none",
              paddingX: 2,
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: alpha("#58B112", 0.5),
              },
              "&.MuiLoadingButton-loading": {
                backgroundColor: "#58B112", // Keep the same background color when loading
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "white", // Set spinner color if needed
                },
              },
            }}
            variant="contained"
            loadingIndicator={
              <CircularProgress size={16} sx={{ color: "#ffffff" }} />
            }
            // autoFocus
          >
            Confirm
          </LoadingButton>
          {/* </Button> */}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialogue;
