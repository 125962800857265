import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const TermsAndCondition = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div
        style={{
          width: "300px",
          position: "absolute",
          // borderRadius: "50%",
          borderBottomRightRadius: "30%",
          overflow: "hidden",
          top: 120,
          display: isSmallScreen ? "none" : "block",
          background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
          filter: "blur(146px)",
          height: "208px",
          opacity: "0.6",
        }}
      />
      <Container>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: { xs: 4, md: 8 },
            marginBottom: { xs: 8, md: 12 },
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "28px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={1.2}
            sx={{ color: "#FFFFFF" }}
          >
            Terms & Conditions
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={10}>
          <Typography
            variant="caption"
            fontSize={{ md: "40px", sm: "24px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={{ xs: 1, md: 1.2 }}
            sx={{ color: "#FFFFFF" }}
          >
            Introduction
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 2 },
              fontSize: { xs: "14px", md: "18px" },
            }}
          >
            Welcome to Crypto Champs! By using our website and/or using the
            services that are provided, you acknowledge that you have read,
            understood, and agree to be bound by our Terms and Conditions. These
            Terms and Conditions unconditionally extend and apply to all related
            applications, internet service, or website extensions. If you are
            not in agreement with all of these Terms and Conditions, you are
            prohibited from using this Website, and you may discontinue use
            immediately. Crypto Champs recommends that you save or print a copy
            of these Terms and Conditions for future reference.
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={10}>
          <Typography
            variant="caption"
            fontSize={{ md: "40px", sm: "24px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={{ xs: 1, md: 1.2 }}
            sx={{ color: "#FFFFFF" }}
          >
            Agreement to Terms and Conditions
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{ color: "#FFFFFF", lineHeight: { xs: 1.6, md: 2 } }}
          >
            Welcome to Crypto Champs! By using our website and/or using the
            services that are provided, you acknowledge that you have read,
            understood, and agree to be bound by our Terms and Conditions. These
            Terms and Conditions unconditionally extend and apply to all related
            applications, internet service, or website extensions. If you are
            not in agreement with all of these Terms and Conditions, you are
            prohibited from using this Website, and you may discontinue use
            immediately. Crypto Champs recommends that you save or print a copy
            of these Terms and Conditions for future reference.
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={10}>
          <Typography
            variant="caption"
            fontSize={{ md: "40px", sm: "24px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={{ xs: 1, md: 1.2 }}
            sx={{ color: "#FFFFFF" }}
          >
            Definitions/Terminology
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{ color: "#FFFFFF", lineHeight: { xs: 1.6, md: 2 } }}
          >
            The following definitions apply to these Terms and Conditions,
            Privacy Statement, Disclaimer Notice and all Agreements: "User",
            "Visitor," "Client," "Customer," "You" and "Your" refers to you, the
            person(s) that use this Website. "Crypto Champs", "We", "Our" and
            "Us", refers to our Website/Company. "Party," "Parties," or "Us,"
            refers to both you and us. All terms refer to all considerations of
            www.cryptochamps.io necessary to undertake support to you for the
            express purpose of meeting your User needs in respect of our
            services, under and subject to, prevailing law of the state or
            country in which www.cryptochamps.io operates ([STATE OR COUNTRY]).
            Any use of these definitions or other glossary in the singular,
            plural, capitalization, and/or pronoun are interchangeable but refer
            to the same.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            // marginTop: 8,
            marginBottom: { md: 12, xs: 2 },
          }}
        >
          {" "}
          {/* <Button
            sx={{
              paddingY: "10px",
              color: "white",
              fontWeight: 400,
              paddingX: "25px",
              textTransform: "none",
              fontFamily: "Space Grotesk",
              borderRadius: "64px",
              marginTop: 4,
              background:
                "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
              "&:hover": {
                backgroundColor: " #10ADEF95",
              },
            }}
            variant="contained"
            onClick={() => {}}
          >
            + Read More
          </Button> */}
        </Box>
      </Container>
    </>
  );
};

export default TermsAndCondition;
