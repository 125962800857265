import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../Components/Loaders/ButtonLoader";
import {
  checkUserExists,
  createCustomToken,
  updateFirebaseUid,
  updateMagicWalletAddress,
} from "../../Services/Service";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useTheme } from "@emotion/react";
import { googleAuth, auth } from "../../Utils/firebaseConfig";
import { ethers } from "ethers";
import { signInWithCustomToken } from "firebase/auth";
import toast from "react-hot-toast";
import { FooterSection } from "../../Components/Auth/FooterSection";
import SocialAuthButton from "../../Components/Auth/SocialAuthButton";
import { magicLinkLogin, makeMagic } from "../../lib/magic";
import { getIdToken } from "firebase/auth";
// import { blockNativeInit } from "../../lib/blocknative_web3_onboard";
import { useConnectWallet } from "@web3-onboard/react";

// blockNativeInit();

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  useEffect(() => {
    setIsMetamaskInstalled(!!window.ethereum);
  }, []);

  const handleGoogleLogin = async () => {
    const googleData = await googleAuth();
    if (googleData) {
      sessionStorage.setItem("token", googleData?.user?.accessToken);
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          const checkUserResult = await checkUserExists();
          if (checkUserResult.data.success) {
            await magicLinkLogin(googleData, checkUserResult);

            sessionStorage.setItem("userId", checkUserResult?.data?.data?._id);
            sessionStorage.setItem(
              "userName",
              checkUserResult?.data.data.username
            );
            setLoading(false);
            navigate("/home");
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          setError(
            "No account found. Want to join? Hit the 'Sign Up' button! "
          );

          await updateFirebaseUid({
            loginType: "Email",
            email: googleData?.user?.email,
          });
          return toast.error(
            "No account found. Want to join? Hit the 'Sign Up' button!"
          );
        }
      } else {
        setError("No account linked to the google account found");
        toast.error("No account linked to the google account found");
        // return toast.error(
        //   "No account found. Want to join? Hit the 'Sign Up' button!"
        // );
      }
    }
  };
  console.log(error);

  const handleMetamaskLogin = async () => {
    try {
      if (!isMetamaskInstalled) {
        return toast.error("Metamask is not installed.");
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const singner = provider.getSigner();
      const address = await singner.getAddress();
      console.log(address);
      const customToken = await createCustomToken(address);
      const accessToken = customToken.data?.data;
      const acc_token = await signInWithCustomToken(auth, accessToken);
      sessionStorage.setItem("token", acc_token.user.accessToken);
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          const checkUserResult = await checkUserExists();
          if (checkUserResult.data.success) {
            sessionStorage.setItem("userId", checkUserResult?.data?.data?._id);
            sessionStorage.setItem(
              "userName",
              checkUserResult?.data.data.username
            );
            setLoading(false);
            navigate("/home");
          }
        } catch (error) {
          setLoading(false);
          setError(
            "No account found. Want to join? Hit the 'Sign Up' button! "
          );
          return toast.error(
            "No account found. Want to join? Hit the 'Sign Up' button!"
          );
        }
      } else {
        setError("No account linked to the metamask account found");
        // toast.error("No account linked to the metamask account found");
      }
    } catch (error) {
      console.log(error);
      // toast.error("No account found. Want to join? Hit the 'Sign Up' button!");
    }
  };

  const connectBlockNativeWallet = async () => {
    try {
      if (wallet) disconnect(wallet);
      const walletConnect = await connect();
      console.log(walletConnect[0]?.accounts[0], "connectwallet");
      console.log(walletConnect, "connectwallet");

      const walletData = walletConnect[0];
      const metaData = walletData?.accounts[0];

      if (metaData?.address) {
        const address = metaData?.address;

        console.log(address);
        const customToken = await createCustomToken(address);
        const accessToken = customToken.data?.data;
        const acc_token = await signInWithCustomToken(auth, accessToken);
        sessionStorage.setItem("token", acc_token.user.accessToken);
        const token = sessionStorage.getItem("token");

        if (token) {
          try {
            setLoading(true);
            const checkUserResult = await checkUserExists();
            if (checkUserResult.data.success) {
              //====
              await magicLinkLogin(acc_token, checkUserResult);
              sessionStorage.setItem(
                "userId",
                checkUserResult?.data?.data?._id
              );
              sessionStorage.setItem(
                "userName",
                checkUserResult?.data.data.username
              );
              setLoading(false);
              navigate("/home");
            }
          } catch (error) {
            console.log(error);
            setLoading(false);
            setError(
              "No account found. Want to join? Hit the 'Sign Up' button! "
            );

            await updateFirebaseUid({
              loginType: "MetaMask",
              loginWalletAddress: address,
            });
            return toast.error(
              "No account found. Want to join? Hit the 'Sign Up' button!"
            );
          }
        } else {
          setError("No account linked to the metamask account found");
          // toast.error("No account linked to the metamask account found");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? <ButtonLoader open={loading ? true : false} /> : ""}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={isSmall && "center"}
        justifyContent={"center"}
        gap={{ xs: 2, md: 4 }}
      >
        <SocialAuthButton
          text={"Continue with Gmail"}
          src={"/Images/login-g.png"}
          onClick={handleGoogleLogin}
          width={"40px"}
          paddingY={1}
        />
        <SocialAuthButton
          text={"Continue with Phone"}
          src={"/Images/login-p.png"}
          onClick={() => navigate("/login-with-phone")}
          width={"22px"}
          paddingY={2}
        />

        <SocialAuthButton
          text={"Continue with Wallet"}
          src={"/Images/login-m.png"}
          onClick={connectBlockNativeWallet}
          width={"28px"}
          paddingY={2}
        />

        {/*     <SocialAuthButton
          text={"Continue with Metamask"}
          src={"/Images/login-m.png"}
          onClick={handleMetamaskLogin}
          width={"28px"}
          paddingY={2}
        />*/}

        {/* <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            // minWidth: "300px",
            paddingX: { md: 10, sm: 8 },
            maxWidth: "550px",
            paddingY: 2,
            color: "#fffff",
            fontWeight: 500,
            textTransform: "none",
            borderRadius: "16px",
            background: "#011F2D",
            "&:hover": {
              border: 1,
              borderColor: "#11B1F4",
              background: "none",
            },
          }}
          variant="contained"
          onClick={() => navigate("/login-with-phone")}
        >
          <img
            style={{ width: "22px", marginRight: "10px" }}
            src="/Images/login-p.png"
            alt="login"
          />
          Continue with Phone
        </Button> */}
      </Box>

      <FooterSection
        text={"Don’t have an account? "}
        link={"Create Account"}
        onClick={"/signup"}
      />
    </>
  );
};

export default Login;
