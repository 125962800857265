import { Magic } from "magic-sdk";
import { OpenIdExtension } from "@magic-ext/oidc";
import { updateMagicWalletAddress } from "../Services/Service";
import { getIdToken } from "firebase/auth";
import { whitelistedAddresses } from "./feedContract";

export const makeMagic = new Magic(
  process.env.REACT_APP_MAGIC_PUBLISHABLE_API_KEY,
  {
    // deferPreload: true,
    // network: {
    //   rpcUrl: "https://rpc.ankr.com/arbitrum", //mainnet
    //   chainId: 42161,
    // },
    // network: {
    //   rpcUrl: "https://sepolia-rollup.arbitrum.io/rpc", // testnet
    //   chainId: 421614,
    // },
    // network: "sepolia",

    //PolyGone - TestNet
    // network: {
    //   rpcUrl: "https://rpc-amoy.polygon.technology/", // Polygon RPC URL
    //   chainId: 80002, // Polygon chain id
    // },

    //PolyGone - Mainnet
    network: {
      rpcUrl: "https://polygon-rpc.com/", // Polygon RPC URL
      chainId: 137, // Polygon chain id
    },

    //zksync-sepolia
    // network: {
    //   rpcUrl: "https://sepolia.era.zksync.dev",
    //   chainId: 300,
    // },
    extensions: [new OpenIdExtension()],
  }
);

export const magicLoginWithODIC = async (token) => {
  const data = await makeMagic.openid.loginWithOIDC({
    token,
    providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
  });
};

//result => firebase data
//checkResult from api
export const magicLinkLogin = async (result, checkUserResult) => {
  const jwt = await getIdToken(result?.user);
  // magicLoginWithODIC(jwt);
  await makeMagic.openid.loginWithOIDC({
    jwt,
    providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
  });

  const isLoggedIn = await makeMagic.user.isLoggedIn();
  if (isLoggedIn === true) {
    const magic = await makeMagic.user.getInfo();
    const data = {
      userId: checkUserResult?.data?.data?._id,
      walletAddress: magic?.publicAddress,
    };
    await updateMagicWalletAddress(data);
  }
};
