import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RegisterSidebar from "../../Components/Sidebar/RegisterSidebar";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import {
  checkUserExists,
  createCustomToken,
  updateFirebaseUid,
  updateMagicWalletAddress,
} from "../../Services/Service";
import { AuthContext } from "../../context/AuthContext";
import {
  RecaptchaVerifier,
  signInWithCustomToken,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth, googleAuth } from "../../Utils/firebaseConfig";
import BackButton from "../../Components/BackButton";
import AvatarGroups from "../../Components/AvatarGroup";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import ButtonLoader from "../../Components/Loaders/ButtonLoader";
import { getIdToken } from "firebase/auth";
import { magicLinkLogin, makeMagic } from "../../lib/magic";
import { LoadingButton } from "@mui/lab";

const OTPPage = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const isxSmall = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  // const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);

  const { phoneresult } = useContext(AuthContext);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);

  console.log(phoneresult, "PH");

  const handleOTPverify = () => {
    setVerifyLoading(true);
    console.log(verifyLoading);
    try {
      window.confirmationResult
        .confirm(otp)
        .then(async (result) => {
          sessionStorage.setItem("token", result.user.accessToken);
          const token = sessionStorage.getItem("token");

          if (token) {
            // try {
            // setLoading(true);
            const checkUserResult = await checkUserExists();
            console.log(checkUserResult?.data?.success, "HANDLE OTPVERIFY");
            if (checkUserResult?.data?.success) {
              await magicLinkLogin(result, checkUserResult);

              sessionStorage.setItem("userId", checkUserResult?.data.data._id);
              sessionStorage.setItem(
                "userName",
                checkUserResult?.data.data.username
              );
              navigate("/home");
              // setLoading(false);
              setVerifyLoading(false);
            } else {
              setVerifyLoading(false);
              return toast.error(
                "No account found. Want to join? Hit the 'Sign Up' button! "
              );
            }
            setVerifyLoading(false);
            // } catch (error) {
            //   // setLoading(false);
            //   toast.error(
            //     "No account found. Want to join? Hit the 'Sign Up' button! "
            // );
          } else {
            setVerifyLoading(false);
            return toast.error(
              "No account found. Want to join? Hit the 'Sign Up' button! "
            );
            //  return toast.error("No account linked to the phone number found");
          }
          // if(checkUserResult){

          //   navigate("/biggestWinner")
          // }else{
          //   console.log("login error")
          // }

          // setSignupDetails(true);
          // ...
        })
        .catch((error) => {
          setVerifyLoading(false);
          // User couldn't sign in (bad verification code?)
          console.log(error);

          if (error?.response?.data?.success === false) {
            updateFirebaseUid({
              loginType: "Phone",
              phone: phoneresult,
            });
            return toast.error(
              "No account found. Want to join? Hit the 'Sign Up' button! "
            );
          }
          return toast.error(
            "Invalid OTP. Please double-check the code you entered and try again."
          );
          // window.location.reload()
          // ...
        });
    } catch (error) {
      console.log(error);
      setVerifyLoading(false);
      return toast.error("Something went wrong");
      // return toast.error(
      //   "Invalid OTP. Please double-check the code you entered and try again."
      // );
    }
  };

  const handleResendCode = async (event) => {
    event.preventDefault();
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      } else {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      }

      let recaptchaVerifier = window.recaptchaVerifier;
      const formatPh = "+" + phoneresult;

      let confirmation = await signInWithPhoneNumber(
        auth,
        formatPh,
        recaptchaVerifier
      );

      if (confirmation) {
        window.confirmationResult = confirmation;
        toast.success("OTP sent successfully");
        // setnumberState("otp");
        // navigate("/login-verification");
      }
    } catch (error) {
      console.log(error.message, "ERROR");
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    setIsMetamaskInstalled(!!window.ethereum);
  }, []);

  const handleGoogleLogin = async () => {
    const googleData = await googleAuth();
    if (googleData) {
      sessionStorage.setItem("token", googleData?.user?.accessToken);
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          const checkUserResult = await checkUserExists();
          if (checkUserResult.data.success) {
            const jwt = await getIdToken(googleData?.user);
            // magicLoginWithODIC(jwt);
            await makeMagic.openid.loginWithOIDC({
              jwt,
              providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
            });

            const isLoggedIn = await makeMagic.user.isLoggedIn();
            if (isLoggedIn === true) {
              const magic = await makeMagic.user.getInfo();
              const data = {
                userId: checkUserResult?.data?.data?._id,
                walletAddress: magic?.publicAddress,
              };
              await updateMagicWalletAddress(data);
            }
            sessionStorage.setItem("userId", checkUserResult?.data.data._id);
            sessionStorage.setItem(
              "userName",
              checkUserResult?.data.data.username
            );
            setLoading(false);
            navigate("/home");
          }
        } catch (error) {
          setLoading(false);
          await updateFirebaseUid({
            loginType: "Email",
            email: googleData?.user?.email,
          });
          toast.error(
            "No account found. Want to join? Hit the 'Sign Up' button! "
          );
        }
      } else {
        toast.error("No account linked to the google account found");
      }
    }
  };

  const handleMetamaskLogin = async () => {
    try {
      if (!isMetamaskInstalled) {
        return toast.error("Metamask is not installed.");
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const singner = provider.getSigner();
      const address = await singner.getAddress();
      console.log(address);
      const customToken = await createCustomToken(address);
      const accessToken = customToken.data?.data;
      const acc_token = await signInWithCustomToken(auth, accessToken);
      sessionStorage.setItem("token", acc_token.user.accessToken);
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          const checkUserResult = await checkUserExists();
          if (checkUserResult.data.success) {
            sessionStorage.setItem("userId", checkUserResult?.data?.data?._id);
            sessionStorage.setItem(
              "userName",
              checkUserResult?.data.data.username
            );
            setLoading(false);
            navigate("/home");
          }
        } catch (error) {
          setLoading(false);
          // setError(
          //   "No account found. Want to join? Hit the 'Sign Up' button! "
          // );
          return toast.error(
            "No account found. Want to join? Hit the 'Sign Up' button!"
          );
        }
      } else {
        toast.error("No account linked to the metamask account found");
        // toast.error("No account linked to the metamask account found");
      }
    } catch (error) {
      console.log(error);
      // toast.error("No account found. Want to join? Hit the 'Sign Up' button!");
    }
  };
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#03364c",
      }}
    >
      <Grid container>
        <RegisterSidebar src={"/Images/verify-im.png"} />
        {loading ? <ButtonLoader open={loading ? true : false} /> : ""}
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ paddingY: 10 }}
          className="register-right-side"
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={5}
            marginBottom={10}
            padding={isSmall && 1}
          >
            <BackButton />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isSmall && "center",
                // alignItems: "center",
                gap: "40px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isxSmall ? "column" : "row",
                    alignItems: isxSmall ? "start" : "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Hi,
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Welcome Back
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Ready to be a{" "}
                    <span style={{ color: "#10ADEF", fontWeight: "700" }}>
                      Champ
                    </span>{" "}
                    ?
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      // fontWeight: 300,
                      marginTop: 2,
                      marginBottom: 2,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Verify your Phone Number
                  </Typography>
                </Box>
              </Box>

              <Stack
                direction={isSmall ? "column" : "row"}
                justifyContent={"space-between"}
                marginRight={isSmall ? 0 : 10}
                sx={{ maxWidth: "600px", width: "100%" }}
                spacing={3}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={isSmall && "center"}
                  justifyContent={"center"}
                  gap={4}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        // fontWeight: 300,
                        marginBottom: 2,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Enter your OTP code here
                    </Typography>

                    {/* <OTPInput
                      // value={otp}
                      // onChange={setOtp}
                      numInputs={6}
                      renderSeparator={
                        <span style={{ marginRight: "15px" }}>{""}</span>
                      }
                      renderInput={(props) => (
                        <input
                          style={{
                            border: "none",
                            backgroundColor: "#011F2D",
                            height: isxSmall ? "35px" : "50px",
                            width: isxSmall ? "35px" : "50px",
                            borderRadius: "8px",
                            color: "white",
                          }}
                          // {...props}
                        />
                      )}
                      inputStyle={{ width: "35px", height: "25px" }}
                    /> */}

                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span style={{ width: "8px" }} />}
                      renderInput={(props) => (
                        <input
                          {...props}
                          class="form-control"
                          style={{
                            background: "#011F2D",
                            width: mobile ? "20px" : "20px",
                            // height: mobile ? "35px" : "50px",
                            color: "white",
                            fontSize: "18px",
                            boxShadow: "none",
                            border: "none",
                            borderRadius: "8px",
                            padding: mobile ? "10px" : "20px",
                          }}
                        />
                      )}
                      shouldAutoFocus={false}
                    />

                    <Stack
                      direction={isxSmall ? "column" : "row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          // fontWeight: 300,
                          marginTop: 2,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Didn't you receive any code?
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#11B1F4",
                          // fontWeight: 300,
                          marginTop: 2,
                          fontFamily: "Montserrat",
                          cursor: "pointer",
                        }}
                        onClick={handleResendCode}
                      >
                        RESEND NEW CODE
                      </Typography>
                    </Stack>
                  </Box>
                  <LoadingButton
                    loading={verifyLoading}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: isxSmall ? "300px" : "400px",
                      paddingY: "10px",
                      color: "#fffff",
                      fontWeight: 900,
                      textTransform: "none",
                      borderRadius: "8px",
                      background: "#11B1F4",
                      "&:hover": {
                        backgroundColor: " #10ADEF95",
                      },
                    }}
                    variant="contained"
                    onClick={handleOTPverify}
                  >
                    Verify
                  </LoadingButton>
                </Box>

                <Box display={!isSmall && "flex"}>
                  <Divider
                    orientation={isSmall ? "horizontal" : "vertical"}
                    sx={{
                      backgroundColor: " #11B1F4",
                      borderRightWidth: 1,
                      height: !isSmall && "255px",
                    }}
                  />

                  <Box
                    sx={{
                      marginLeft: 2,
                      display: "flex",
                      flexDirection: isSmall ? "row" : "column",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: isSmall ? "start" : "center",
                      marginTop: isSmall ? 2 : 0,
                      height: !isSmall && "250px",
                    }}
                  >
                    <Typography variant="caption" color={"white"}>
                      Or continue with
                    </Typography>

                    <Box
                      width={50}
                      height={50}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      onClick={handleGoogleLogin}
                      sx={{
                        background: "#011F2D",
                        cursor: "pointer",
                        "&:hover": {
                          border: 1,
                          borderColor: "#11B1F4",
                          background: "none",
                        },
                      }}
                    >
                      <img
                        style={{ width: "45px" }}
                        src="/Images/login-g.png"
                        alt="login"
                      />
                    </Box>

                    <Box
                      width={50}
                      height={50}
                      bgcolor={"#011F2D"}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        border: 1,
                        borderColor: "#11B1F4",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ width: "20px" }}
                        src="/Images/login-p.png"
                        alt="login"
                      />
                    </Box>
                    {/* <Box
                      width={50}
                      height={50}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      onClick={handleMetamaskLogin}
                      sx={{
                        background: "#011F2D",
                        cursor: "pointer",
                        "&:hover": {
                          border: 1,
                          borderColor: "#11B1F4",
                          background: "none",
                        },
                      }}
                    >
                      <img
                        style={{ width: "28px" }}
                        src="/Images/login-m.png"
                        alt="login"
                      />
                    </Box> */}
                  </Box>
                </Box>
              </Stack>

              {/* {numberState === "otp" ? ( */}

              {/* <Box position={"relative"} sx={{}}> */}
              <Box
                // sx={{ position: "absolute", left: 0 }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={isSmall && "start"}
              >
                <AvatarGroups />

                <Box display={"flex"} flexDirection={"column"} marginLeft={3}>
                  {" "}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      // fontWeight: 300,
                      fontFamily: "Montserrat",
                      marginBottom: 1,
                    }}
                  >
                    Don’t have an account?{" "}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => navigate("/signup")}
                      sx={{ color: "white" }}
                    >
                      Create Account
                    </Link>
                  </Typography>
                  <Typography variant="body2" color={"#517483"}>
                    100+ Users joined last Month
                  </Typography>
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
          </Box>

          <div
            id="recaptcha-container"
            stlye={{ position: "absolute", zIndex: "999" }}
          ></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OTPPage;
