import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "../../Styles/register.css";

const RegisterSidebar = ({ src }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const hideItem = useMediaQuery(theme.breakpoints.down("lg"));

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <Grid
      item
      xs={6}
      sx={{
        display: hideItem ? "none" : "flex",
        // bgcolor: "#0F103C",
        paddingY: 4,
        height: "100%",
        // borderRight: 3,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!imageLoaded && (
        <Box
          sx={{
            width: "80%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "#11B1F4" }} />
        </Box>
      )}
      <img
        src={src}
        alt="registrationPageImage"
        className="register-banner"
        onLoad={handleImageLoad}
        style={{
          width: "80%",
          height: "100%",
          borderRadius: "10px",
          display: imageLoaded ? "block" : "none",
        }}
      />
    </Grid>
  );
};

export default RegisterSidebar;

// -------------- -------------------- -----------------

// import React from "react";
// import { Grid, useMediaQuery, useTheme } from "@mui/material";
// import "../../Styles/register.css";

// const RegisterSidebar = () => {
//   const theme = useTheme();
//   const hideItem = useMediaQuery(theme.breakpoints.down("lg"));
//   return (
//     <Grid item xs={6} style={{ display: hideItem ? "none" : "block" }}>
//       <img
//         src="/Images/auth.jpg"
//         alt="registrationPageImage"
//         className="register-banner"
//       />
//     </Grid>
//   );
// };

// export default RegisterSidebar;
