import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DepositePaymentMethods from "./DepositPaymentMethods";
import { useTheme } from "@emotion/react";
import { useQuery } from "react-query";
import {
  createBlockchainTransaction,
  getBlockChianConfig,
} from "../../Services/Service";
import Loader from "../../Components/Loader/Loader";
import toast from "react-hot-toast";
import detectEthereumProvider from "@metamask/detect-provider";
import ConfirmDialogue from "../../Components/Modals/ConfirmDialogue";
import WithdrawPopup from "../../Components/Popups/WithdrawPopup";
import { AuthContext } from "../../context/AuthContext";
import ChampsWalletCard from "../../Components/wallet/ChampsWalletCard";

// const useStyles = makeStyles({
//   customNumberField: {
//     "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     '& input[type="number"]': {
//       "-moz-appearance": "textfield",
//     },
//   },
// });

const CARDS_OPTIONS = [
  { value: "ViSa1", label: "**** **** **** 1212 - Jimmy Holland" },
  { value: "ViSa2", label: "**** **** **** 2424 - Shawn Stokes" },
  { value: "MasterCard", label: "**** **** **** 4545 - Cole Armstrong" },
];

const CHAMPSWithdrawalPage = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [tokenAmount, setTokenAmount] = useState("");
  const [connectedWallet, setConnectedWallet] = useState(null);
  const [config, setConfig] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [transcId, setTranscId] = useState("");

  const userId = sessionStorage.getItem("userId");
  // const [usdcAmount, setUsdcAmount] = useState();
  const { user } = useContext(AuthContext);

  // useEffect(() => {
  //   setUsdcAmount(
  //     user.walletData?.find((item) => item._id?.paymentMethod === "USDC")
  //   );
  // }, [user]);

  const PAYMENT_OPTIONS = [
    {
      value: "crypto_wallet",
      title: "Crypto Wallet",
      description: `Balance : 0 CHAMPS`,
      icon: "/Images/crypto-wallet.png",
    },
  ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data: configData, isLoading: isConfigLoading } = useQuery(
    ["walletConfig"],
    async () => {
      try {
        await delay(800); // Simulate a delay of 2 seconds (2000 milliseconds)
        const result = await getBlockChianConfig();
        return result.data;
      } catch (error) {
        // seth2hLoading(false);
        console.log(error);
      }
    },
    {
      refetchOnMount: true,
      enabled: true,
    }
  );
  useEffect(() => {
    setConfig(configData);
  }, [configData]);

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const connectToWallet = async () => {
    try {
      const provider = await detectEthereumProvider();

      if (provider) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        const address = await accounts[0];
        console.log(address);
        setConnectedWallet(address);
        return accounts[0];
      }
    } catch (error) {
      console.log("Error connecting to wallet:", error);
    }
  };

  useEffect(() => {
    const connect = async () => await connectToWallet();
    connect();
  }, []);

  const handleWithdrawButtonClick = async () => {
    const notification = toast.loading("Withdraw your tokens...");
    try {
      // await connectToWallet();

      if (!connectedWallet) {
        toast.error("Wallet Not Found", {
          id: notification,
        });
        return;
      }

      if (config?.MiniToken >= user?.champsToken) {
        setDialogOpen(false);
        return toast.error("You don't have minimum amount", {
          id: notification,
        });
      }

      const dataOne = {
        walletAddress: connectedWallet,
        userId: userId,
        // token: tokenAmount,
        token: tokenAmount * 1e18,
        paymentMethod: "CHAMPS",
        transactionType: "Withdrawal",
        exchangedToken: tokenAmount,
        // exchangedToken: tokenAmount * 1e18,
        isTokenConverted: false,
      };

      const res = await createBlockchainTransaction(dataOne);

      console.log("res", res);
      setTranscId(res.data?._id);
      setDialogOpen(false);
      toast.success("Suceessfully applyed withdraw amount.", {
        id: notification,
      });
      setIsSuccessDialogOpen(true);
    } catch (e) {
      toast.error("Something went wrong!", {
        id: notification,
      });
    }
  };

  // const { data, isLoading } = useQuery(
  //   ["overviewWallet"],
  //   async () => {
  //     try {
  //       await delay(400);
  //       const walletOverView = await getWalletOverview({});
  //       console.log(data);
  //       return walletOverView?.data?.data;
  //     } catch (error) {
  //       // seth2hLoading(false);
  //     }
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  if (isConfigLoading) {
    return <Loader />;
  }
  return (
    <>
      <WithdrawPopup
        open={isSuccessDialogOpen}
        setOpen={setIsSuccessDialogOpen}
        amount={tokenAmount}
        id={transcId}
        symbol={"CHAMPS"}
      />
      <Grid container display={"flex"} spacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "#03364C",
              borderRadius: "27px",
              // padding: 4,
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              paddingBottom: 6,
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{ paddingX: 2 }}>
              Withdraw
            </Typography>

            <Box padding={2} width={{ sm: "100%", md: "80%" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 2,
                }}
              >
                Select Account
              </Typography>

              {/* <UsdcWalletCard data={user} disabled={true} /> */}
              <ChampsWalletCard disabled={true} />
            </Box>

            <Box padding={2}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Enter Amount
              </Typography>

              <Box
                display={"flex"}
                sx={{
                  background: "#011F2D",
                  borderRadius: "8px",
                  alignItems: "center",
                  padding: isSmallScreen ? 0 : 1,
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  // className={classes.customNumberField}
                  variant="outlined"
                  value={tokenAmount}
                  onChange={handleTokenAmountChange}
                  placeholder="Enter token amount"
                  InputProps={{ inputProps: { min: "0" } }}
                  inputProps={{
                    style: {
                      color: "white",
                      width: "300px",
                      fontWeight: 600,
                      fontSize: 20,
                    },
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#011F2D",
                    borderRadius: "8px",
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                <Typography marginRight={2}>CHAMPS</Typography>
              </Box>
            </Box>

            <Typography variant="caption" paddingX={2}>
              Select Receiving Account
            </Typography>
            <DepositePaymentMethods
              cardOptions={CARDS_OPTIONS}
              paymentOptions={PAYMENT_OPTIONS}
              isheaderShow={false}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "#03364C",
              borderRadius: "27px",
              padding: 2,
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              paddingBottom: 12,
              height: "100%",
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{}}>
              Withdrawal Summary
            </Typography>

            <Box
              sx={{
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                height: 65,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 500,
                  fontSize: isSmallScreen ? 14 : 22,
                }}
              >
                {tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0}
                <Typography
                  variant="caption"
                  marginLeft={1}
                  sx={{
                    fontSize: isSmallScreen ? 8 : 12,
                  }}
                >
                  CHAMPS
                </Typography>
              </Typography>
            </Box>

            <Box
              sx={{
                background: "#011F2D",
                borderRadius: "19px",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginTop: 3,
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Frequency
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  One Time
                </Typography>
              </Stack>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Ammount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  ${tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Transaction Charges
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  0%
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                  color={"white"}
                >
                  {tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0}
                </Typography>
              </Stack>
            </Box>

            <Button
              sx={{
                color: "white",
                width: "100%",
                paddingY: 2,
                borderRadius: "8px",
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                textTransform: "none",
                fontWeight: 600,
              }}
              onClick={() => handleDialogOpen()}
              disabled={!tokenAmount}
            >
              Confirm Withdraw
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ConfirmDialogue
        open={isDialogOpen}
        handleClose={handleDialogClose}
        title="Confirmation"
        content={`You want to withdraw ${Number(tokenAmount).toFixed(
          2
        )} CHAMPS? `}
        handleConfirm={handleWithdrawButtonClick}
      />
    </>
  );
};

export default CHAMPSWithdrawalPage;
