import { useTheme } from "@emotion/react";
import { Box, Card, Typography, alpha, useMediaQuery } from "@mui/material";
import React from "react";

const GameRules = ({ landing, data, loading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {" "}
      <Card
        sx={{
          width: isMobile ? "100%" : "300px",
          height: "100%",
          backgroundColor: alpha("#000000", 0.7),
          borderRadius: "25px",
          display: landing ? "none" : "block",
          paddingX: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          Rules
        </Typography>
        {loading ? (
          <>Loading...</>
        ) : (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            {data?.map((text, index) => (
              <Box display={"flex"} alignItems={"start"} key={index}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    minWidth: 10,
                    borderRadius: 100,
                    background: "#FBA616",
                    marginRight: 1,
                    marginTop: 1,
                  }}
                />
                <Typography>{text?.step}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Card>
    </>
  );
};

export default GameRules;
