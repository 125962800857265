import { Box, Stack, Typography } from "@mui/material";
import React from "react";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const QuickLinks = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // width: "360px",
        height: "220px",
        // background: "linear-gradient(180deg, #001D2A 23.5%, #002839 100%)",
        background: "#002839",
        marginTop: 4,
        paddingY: 3,
        paddingX: { xs: 1, md: 2, sm: 4 },
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
      }}
      gap={2}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" fontWeight={500} fontSize={20}>
          Quick Links
        </Typography>
      </Box>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        height={"100%"}
        gap={2}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          height={"100%"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            onClick={() => navigate("/mygames")}
            sx={{
              cursor: "pointer",
              color: "#E2E4E9",
              "&:hover": { color: "#10ADEF" },
            }}
          >
            <img src="/Images/link/ic12.png" style={{ width: "45px" }} alt="" />
            <Typography>My Games</Typography>
          </Box>

          <a
            href="mailto:support@cryptochamps.io"
            target="_self"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              sx={{
                cursor: "pointer",
                color: "#E2E4E9",
                "&:hover": { color: "#10ADEF" },
              }}
            >
              <img
                src="/Images/link/ic11.png"
                style={{ width: "45px" }}
                alt=""
              />
              <Typography>Support</Typography>
            </Box>
          </a>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          height={"100%"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            sx={{
              cursor: "pointer",
              color: "#E2E4E9",
              "&:hover": { color: "#10ADEF" },
            }}
            onClick={() => navigate("/wallet")}
          >
            <img src="/Images/link/ic13.png" style={{ width: "45px" }} alt="" />
            <Typography>Wallet</Typography>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            sx={{
              cursor: "pointer",
              color: "#E2E4E9",
              "&:hover": { color: "#10ADEF" },
            }}
            onClick={() => navigate("/howtoplay")}
          >
            <img src="/Images/link/ic14.png" style={{ width: "45px" }} alt="" />
            <Typography>How to Play ?</Typography>
          </Box>
        </Box>
      </Stack>

      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
          cursor: "pointer",
          borderRadius: "12px",
        }}
        // onClick={() => navigate("/mygames")}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <img src="/Images/ic.png" style={{ width: "40px" }} alt="" />
          <Typography>My Games</Typography>
        </Box>

        <Box display={"flex"} alignItems={"center"} gap={1}>
          <img src="/Images/ic.png" style={{ width: "40px" }} alt="" />
          <Typography>Wallet</Typography>
        </Box>
      </Box> */}
      {/* 
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
          cursor: "pointer",
          borderRadius: "12px",
        }}
        // onClick={() => navigate("/howtoplay")}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <img src="/Images/ic-1.png" style={{ width: "40px" }} alt="" />
          <Typography>Support</Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <img src="/Images/ic.png" style={{ width: "40px" }} alt="" />
          <Typography>My Games</Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default QuickLinks;
