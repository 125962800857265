import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { GrNotification } from "react-icons/gr";

import {
  Badge,
  Button,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  keyframes,
  styled,
  tooltipClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import { GoHomeFill } from "react-icons/go";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import { PiGameControllerBold } from "react-icons/pi";
import { BiGame } from "react-icons/bi";
import { BiWallet } from "react-icons/bi";
import { PiSignOutBold } from "react-icons/pi";
import { RiNotification2Line } from "react-icons/ri";
import { signOut } from "firebase/auth";
import { auth } from "../../Utils/firebaseConfig";
import { useQuery, useQueryClient } from "react-query";
import HeaderCryptoCard from "../common/HeaderCryptoCard";
import LogoutPopup from "../Popups/LogoutPopup";
import LogoLoader from "../Loader/LogoLoader";
import SettingsPopover from "../Popups/SettingsPopup";
import Searchbar from "../Popups/Searchbar";
import {
  getAllInvitaions,
  getUserById,
  requestToken,
  updateUserBalance,
} from "../../Services/Service";
import { AuthContext } from "../../context/AuthContext";
import { NotificationContext } from "../../context/NotificationContext";
import { GoInfo } from "react-icons/go";
import { io } from "socket.io-client";
import { useJoinedGameCount } from "../../constants/useJoinedGameCount";
import InvitationConfirmPopup from "../Popups/InvitationConfirmPopup";
import { makeMagic } from "../../lib/magic";
import { ethers } from "ethers";
import NewAbi from "../../Contract/NewAbi.json";
import USDCToken from "../../Contract/USDC.json";
import { Chat, ChatBubble } from "@mui/icons-material";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import LiveChatDrawer from "../liveChat/drawer";
import { DrawerContext } from "../../context/DrawerContext";
import CryptoTooltip from "../Tooltip/LightToolTip";
import { commonData } from "../../constants/common";
import { userBalanceUpdate } from "../../lib/blockchain_common_method";
import RefreshIcon from "@mui/icons-material/Refresh";
import { champsCovertDecimals } from "../../Utils/token";

const drawerWidth = 240;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledNotificationBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -1,
    top: -9,
    backgroundColor: "#FF3030",
    border: `2px solid #03364C`,
    padding: "0 4px",
  },
}));

const StyledMyGamesBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -20,
    top: 16,
    backgroundColor: "#FF3030",
    color: "white",
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: "1 6px",
  },
}));
// Define the pulse animation
const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(17, 177, 244, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(17, 177, 244, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(17, 177, 244, 0);
  }
`;

// Define keyframe for rotation animation
const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Create a styled component for the animated box
const AnimatedBox = styled(Box)`
  animation: ${pulseAnimation} 2s infinite;
`;

const MainLayout = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const [selectedCryptos, setSelectedCryptos] = useState([]);

  const { setUser, setUserBalance, userBalance } = useContext(AuthContext);

  const userId = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("token");

  const { unReadCount } = useContext(NotificationContext);

  const [invitationReqests, setInvitationRequests] = useState([]);

  const ARBITRUM_MAINNET_JSON_RPC_URL = "https://arbitrum.llamarpc.com";
  // const CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET =
  //   "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
  const UNISWAP_CONTRACT_ADDRESS = "0x5974729a8df43276dd52d4d0333d7198fe63455d";
  const WETH_ARBITRUM_MAINNET = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  // const USDCe_ARBITRUM_MAINNET = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
  const USDC_ARBITRUM_MAINNET = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";

  const { openLiveChat, handleLiveChatOpen, isHomePage } =
    useContext(DrawerContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    console.log(userId, "useUserID");
    if (!userId && !token) {
      return navigate("/");
    }
  }, [userId, navigate, token]);

  //get icons according to the menu name

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const [isBalanceProcessing, setIsBalanceProcessing] = useState(false);
  const [requestSended, setRequestSended] = useState(false);

  /*
  useEffect(() => {
    // Set up an interval to print data every second
    const interval = setInterval(async () => {
      // if (!isBalanceProcessing) {
      //   console.log("Balance update process started");
      //   await blockChainGetBalance();
      //   console.log("Balance update process ended");
      // } else {
      //   console.log("Balance update already in process");
      // }
    }, 1000); // 1000ms = 1 second

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array to ensure the effect runs once on mount
  */

  const checkBalance = async () => {
    setIsBalanceProcessing(true);
    try {
      const usdcBalance = await userBalanceUpdate();
      setUserBalance(usdcBalance);
      setIsBalanceProcessing(false);
    } catch (error) {
      setIsBalanceProcessing(false);
      console.error(`Error from check balance${error}`);
    }
    setIsBalanceProcessing(false);
  };

  const handleLogout = () => {
    signOut(auth).then(async () => {
      await makeMagic.user.logout();
      const data = await makeMagic.user.isLoggedIn();
      console.log("IsLogged In => ", data);
      sessionStorage.clear();
      queryClient.clear();

      navigate("/");
    });
  };

  const convertWethToUSDC = async (amount) => {
    try {
      const path = [WETH_ARBITRUM_MAINNET, USDC_ARBITRUM_MAINNET];

      const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API
      );

      const uniswapRouter = new ethers.Contract(
        UNISWAP_CONTRACT_ADDRESS,
        NewAbi,
        provider
      );

      const tokenAmountt = ethers.utils.parseUnits(
        amount ? `${amount}` : "0",
        18
      );
      await uniswapRouter.functions
        .getAmountsOut(tokenAmountt, path)
        .then(async (amounts) => {
          const [wethIn, usdcOut] = amounts[0];
          console.log(wethIn);
          console.log(
            "Amount of USDCe from WETH: ",
            ethers.utils.formatUnits(usdcOut, 6)
          );
          console.log(
            "Amount of USDCe from WETH: ",
            ethers.utils.formatUnits(wethIn, 18)
          );

          setUserBalance(ethers.utils.formatUnits(usdcOut, 6));

          // await updateUserBalance({
          //   userId: userId,
          //   token: parseFloat(ethers.utils.formatUnits(usdcOut, 6)),

          // });
        })
        .finally(() => {})
        .catch((error) => console.log("CONVERT WEH TO USDC1", error));
    } catch (error) {
      console.log("CONVERT WEH TO USDC2", error);
    }
  };

  const blockChainGetBalance = async () => {
    let magicUSDCBalance = 0;

    setIsBalanceProcessing(true);

    try {
      const provider = new ethers.providers.Web3Provider(makeMagic.rpcProvider);

      // ⭐️ After user is successfully authenticated
      const signer = await provider.getSigner();
      // Get user's Ethereum public address
      const address = await signer.getAddress();
      console.log("USER-ADDRESS ", `${address}`);
      const maticBalance = await signer.getBalance(); // Balance is in wei

      // Get user's balance in ether
      const formattedBalance = ethers.utils.formatEther(maticBalance);

      console.log("MATIC BALANCE => ", formattedBalance);

      const usdcContractAddress = commonData.USDC_CONTRACT_ADDRESS; //paymentToken mainnet

      const usdcContract = new ethers.Contract(
        usdcContractAddress,
        USDCToken,
        provider
      );

      await usdcContract
        .balanceOf(address)
        .then((balance) => {
          magicUSDCBalance = ethers.utils.formatUnits(
            balance.toString(),
            commonData.usdcDecimals
          );
          console.log("USDC-BALANCE", magicUSDCBalance.toString());
        })
        .catch((error) => {
          console.error("Error fetching blockChainGetBalance:", error);
          magicUSDCBalance = 0;
        });

      await updateUserBalance({
        userId: userId,
        token: parseFloat(magicUSDCBalance?.toString()),
        maticToken: parseFloat(formattedBalance?.toString()),
      });

      if (
        parseFloat(magicUSDCBalance?.toString()) > 0.1
        // &&
        // requestSended === false
      ) {
        const request = await requestToken({
          userId: userId,
          currentMatic: parseFloat(formattedBalance?.toString()),
          currentUsdc: parseFloat(magicUSDCBalance?.toString()),
          userFeedType: "user",
          walletAddress: address,
        });
        // if (request?.success === true) {
        //   // console.log(request.data);
        //   setRequestSended(true);
        // }
      }
      // else {
      //   console.log("MATIC REQUEST SENDED", requestSended);
      // }

      setIsBalanceProcessing(false);
    } catch (error) {
      console.error(
        `ERROR FROM blockchain getBalance METHOD ${error.toString()}`
      );
      setIsBalanceProcessing(false);
    }

    setUserBalance(magicUSDCBalance);
    setIsBalanceProcessing(false);
  };

  const {
    data,
    isLoading,
    refetch: refetchUser,
  } = useQuery(
    ["userToken", userId],
    async () => {
      try {
        // await delay(400);
        //blockchain
        // await blockChainGetBalance();
        await checkBalance();
        const userToken = await getUserById(userId);
        sessionStorage.setItem("userToken", userToken.data?.data?.token);
        setUser(userToken.data?.data);
        return userToken.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      cacheTime: 0,
      enabled: true,
    }
  );

  const getIcons = (index) => {
    switch (index) {
      case 0:
        return <GoHomeFill size={22} />;
      case 1:
        return <FaArrowTrendUp size={22} />;
      case 2:
        return <FaArrowTrendDown size={22} />;
      case 3:
        return <BiGame size={22} />;
      case 4:
        return <PiGameControllerBold size={22} />;
      case 5:
        return <BiWallet size={22} />;
      case 6:
        return (
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#E98816",
              },
            }}
            variant="dot"
            invisible={unReadCount > 0 ? false : true}
          >
            <RiNotification2Line size={22} />
          </Badge>
        );
      default:
        return null;
    }
  };

  const zIndex = 1;

  useEffect(() => {
    const socket = io("wss://api.cryptochamps.io");
    // const socket = io("wss://dev-staging-api.cryptochamps.io");
    // const socket = io("http://localhost:3005");
    // Emit the JSON body
    const requestData = {
      user: userId,
      page: 1,
      limit: 10,
    };

    const fetchDataAndUpdate = (socket, requestData) => {
      socket.emit("USER_SEARCHED_CRYPTOS_GETALL", requestData);

      socket.on(userId, (ackData) => {
        // console.log("Received live data update:", ackData);
        setSelectedCryptos(ackData?.cryptos);
      });
    };
    // Initial fetch of data
    fetchDataAndUpdate(socket, requestData);
    // Fetch data every 2 seconds
    const intervalId = setInterval(() => {
      fetchDataAndUpdate(socket, requestData);
    }, 2000);

    // Clean up the socket connection
    return () => {
      clearInterval(intervalId);
      socket.disconnect();
    };
  }, [userId]);

  const { data: joinedGameCount, refetch: refetchJoinedGameCount } =
    useJoinedGameCount();

  useEffect(() => {
    refetchUser();
    refetchJoinedGameCount();
  }, [refetchUser, refetchJoinedGameCount]);

  useEffect(() => {
    const fetchAllInvitations = async () => {
      const response = await getAllInvitaions(userId);
      console.log(response.data, "getAllInvitaions");
      setInvitationRequests(response.data);
    };
    fetchAllInvitations();
  }, [userId]);

  useEffect(() => {
    const socket = io("wss://api.cryptochamps.io");
    // const socket = io("http://localhost:3005");
    // const socket = io("wss://dev-staging-api.cryptochamps.io");
    // Listen for real-time updates using Socket.IO
    socket.on(`NEW_INVITATION_${userId}`, (newInvitation) => {
      console.log("NEW_INVITATION", newInvitation);
      setInvitationRequests((prevInv) => [...prevInv, newInvitation]);
    });

    return () => {
      // Clean up the Socket.IO connection when the component unmounts
      console.log("unregistering event");
      socket.off("NEW_INVITATION");
      socket.disconnect();
    };
  }, [userId]);

  return isLoading ? (
    <>
      <LogoLoader />
    </>
  ) : (
    <>
      {invitationReqests.length > 0 &&
        invitationReqests.map((req) => (
          <InvitationConfirmPopup
            invitationId={req._id}
            recieveUserName={req.sendUserName}
            recieveProfilePic={req?.sendUserProfilePic}
            gameInstanceId={req.gameInstance}
          />
        ))}

      <Box display={"flex"}>
        <LogoutPopup
          open={isDialogOpen}
          setOpen={setDialogOpen}
          handleSubmit={handleLogout}
        />
        <CssBaseline />
        <AppBar
          position="fixed"
          component="nav"
          sx={{
            background: "#002839",
            height: "100px",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" }, marginTop: 2 }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "100%", sm: "50%", md: "48%", lg: "60%" },
                marginTop: 1,
                marginLeft: !isMediumScreen ? 30 : 0,
                alignItems: "center",
              }}
            >
              {!isMediumScreen && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    // position: "relative",
                    alignItems: "center",
                    marginTop: 1,
                    paddingX: 1,
                    paddingY: 0.8,
                    backgroundColor: "#033D55",
                    borderRadius: "36px",
                    width: "100%",
                    justifyContent: "space-between",
                    zIndex: 1,
                    // width: isLargeScreen ? "90%" : "800px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      marginLeft: 1,
                      minWidth: 100,
                    }}
                    noWrap
                  >
                    Price Updates:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      overflowX: "scroll",
                      gap: 1,
                      // marginY: 1,
                      "&::-webkit-scrollbar": { display: "none" },
                      width: "100%",
                    }}
                  >
                    {selectedCryptos?.map((data) => (
                      <HeaderCryptoCard
                        data={data}
                        selectedCryptos={selectedCryptos}
                        setSelectedCryptos={setSelectedCryptos}
                      />
                    ))}
                  </Box>

                  <Searchbar
                    selectedCryptos={selectedCryptos}
                    setSelectedCryptos={setSelectedCryptos}
                    zIndex={zIndex}
                  />
                </Box>
              )}
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              width={isSmallScreen ? "80%" : "50%"}
              gap={0.8}
              marginTop={2.5}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(to right, #11B1F4 -30%, #085A7D  100%, #085A7D 100%)",
                  paddingY: 1.5,
                  paddingX: 3,
                  // minWidth: "110px",
                  borderRadius: "41px",
                  color: "white",
                  fontSize: 12,
                  textTransform: "none",
                }}
                onClick={() => navigate("/deposit/usdc")}
              >
                Deposit
              </Button>
              <Divider
                orientation="vertical"
                sx={{
                  backgroundColor: "#6E7A91",
                  borderRightWidth: 1,
                  height: 50,
                  display: { xs: "none", sm: "block" },
                }}
              />
              <Box
                width={45}
                height={45}
                sx={{
                  background: "#064865",
                  borderRadius: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  // position: "relative",
                  "&:hover": {
                    background: "#11B1F4",
                  },
                }}
                onClick={() => navigate("/notifications")}
              >
                {" "}
                <StyledNotificationBadge
                  // color="secondary"
                  badgeContent={unReadCount}
                  sx={{
                    zIndex: zIndex - 1,
                  }}
                  invisible={unReadCount > 0 ? false : true}
                >
                  <GrNotification size={22} className="gricon" />
                </StyledNotificationBadge>
              </Box>
              <SettingsPopover handleOpenDialog={handleOpenDialog} />

              <CryptoTooltip
                disableFocusListener
                disableTouchListener
                title={"Live chat"}
                placement="top"
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                        {
                          marginBottom: "5px",
                        },
                    },
                  },
                }}
              >
                <AnimatedBox
                  width={45}
                  height={45}
                  sx={{
                    background: "#064865",
                    borderRadius: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    // position: "relative",
                    "&:hover": {
                      background: "#11B1F4",
                    },
                  }}
                  onClick={handleLiveChatOpen}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={openLiveChat ?? false}
                  >
                    <IoChatbubbleEllipsesOutline size={24} color="#D0D4DC" />
                  </Badge>
                </AnimatedBox>
              </CryptoTooltip>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* Mobile Drawer */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: "#002839",
              },
            }}
          >
            <Toolbar sx={{ marginBottom: 2 }}>
              <img
                style={{
                  width: "216px",
                  marginTop: "25px",
                  height: "75px",
                  cursor: "pointer",
                }}
                src="/Images/chmaps-logo.png"
                alt=""
                onClick={() => {
                  navigate("/home");
                  handleDrawerToggle();
                }}
              />
            </Toolbar>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{
                  marginY: 1,
                  paddingX: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  onClick={() => {
                    navigate("/wallet/edit-profile");
                    handleDrawerToggle();
                  }}
                  sx={{
                    cursor: "pointer",
                    padding: 1,
                    paddingY: 2,
                    borderRadius: "8px",
                    "&:hover": {
                      background: "#001D2A",
                    },
                  }}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      alt="profile pic"
                      sx={{
                        width: 60,
                        height: 60,
                      }}
                      src={data?.profilePic}
                    />
                  </StyledBadge>

                  <Box>
                    <Box display={"flex"} alignItems={"center"} gap={0.5}>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={16}
                      >
                        {data?.username}
                      </Typography>
                      {/* <Avatar
                        src="/Images/badge2.png"
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      /> */}
                    </Box>
                    {/* <Typography
                      variant="caption"
                      fontWeight={500}
                      fontSize={14}
                      color={"#FFD54B"}
                    >
                      Level 2
                    </Typography> */}
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  padding: 1,
                  marginBottom: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "row", // Use 'row' if you want the icon next to the text
                    // padding: 2, // Optional padding
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#E2E4E9",
                      marginLeft: 1,
                      // marginRight: 1,
                    }}
                    fontSize={16}
                    fontWeight={500}
                  >
                    Current Balance
                  </Typography>

                  <IconButton
                    sx={{
                      animation: isBalanceProcessing
                        ? `${rotateAnimation} 1s ease infinite`
                        : "none", // Apply animation only when refreshing
                    }}
                    onClick={async () => {
                      // Add your refresh action here
                      // console.log("Refresh button clicked");
                      await checkBalance();
                    }}
                    // sx={{
                    //   padding: 0, // Optional to reduce padding around the icon
                    // }}
                  >
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </Box>

                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "#022737",
                    borderRadius: "11px",
                    padding: 1,
                    cursor: "pointer",
                    "&:hover": {
                      background: "#001D2A",
                    },
                  }}
                  onClick={() => {
                    navigate("/wallet");
                    handleDrawerToggle();
                  }}
                >
                  {/* <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#E2E4E9",
                        marginLeft: 1,
                      }}
                      fontSize={16}
                      fontWeight={500}
                    >
                      Current Balance
                    </Typography>

                    <RefreshIcon />
                  </Box> */}

                  <Box
                    display={"flex"}
                    marginTop={1}
                    gap={1}
                    alignItems={"center"}
                    marginLeft={1}
                  >
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src="/Images/usdc-blue-logo.png"
                      alt=""
                    />
                    <Typography
                      variant="caption"
                      fontSize={20}
                      fontWeight={600}
                    >
                      {
                        // data?.token.toFixed(2)
                        userBalance
                          ? // parseFloat(userBalance)?.toFixed(
                            //     commonData?.tokenDecimal
                            //   )
                            champsCovertDecimals(
                              userBalance,
                              commonData?.tokenDecimal
                            )
                          : "0"
                      }
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "#E2E4E9" }}
                      fontWeight={300}
                    >
                      USDC
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <List>
                {[
                  "Home",
                  "Biggest Winner",
                  "Biggest Loser",
                  "Crypto Parlay",
                  "My Games",
                  "Wallet",
                  "Notifications",
                  // "Sign Out",
                ].map((text, index) => (
                  <ListItem
                    key={text}
                    disablePadding
                    components={NavLink}
                    sx={{ paddingX: 1 }}
                  >
                    {text === "Biggest Winner" ? (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        onClick={handleDrawerToggle}
                        className="nav-link-bw"
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "17px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                          }}
                        >
                          {getIcons(1)}
                          <ListItemText sx={{ marginLeft: 2 }} primary={text} />

                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    ) : text === "Biggest Loser" ? (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        onClick={handleDrawerToggle}
                        className="nav-link-bl"
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "17px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                          }}
                        >
                          {getIcons(2)}
                          <ListItemText sx={{ marginLeft: 2 }} primary={text} />

                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    ) : text === "My Games" ? (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        style={({ isActive }) => ({
                          background: isActive
                            ? "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                            : "none",
                          textDecoration: "none",
                          color: isActive ? "#FFFFFF" : "#AFB5C0",
                          width: "100%",
                          borderRadius: "14px",
                          fontWeight: isActive ? 800 : 400,

                          // padding: "5px 5px",
                        })}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "17px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {getIcons(4)}

                            <StyledMyGamesBadge
                              // color="secondary"
                              overlap="circular"
                              badgeContent={joinedGameCount}
                            >
                              <ListItemText
                                sx={{ marginLeft: 2 }}
                                primary={text}
                              />
                            </StyledMyGamesBadge>
                          </div>
                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        onClick={handleDrawerToggle}
                        style={({ isActive }) => ({
                          background: isActive
                            ? "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                            : "none",
                          textDecoration: "none",
                          color: isActive ? "#FFFFFF" : "#AFB5C0",
                          width: "100%",
                          borderRadius: "14px",
                          fontWeight: isActive ? 800 : 400,

                          // padding: "5px 5px",
                        })}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "17px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                          }}
                        >
                          {getIcons(index)}
                          <ListItemText sx={{ marginLeft: 2 }} primary={text} />

                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    )}
                  </ListItem>
                ))}
              </List>
              {/* <Divider /> */}
              <List>
                <ListItem sx={{ paddingX: 1 }}>
                  <ListItemButton
                    sx={{
                      width: "100%",
                      borderRadius: "17px",
                      paddingY: 1.8,
                      // marginBottom: 1,
                    }}
                    onClick={handleOpenDialog}
                  >
                    <PiSignOutBold size={22} color="#AFB5C0" />
                    <ListItemText
                      sx={{ marginLeft: 2, color: "#AFB5C0" }}
                      primary={"Sign Out"}
                    />
                    <IoIosArrowForward fontSize={18} color="#AFB5C0" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>

          {/* Permanent Drawer */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                border: "none",
                background: "#002839",
              },
            }}
          >
            <Toolbar sx={{ marginBottom: 3.8 }}>
              <img
                style={{
                  width: "216px",
                  marginTop: "25px",
                  height: "75px",
                  cursor: "pointer",
                }}
                src="/Images/chmaps-logo.png"
                alt=""
                onClick={() => navigate("/home")}
              />
            </Toolbar>

            <Box sx={{ overflowY: "auto" }}>
              <Box
                sx={{
                  marginY: 1,
                  paddingX: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  onClick={() => navigate("/wallet/edit-profile")}
                  sx={{
                    cursor: "pointer",
                    padding: 1,
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#001D2A",
                    },
                  }}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="dot"
                  >
                    {data.profilePic === "undefined" ? (
                      <Avatar
                        sx={{
                          bgcolor: "#E0F64B",
                          fontWeight: 600,
                          width: 60,
                          height: 60,
                        }}
                      >
                        {data?.username?.toUpperCase().split("").slice(0, 1)}
                      </Avatar>
                    ) : (
                      <Avatar
                        alt="profile pic"
                        sx={{
                          width: 60,
                          height: 60,
                        }}
                        src={data?.profilePic}
                      />
                    )}
                  </StyledBadge>

                  <Box>
                    <Box display={"flex"} alignItems={"center"} gap={0.5}>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={16}
                      >
                        {data?.username}
                      </Typography>

                      {/* <Avatar
                        src="/Images/badge2.png"
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      /> */}
                    </Box>
                    {/* <Typography
                      variant="caption"
                      fontWeight={500}
                      fontSize={14}
                      color={"#FFD54B"}
                    >
                      Level 2
                    </Typography> */}
                  </Box>
                </Stack>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  padding: 1,
                  marginBottom: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "row", // Use 'row' if you want the icon next to the text
                    // padding: 2, // Optional padding
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#E2E4E9",
                      marginLeft: 1,
                      // marginRight: 1,
                    }}
                    fontSize={16}
                    fontWeight={500}
                  >
                    Current Balance
                  </Typography>

                  {/* Refresh button */}
                  <IconButton
                    sx={{
                      animation: isBalanceProcessing
                        ? `${rotateAnimation} 1s ease infinite`
                        : "none", // Apply animation only when refreshing
                    }}
                    onClick={async () => {
                      // Add your refresh action here
                      // console.log("Refresh button clicked");
                      await checkBalance();
                    }}
                    // sx={{
                    //   padding: 0, // Optional to reduce padding around the icon
                    // }}
                  >
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "#022737",
                    borderRadius: "10px",
                    padding: 1,
                    cursor: "pointer",
                    "&:hover": {
                      background: "#001D2A",
                    },
                  }}
                  onClick={() => navigate("/wallet")}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      flexDirection: "row", // Use 'row' if you want the icon next to the text
                      // padding: 2, // Optional padding
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#E2E4E9",
                        marginLeft: 1,
                        marginRight: 1,
                      }}
                      fontSize={16}
                      fontWeight={500}
                    >
                      Current Balance
                    </Typography>

                 
                    <IconButton
                      onClick={() => {
                        // Add your refresh action here
                        console.log("Refresh button clicked");
                      }}
                      sx={{
                        padding: 0, // Optional to reduce padding around the icon
                      }}
                    >
                      <RefreshIcon fontSize="small" />
                    </IconButton>
                  </Box> */}

                  <Box
                    display={"flex"}
                    marginTop={1}
                    gap={1}
                    alignItems={"center"}
                    marginLeft={1}
                  >
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src="/Images/usdc-blue-logo.png"
                      alt=""
                    />
                    <Typography
                      variant="caption"
                      fontSize={20}
                      fontWeight={600}
                    >
                      {
                        // data?.token.toFixed(2)
                        userBalance
                          ? champsCovertDecimals(
                              userBalance,
                              commonData?.tokenDecimal
                            )
                          : "0"
                      }
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "#E2E4E9" }}
                      fontWeight={300}
                    >
                      USDC
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <List>
                {[
                  "Home",
                  "Biggest Winner",
                  "Biggest Loser",
                  "Crypto Parlay",
                  "My Games",
                  "Wallet",
                  "Notifications",
                  // "Sign Out",
                ].map((text, index) => (
                  <ListItem
                    key={text}
                    disablePadding
                    components={NavLink}
                    sx={{ paddingX: 1 }}
                  >
                    {text === "Biggest Winner" ? (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        className="nav-link-bw"
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "17px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                          }}
                        >
                          {getIcons(1)}

                          <ListItemText
                            sx={{
                              marginLeft: 2,
                              textShadow: "6px 3px 15px #39B149",
                              color: "white",
                            }}
                            primary={text}
                          />

                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    ) : text === "Biggest Loser" ? (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        className="nav-link-bl"
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "17px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                          }}
                        >
                          {getIcons(2)}
                          <ListItemText
                            sx={{
                              marginLeft: 2,
                              textShadow: "6px 3px 10px #FF1111",
                              color: "white",
                              // border: "0.5px solid #B03737",

                              // boxShadow: "0px 0px 25px 0px #BD3B3B",
                            }}
                            primary={text}
                          />
                          {/* <Typography
                            sx={{
                              marginLeft: 2,
                              textShadow: "6px 3px 10px #FF1111",
                              color: "white",
                              // border: "0.5px solid #B03737",

                              // boxShadow: "0px 0px 25px 0px #BD3B3B",
                            }}
                          >
                            {text}
                          </Typography> */}

                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    ) : text === "My Games" ? (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        style={({ isActive }) => ({
                          background: isActive
                            ? // ? "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                              "#001D2A"
                            : "none",
                          textDecoration: "none",
                          color: isActive ? "#FFFFFF" : "#AFB5C0",
                          width: "100%",
                          borderRadius: "10px",
                          fontWeight: isActive ? 800 : 400,

                          // padding: "5px 5px",
                        })}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {getIcons(4)}

                            <StyledMyGamesBadge
                              // color="secondary"
                              overlap="circular"
                              badgeContent={joinedGameCount}
                            >
                              <ListItemText
                                sx={{ marginLeft: 2 }}
                                primary={text}
                              />
                            </StyledMyGamesBadge>
                          </div>
                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
                        style={({ isActive }) => ({
                          background: isActive ? "#001D2A" : "none",
                          textDecoration: "none",
                          color: isActive ? "#FFFFFF" : "#AFB5C0",
                          width: "100%",
                          borderRadius: "10px",
                          fontWeight: isActive ? 800 : 400,

                          // padding: "5px 5px",
                        })}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            borderRadius: "10px",
                            paddingY: 1.8,
                            // marginBottom: 1,
                          }}
                        >
                          {getIcons(index)}
                          <ListItemText sx={{ marginLeft: 2 }} primary={text} />

                          <IoIosArrowForward fontSize={18} />
                        </ListItemButton>
                      </NavLink>
                    )}
                  </ListItem>
                ))}
              </List>
              {/* <Divider /> */}
              <List>
                <ListItem sx={{ paddingX: 1 }}>
                  <ListItemButton
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      paddingY: 1.8,
                      // marginBottom: 1,
                    }}
                    onClick={handleOpenDialog}
                  >
                    <PiSignOutBold size={22} color="#AFB5C0" />
                    <ListItemText
                      sx={{ marginLeft: 2, color: "#AFB5C0" }}
                      primary={"Sign Out"}
                    />
                    <IoIosArrowForward fontSize={18} color="#AFB5C0" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            paddingTop: 7,
            paddingBottom: 5,
            paddingX: isSmallScreen ? 1 : 3,
            // position: "relative",
            background: "#001d2a",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
        {/* Live Telegram Chat  */}
        <>
          {openLiveChat === true && isHomePage === false ? (
            <LiveChatDrawer
              open={openLiveChat}
              handleLiveChatOpen={handleLiveChatOpen}
              openFloating={true}
            />
          ) : (
            <></>
          )}
        </>
      </Box>

      {/* Live Telegram Chat  */}
      {/* <>
        {openLiveChat === true ? (
          <LiveChatDrawer
            open={openLiveChat}
            handleLiveChatOpen={handleLiveChatOpen}
            openFloating={true}
          />
        ) : (
          <></>
        )}
      </> */}
    </>
  );
};

export default MainLayout;
