import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
// import CryptoUpdateCard from "./CryptoUpdateCard";
import { getCryptoUpdate } from "../../../Services/Service";
import { useQuery } from "react-query";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const CryptoUpdate = () => {
  const [lowerCrypto, setLowerCrypto] = useState([]);
  const [topCrypto, setTopCrypto] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const fetchCyptoUpdates = async () => {
  //     setIsLoading(true);
  //     const response = await getCryptoUpdate();
  //     setLowerCrypto(response.data?.data?.lowCryptos);
  //     setTopCrypto(response.data?.data?.topCryptos);
  //   };
  //   fetchCyptoUpdates();
  //   setIsLoading(false);
  // }, []);

  console.log(lowerCrypto);

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchCyptoUpdates = async () => {
    try {
      // await delay(200);
      const response = await getCryptoUpdate();
      setLowerCrypto(response.data?.data?.lowCryptos);
      setTopCrypto(response.data?.data?.topCryptos);
      return response.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const { isLoading } = useQuery({
    queryKey: ["cryptoUpdates"],
    queryFn: fetchCyptoUpdates,
    refetchOnWindowFocus: false,
    cacheTime: 5 * 1000,
  });

  console.log("====================================");
  console.log(topCrypto);
  console.log("====================================");

  return (
    <Box
      sx={{
        // width: "360px",
        // background: "linear-gradient(180deg, #001D2A 23.5%, #002839 100%)",
        background: "#002839",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          marginTop: { xs: 3, sm: 3, md: 3 },
          px: 3,
        }}
      >
        <Typography fontSize={22} fontWeight={700} lineHeight={1.5}>
          Crypto Updates
        </Typography>

        {/* <MoreVertIcon
      sx={{
        color: "#AFB4C0",
      }}
    /> */}
      </Box>
      <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} gap={2}>
        {isLoading || topCrypto.length === 0 || lowerCrypto.length === 0 ? (
          <>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                // background: "#03364C",
                marginTop: 2,
                padding: 3,
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack spacing={1}>
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="rounded" height={45} />
                <Skeleton variant="rounded" height={45} />
              </Stack>
            </Box>
            {/* <Box
              sx={{
                width: "100%",
                height: "100%",
                background: "#03364C",
                marginTop: 2,
                padding: 3,
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="rounded" height={45} />
                <Skeleton variant="rounded" height={45} />
              </Stack>
            </Box> */}
          </>
        ) : (
          <>
            {/* <CryptoUpdateCard data={topCrypto} /> */}
            {/* <CryptoUpdateCard data={lowerCrypto} isLower={true} /> */}

            <Box
              sx={{
                width: "100%",
                height: "100%",
                // background: "#03364C",
                padding: 3,
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  src={topCrypto?.image}
                  alt=""
                />
                <Box>
                  <Typography fontWeight={600}>{topCrypto?.name}</Typography>
                  <Typography>
                    {topCrypto?.price?.toFixed(4)}{" "}
                    {topCrypto?.symbol?.toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // padding: 1,
                    backgroundColor: "#D5F5DE",
                    borderRadius: "5px",
                    color: "#00BB6C",
                    maxHeight: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pr: 1,
                    py: 1,
                    marginLeft: { md: 3, xs: 1 },
                    fontSize: 12,
                  }}
                >
                  <ArrowDropUpIcon
                    sx={{
                      color: "#00BB6C",
                    }}
                  />
                  {topCrypto?.price_change_percentage_24h?.toFixed(2)}%
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  src={lowerCrypto?.image}
                  alt=""
                />
                <Box>
                  <Typography fontWeight={600}>{lowerCrypto?.name}</Typography>
                  <Typography>
                    {lowerCrypto?.price?.toFixed(4)}{" "}
                    {lowerCrypto?.symbol?.toUpperCase()}
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    // padding: 1,
                    backgroundColor: "#D5F5DE",
                    borderRadius: "5px",
                    color: "#00BB6C",
                    maxHeight: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pr: 1,
                    py: 1,
                    marginLeft: { md: 3, xs: 1 },
                    fontSize: 12,
                  }}
                >
                  <ArrowDropUpIcon
                    sx={{
                      color: "#00BB6C",
                    }}
                  />
                  {lowerCrypto?.price_change_percentage_24h?.toFixed(2)}%
                </Box> */}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CryptoUpdate;
