import React, { useContext, useEffect } from "react";
import { markAsRead } from "../../Services/Service";

import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import Loader from "../../Components/Loader/Loader";
import { useTheme } from "@emotion/react";
import { NotificationContext } from "../../context/NotificationContext";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
// import InvitationConfirmPopup from "../../Components/Popups/InvitationConfirmPopup";

const Notification = () => {
  const userId = sessionStorage.getItem("userId");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const queryClient = useQueryClient();
  // const [isInvitationPopupOpen, setIsInvitationPopupOpen] =
  //   React.useState(false);
  // const [invitationId, setInvitationId] = useState("");
  // const [gameInstanceId, setGameInstanceId] = useState("");

  const {
    unReadCount,
    isGrouped,
    setPage,
    refetch,
    data,
    isLoading,
    page,
    limit,
    count,
  } = useContext(NotificationContext);

  console.log(isGrouped, "IAS");

  useEffect(() => {
    const refetchData = async () => {
      await refetch();
    };
    refetchData();
  }, [refetch]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  console.log(unReadCount, "unReadCount");

  const updateMarkAsRead = async (id) => {
    // const notify = toast.loading("Loading...");
    try {
      const response = await markAsRead(id);
      if (response.data.data === "Notification marked as read successfully") {
        await refetch();
        toast.success("Notification marked as read successfully");
        // {
        //   id: notify,
        // });
      }
    } catch (error) {
      console.log(error);
      toast.error("Whoops something went wrong!");
    }
  };

  const mutation = useMutation(updateMarkAsRead, {
    onSuccess: () => {
      // Invalidate and refetch the posts query after a successful post
      queryClient.invalidateQueries("notificationData");
    },
  });

  const handleMarkAllNotification = async () => {
    // const notify = toast.loading("Loading...");
    // try {
    //   const response = await markAsRead(userId);
    //   if (response) {
    //     await refetch();
    //     return toast.success("All conversations marked as read", {
    //       id: notify,
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    //   toast.error("Whoops something went wrong!", {
    //     id: notify,
    //   });
    // }
    // Call the mutation function with the form data
    mutation.mutate(userId);
  };

  const today = new Date().toLocaleDateString();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayFormatted = yesterday.toLocaleDateString();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {/* <InvitationConfirmPopup
        open={isInvitationPopupOpen}
        setOpen={setIsInvitationPopupOpen}
        invitationId={invitationId}
        gameInstanceId={gameInstanceId}
      /> */}
      <div>
        <Box
          sx={{
            bgcolor: "#03364C",
            borderRadius: "27px",
            paddingY: isMobile ? 1 : "10px",
          }}
        >
          {" "}
          <Stack
            direction={"row"}
            sx={{ paddingX: "20px", marginTop: 2 }}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{
                  fontSize: isMobile ? 12 : 22,
                }}
              >
                Notifications
              </Typography>
              <Typography
                variant="caption"
                marginBottom={2}
                fontWeight={500}
                sx={{ color: "#11B1F4", fontSize: isMobile ? 8 : 14 }}
              >
                {data?.notification?.length === 0
                  ? "You’ve no new notifications"
                  : "You’ve got notifications"}
              </Typography>
            </Box>
            <Button
              sx={{
                background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                padding: isMobile ? 0 : 2,
                height: isMobile ? 20 : 40,
                borderRadius: "8px",
                color: "white",
                fontWeight: 600,
                fontSize: isMobile ? 6 : 12,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: " #10ADEF95",
                },
                "&:disabled": {
                  backgroundColor: "#085A7D",
                },
              }}
              onClick={() => handleMarkAllNotification()}
              disabled={unReadCount === 0}
            >
              Mark all as Read
            </Button>
          </Stack>
          {/* {data?.notification ? ( */}
          <div>
            <List dense compoent="span">
              {Object.keys(isGrouped).map((date) => {
                return (
                  <>
                    <div key={date} style={{ margin: "18px" }}>
                      <Typography
                        variant="h6"
                        fontWeight={500}
                        sx={{
                          fontSize: isMobile ? 12 : 18,
                        }}
                      >
                        {date === today
                          ? "Today"
                          : date === yesterdayFormatted
                          ? "Yesterday"
                          : date}
                      </Typography>
                    </div>

                    {isGrouped[date].map((notification) => (
                      <ListItem onClick={() => console.log("")}>
                        <Box
                          sx={{
                            background: notification?.isRead
                              ? alpha("#062633", 0.3)
                              : "#062633",
                            width: "100%",
                            padding: 1,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: isMobile ? 1 : 3,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#085A7D",
                                width: isMobile ? "25px" : "50px",
                                height: isMobile ? "25px" : "50px",
                                padding: "3px",
                                borderRadius: isMobile ? "5px" : "12px",
                              }}
                            >
                              {notification.type === "Entry fees" ? (
                                <img
                                  style={{
                                    width: isMobile ? "15px" : "25px",
                                    height: isMobile ? "15px" : "25px",
                                  }}
                                  src="/Images/deducted-arrow.png"
                                  alt=""
                                />
                              ) : notification.type === "Refund" ? (
                                <img
                                  style={{
                                    width: isMobile ? "15px" : "25px",
                                    height: isMobile ? "15px" : "25px",
                                  }}
                                  src="/Images/credited-arrow.png"
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{
                                    width: isMobile ? "15px" : "25px",
                                    height: isMobile ? "15px" : "25px",
                                  }}
                                  src="/Images/tropy.png"
                                  alt=""
                                />
                              )}
                            </Box>
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: isMobile && 10,
                                }}
                              >
                                {notification.title}
                              </Typography>

                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 700,
                                  color: "gray",
                                  fontSize: isMobile && 8,
                                }}
                              >
                                {notification.message}
                              </Typography>
                            </Box>
                          </Box>
                          {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "end",
                            }}
                          >
                            {notification.type === "Invitation" && (
                              <Button
                                sx={{
                                  background:
                                    "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                                  maxHeight: 10,
                                  paddingY: isMobile ? 1 : 2,
                                  minWidth: isMobile ? 40 : 70,
                                  borderRadius: "8px",
                                  color: "white",
                                  fontWeight: 500,
                                  fontSize: isMobile ? 6 : 12,
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: " #10ADEF95",
                                  },
                                  "&:disabled": {
                                    backgroundColor: "#085A7D",
                                  },
                                }}
                                onClick={() => {
                                  setGameInstanceId(notification?.gameInstance);
                                  setInvitationId(notification?.invitationLink);
                                  setIsInvitationPopupOpen(true);
                                }}
                              >
                                Accept
                              </Button>
                            )} */}

                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 700,
                              color: "gray",
                              mt: 1.5,
                              fontSize: isMobile ? 4 : 12,
                            }}
                          >
                            {moment(notification.createdAt).fromNow()}
                          </Typography>
                          {/* </div> */}
                        </Box>
                      </ListItem>
                    ))}
                  </>
                );
              })}
            </List>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Box
              component="span"
              sx={{ mt: 3, display: "flex", justifyContent: "end", mr: 2 }}
            >
              <Pagination
                count={Math.ceil(count / limit)}
                page={page}
                onChange={handlePageChange}
                defaultPage={1}
                siblingCount={0}
                color="standard"
                size="large"
                variant="outlined"
                renderItem={(item) => {
                  if (item.type === "page") {
                    return null; // Hide page numbers
                  }

                  return <PaginationItem {...item} />;
                }}
              />
            </Box>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Notification;
