import { useQuery } from "react-query";
import { getGameConfig } from "../Services/Service";

// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getUserGameConfig = async () => {
  try {
    // await delay(200);
    const response = await getGameConfig();
    console.log(response.data);
    return response.data?.data?.gameType;
  } catch (error) {
    console.log(error);
  }
};

export const useGameConfig = () =>
  useQuery({
    queryKey: ["gameCoinfig"],
    queryFn: getUserGameConfig,
    enabled: true,
    refetchOnWindowFocus: false,
  });
