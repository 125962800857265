import React, { Suspense, useContext } from "react";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
// import MyBalanceCard from "../../Components/Home/BalanceCard/MyBalanceCard";
// import TreasuryBalanceCard from "../../Components/Home/BalanceCard/TreasuryBalanceCard";
import QuickLinks from "../../Components/Home/QuickLinks/QuickLinks";
import WinnersUpdate from "../../Components/Home/WinnersUpdate";
import HomeLeaderBoard from "../../Components/Home/HomeLeaderBoard";
import CryptoUpdate from "../../Components/Home/CryptoUpdate/CryptoUpdate";
import LiveTelegramChat from "../../Components/liveChat/liveChat";
import LiveChatDrawer from "../../Components/liveChat/drawer";
import { DrawerContext } from "../../context/DrawerContext";
import { useTheme } from "@emotion/react";

const MyBalanceCard = React.lazy(() =>
  import("../../Components/Home/BalanceCard/MyBalanceCard")
);
const TreasuryBalanceCard = React.lazy(() =>
  import("../../Components/Home/BalanceCard/TreasuryBalanceCard")
);

const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openLiveChat, handleLiveChatOpen } = useContext(DrawerContext);

  return (
    <>
      <Stack direction={"row"} gap={2}>
        <Box sx={{ maxWidth: openLiveChat && !isSmallScreen ? "60%" : "100%" }}>
          <Grid
            // xs={12}
            sx={{
              // background: "linear-gradient(180deg, #022737 0%, #001D2A 76.5%)",
              borderRadius: "20px",
            }}
            spacing={2}
            container
          >
            {/* <Grid item xs={12} md={3.5}>
          <Suspense fallback={<div>Loading...</div>}>
            <MyBalanceCard />
          </Suspense>
        </Grid> */}
            {/* <Grid item xs={12} md={7.5}> */}
            <Grid item xs={12} md={openLiveChat && !isSmallScreen ? 12 : 7.5}>
              {/* Only for small screen */}
              <>
                {isSmallScreen && openLiveChat && (
                  <Box
                    sx={{
                      width: "100%",
                      height: 400,
                      marginBottom: 6,
                      position: "relative",
                    }}
                  >
                    <LiveChatDrawer
                      open={true}
                      handleLiveChatOpen={handleLiveChatOpen}
                    />
                  </Box>
                )}
              </>
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row", md: "row" }}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <MyBalanceCard />
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                  <TreasuryBalanceCard />
                </Suspense>
              </Box>
            </Grid>

            {!openLiveChat && (
              <Grid item xs={12} md={4.5}>
                <CryptoUpdate />
              </Grid>
            )}

            {/* <Grid container sm={12} xs={12} md={12} spacing={2}>
          <Grid item sm={12} xs={6} md={8}>
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row", md: "row" }}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={2}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <MyBalanceCard />
              </Suspense>

              <Suspense fallback={<div>Loading...</div>}>
                <TreasuryBalanceCard />
              </Suspense>
            </Box>
          </Grid>
          <Grid item sm={12} xs={6} md={4}>
            <CryptoUpdate />
          </Grid>
        </Grid> */}
          </Grid>

          <Grid container display={"flex"} spacing={4}>
            <Grid item xs={12} md={openLiveChat && !isSmallScreen ? 12 : 5}>
              <Box>
                <QuickLinks />
                <WinnersUpdate />
              </Box>
            </Grid>

            {!openLiveChat && (
              <Grid item xs={12} md={7}>
                <HomeLeaderBoard />
              </Grid>
            )}
          </Grid>
        </Box>
        <>
          {!isSmallScreen && openLiveChat && (
            <Box sx={{ width: "40%", height: "765px", position: "relative" }}>
              <LiveChatDrawer
                open={true}
                handleLiveChatOpen={handleLiveChatOpen}
              />
            </Box>
          )}
        </>
      </Stack>
    </>
  );
};

export default Home;
