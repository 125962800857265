import React, { useState } from "react";
import "../../Styles/main.css";
import { getLandingPageData } from "../../Services/Service";
import { useInfiniteQuery } from "react-query";
import HeroComponent from "../../Components/Landing/HeroComponent";
import GeneralFaqs from "../../Components/Landing/GeneralFaqs";
import GameTime from "../../Components/Landing/GameTime";
import AboutUs from "../../Components/Landing/AboutUs";
import FutureComponent from "../../Components/Landing/FutureComponent";
import CampaignTable from "../../Components/Landing/CampaignTable";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const NewLandingPage = () => {
  const [homeData, setHomeData] = useState([]);
  const fetchHomePageData = async ({ pageParam = 1 }) => {
    const result = await getLandingPageData({
      page: pageParam,
      limit: 7,
    });
    setHomeData(result?.data?.data);
    return result?.data?.data?.faq;
  };

  const {
    data: faqs,
    isSuccess,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery("faqResults", fetchHomePageData, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page < Math.ceil(lastPage.totalCount / 7))
        return lastPage.page + 1;
      return false;
    },
    retry: true,
    refetchOnWindowFocus: false,
  });

  console.log(homeData, "faqs");

  return (
    <>
      <HeroComponent homeData={homeData} />
      {/* <AboutUs homedata={homeData} /> */}
      <FutureComponent />
      {/* <GameTime homedata={homeData} /> */}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: { md: 10 },
          pb: 3,
        }}
      >
        <a
          // to={"/"}
          href="#FAQ_SECTION"
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/Images/landingPage/ambassador.png"
            style={{
              width: "80%",
            }}
          />
        </a>
      </Box>
      <CampaignTable />
      <GeneralFaqs
        faqs={faqs}
        isSuccess={isSuccess}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  );
};

export default NewLandingPage;
