import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";

const MobileScreenBanner = ({ src }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  return (
    <>
      <video
        ref={videoRef}
        style={{
          display: isSmallScreen ? "block" : "none",
          width: "100%",
          height: "300px",
          objectFit: "cover",
          borderRadius: "18px",
          marginBottom: "20px",
        }}
        loop
        muted
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default MobileScreenBanner;
