import { Button } from "@mui/material";
import React from "react";

const LandingButton = ({ text, onClick }) => {
  return (
    <>
      <Button
        sx={{
          paddingY: "10px",
          color: "white",
          fontWeight: 500,
          paddingX: "25px",
          textTransform: "none",
          fontFamily: "Space Grotesk",
          alignSelf: "start",
          borderRadius: "64px",
          background:
            "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
          marginTop: "30px",
          "&:hover": {
            backgroundColor: " #10ADEF95",
          },
        }}
        variant="contained"
        onClick={onClick}
      >
        {text}
      </Button>
    </>
  );
};

export default LandingButton;
