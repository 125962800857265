import {
  AppBar,
  Button,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import React from "react";
// import NavBarDrawer from "./Drawer";
import { useNavigate } from "react-router-dom";
import { RxDiscordLogo } from "react-icons/rx";
import { PiTelegramLogoLight } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
// import { SiGitbook } from "react-icons/si";
import { BiBookAlt } from "react-icons/bi";
// import { ImBook } from "react-icons/im";

import NavBarDrawer from "../../Pages/NewLandingPage/Drawer";

export const appBarZindex = 10;
const LandingHeader = ({ fromOtherPage }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md")); // mobile view
  const isTablet = useMediaQuery(theme.breakpoints.down("lg")); //tab view
  // const isTab = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const linksArray = [
    {
      title: "How to Play",
      link: "/how-to-play",
    },
    { title: "About Us", link: "/about-us" },
    { title: "Games", link: "/landing/biggestwinner" },
    // { title: "Affiliate Marketing", link: "/" },
    { title: "Roadmap", link: "roadmap" },
    // { title: "ETH Rewards", link: "/" },
  ];
  return (
    <>
      <AppBar
        position="relative"
        sx={{
          background: isMatch ? "rgba(0, 0, 0, 0.19)" : "transparent",
          // height: isMatch ? "82px" : 90,
          paddingY: isMatch ? 0 : 3,
          paddingX: 0,
          boxShadow: "none",
          zIndex: appBarZindex,
          color: "#ffffff",
        }}
      >
        <Box
          sx={{
            ml: isMatch ? (fromOtherPage === true ? 2 : 0) : 6,
            mr: isMatch ? (fromOtherPage === true ? 2 : 0) : 6,
            mt: fromOtherPage === true ? 2 : null,
            p: 1,
            height: isMatch ? "40px" : 90,
            background:
              "linear-gradient(to right bottom, #11B1F4 1%, #085A7D 100%)",
            borderRadius: isMatch ? 5 : 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: isMatch ? 2 : 4,
          }}
        >
          {/* <Toolbar sx={{ paddingX: { xs: 1, sm: 2, md: 2 } }}> */}
          <Grid container xs={12} sx={{ placeItems: "center" }}>
            {!isMatch ? (
              <>
                {" "}
                <Grid
                  item={true}
                  xs={isTablet ? 7 : 8}
                  sx={{
                    display: "flex",
                    //  justifyContent: "space-between"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "start",
                    }}
                  >
                    {isTablet && <NavBarDrawer linksArray={linksArray} />}

                    {!isMatch && (
                      <img
                        style={{
                          width: "170px",
                          height: "60px",
                          marginTop: "10px",
                          marginBottom: "5px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/")}
                        src="/Images/chmaps-logo1.png"
                        alt=""
                      />
                    )}

                    {/* <Divider orientation="vertical" sx={{ zIndex: 999 }} /> */}
                  </Box>

                  {!isTablet && (
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      // justifyContent={"start"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      // marginTop={2}
                      spacing={{ lg: 5, md: 2, sm: 2 }}
                      // xs={8}
                    >
                      {linksArray.map((link, i) => (
                        <Typography
                          fontSize={13}
                          fontFamily={"Space Grotesk"}
                          fontWeight={400}
                          key={i}
                          sx={{
                            "&:hover": { color: "#10ADEF" },
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => navigate(link.link)}
                        >
                          {link.title}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Grid>
                <Grid item={true} xs={isTablet ? 5 : 4}>
                  <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    alignItems={"center"}
                    marginTop={1}
                    spacing={1}
                    // marginLeft={2}
                  >
                    <a
                      href="https://cryptochamps-io.gitbook.io/cryptochamps.io/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        padding={0.8}
                        sx={{
                          borderRadius: "50px",
                          border: 1,
                          borderColor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            color: "#11B1F4",
                            background: "transparent",
                            borderColor: "#11B1F4",
                          },
                          cursor: "pointer",
                        }}
                      >
                        <BiBookAlt size={18} />
                      </Box>
                    </a>

                    <a
                      href="https://twitter.com/cryptochamps_io?s=21"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        padding={0.8}
                        sx={{
                          borderRadius: "50px",
                          border: 1,
                          borderColor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            color: "#11B1F4",
                            background: "transparent",
                            borderColor: "#11B1F4",
                          },
                          cursor: "pointer",
                        }}
                      >
                        <RiTwitterXLine size={18} />
                      </Box>
                    </a>

                    <a
                      href="https://discord.gg/usquyPnE"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        padding={0.8}
                        sx={{
                          borderRadius: "50px",
                          border: 1,
                          borderColor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            color: "#11B1F4",
                            background: "transparent",
                            borderColor: "#11B1F4",
                          },
                          cursor: "pointer",
                        }}
                      >
                        <RxDiscordLogo size={18} />
                      </Box>
                    </a>

                    <a
                      // href="https://t.me/CryptoChampsIO"
                      href="https://t.me/CryptoChampsx"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        padding={0.8}
                        sx={{
                          borderRadius: "50px",
                          border: 1,
                          borderColor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            color: "#11B1F4",
                            background: "transparent",
                            borderColor: "#11B1F4",
                          },
                          cursor: "pointer",
                        }}
                      >
                        <PiTelegramLogoLight size={18} />
                      </Box>
                    </a>
                    <Button
                      sx={{
                        color: "white",
                        fontSize: 16,
                        fontFamily: "Space Grotesk",
                        fontWeight: 400,
                        textTransform: "none",
                        "&:hover": {
                          color: "#11B1F4",
                          background: "transparent",
                        },
                        fontSize: 13,
                      }}
                      onClick={() => navigate("/login")}
                      variant="text"
                    >
                      Login
                    </Button>
                    <Button
                      sx={{
                        // display: "flex",
                        // justifyContent: "center",
                        border: 1,
                        borderColor: "white",
                        paddingY: "6px",
                        paddingX: "12px",
                        color: "#FFFFFF",
                        fontWeight: 400,
                        textTransform: "none",
                        borderRadius: "4px",
                        background: "transparent",
                        "&:hover": {
                          color: "#11B1F4",
                          background: "transparent",
                          borderColor: "#11B1F4",
                        },
                        borderRadius: 20,
                        fontSize: 13,
                      }}
                      variant="contained"
                      onClick={() => navigate("/signup")}
                    >
                      Join Now
                    </Button>
                  </Stack>
                </Grid>
              </>
            ) : (
              <>
                {/* <Grid item xs={10}> */}
                <Grid xs={12}>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    // marginTop={1}
                    // spacing={1.}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={0}>
                      <NavBarDrawer linksArray={linksArray} />
                      <img
                        style={{
                          width: "110px",
                          height: "38px",
                          // marginTop: "10px",
                          // marginBottom: "5px",
                          // marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/")}
                        src="/Images/chmaps-logo.png"
                        alt=""
                      />
                    </Box>
                    {/* <Box display={"flex"} gap={{ xs: 0.4, sm: 1 }}>
                      <a
                        href="https://cryptochamps-io.gitbook.io/cryptochamps.io/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Box
                          padding={{ xs: 0.4, sm: 0.8 }}
                          sx={{
                            borderRadius: "50px",
                            border: 1,
                            borderColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              color: "#11B1F4",
                              background: "transparent",
                              borderColor: "#11B1F4",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <BiBookAlt size={isTab ? 14 : 18} />
                        </Box>
                      </a>
                      <a
                        href="https://twitter.com/cryptochamps_io?s=21"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Box
                          padding={{ xs: 0.4, sm: 0.8 }}
                          sx={{
                            borderRadius: "50px",
                            border: 1,
                            borderColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              color: "#11B1F4",
                              background: "transparent",
                              borderColor: "#11B1F4",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <RiTwitterXLine size={isTab ? 14 : 18} />
                        </Box>
                      </a>
                      <a
                        href="https://discord.gg/usquyPnE"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Box
                          padding={{ xs: 0.4, sm: 0.8 }}
                          sx={{
                            borderRadius: "50px",
                            border: 1,
                            borderColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              color: "#11B1F4",
                              background: "transparent",
                              borderColor: "#11B1F4",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <RxDiscordLogo size={isTab ? 14 : 18} />
                        </Box>
                      </a>
                      <a
                        href="https://t.me/CryptoChampsIO"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Box
                          padding={{ xs: 0.4, sm: 0.8 }}
                          sx={{
                            borderRadius: "50px",
                            border: 1,
                            borderColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                              color: "#11B1F4",
                              background: "transparent",
                              borderColor: "#11B1F4",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <PiTelegramLogoLight size={isTab ? 14 : 18} />
                        </Box>
                      </a>
                    </Box> */}
                    <Box display={"flex"} gap={0}>
                      {/* <Button
                        sx={{
                          color: "#FFFFFF",
                          fontSize: { xs: 8, sm: 16 },
                          fontFamily: "Space Grotesk",
                          fontWeight: 400,
                          textTransform: "none",
                          "&:hover": {
                            color: "#11B1F4",
                            background: "transparent",
                          },
                        }}
                        onClick={() => navigate("/login")}
                        variant="text"
                      >
                        Login
                      </Button> */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: 1,
                          borderColor: "white",
                          paddingY: { xs: "2px", sm: "2px" },
                          paddingX: { xs: "6px", sm: "12px" },
                          color: "#FFFFFF",
                          fontSize: { xs: 8, sm: 14 },
                          fontWeight: 400,
                          cursor: "pointer",
                          textTransform: "none",
                          borderRadius: "4px",
                          background: "transparent",
                          "&:hover": {
                            color: "#11B1F4",
                            background: "transparent",
                            borderColor: "#11B1F4",
                          },
                        }}
                        variant="contained"
                        onClick={() => navigate("/signup")}
                      >
                        Join Now
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                {/* </Grid> */}
              </>
            )}
          </Grid>
          {/* </Toolbar> */}
        </Box>
      </AppBar>
    </>
  );
};

export default LandingHeader;
