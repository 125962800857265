import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const FooterComponent = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const zIndex = 1;

  return (
    <>
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      > */}
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: { xs: "80%", sm: "80%", md: "65%" },
            paddingY: 4,
            marginTop: { xs: 6, md: 10 },
            display: "flex",
            borderRadius: "7px",
            alignItems: "center",
            justifyContent: "center",
            marginX: "auto",
            boxShadow: "0 1px 5px gray", // Default shadow for the other sides
            "::before, ::after": {
              content: '""',
              position: "absolute",
              width: "calc(100% + 10px)", // Adjust the width and height to cover the corners
              height: "calc(100% + 10px)",
              top: "-5px", // Adjust top and left to cover the corners
              left: "-5px",
              background: "white", // Shadow color
              boxShadow: "inherit",
              zIndex: -1,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="caption"
              fontSize={{ xl: "50px", md: "40px", sm: "30px", xs: "20px" }}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
              lineHeight={1.5}
              textAlign={mobile && "center"}
              sx={{ color: "#FFFFFF" }}
            >
              Don’t wait, join us today and
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xl: "50px", md: "40px", sm: "30px", xs: "20px" }}
              textAlign={mobile && "center"}
              fontFamily={"Space Grotesk"}
              lineHeight={1.5}
              fontWeight={500}
              sx={{ color: "#FFFFFF" }}
            >
              become a Crypto Champ
            </Typography>

            <Button
              sx={{
                paddingY: { md: "10px", xs: "6px" },
                color: "white",
                fontWeight: 500,
                paddingX: { md: "25px", xs: "10px" },
                textTransform: "none",
                fontFamily: "Space Grotesk",
                borderRadius: "64px",
                fontSize: { xs: "8px", md: "14px" },
                marginTop: 2,
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                // marginTop: "30px",
                "&:hover": {
                  backgroundColor: " #10ADEF95",
                },
              }}
              variant="contained"
              onClick={() => navigate("/signup")}
            >
              Join Now
            </Button>
          </Box>
        </Box>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 4, md: 2 }}
          // padding={4}
          paddingY={{ xs: 2, sm: 4, md: 4 }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={"center"}
          marginTop={{ xs: 1, md: 12 }}
          width={"100%"}
          marginBottom={{ md: 4, xs: 0 }}
        >
          <Box sx={{ zIndex: zIndex - 1 }} className="white-footer-gradient" />
          <Box
            display={"flex"}
            // gap={6}
            sx={{
              width: { xs: "80%", sm: "400px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Link
              to={"/terms-and-condition"}
              style={{ textDecoration: "none" }}
            >
              <Typography
                fontSize={mobile ? 10 : 16}
                fontFamily={"Space Grotesk"}
                fontWeight={400}
                zIndex={zIndex + 1}
                sx={{
                  color: "#FFFFFF",
                  "&:hover": { color: "#10ADEF", cursor: "pointer" },
                  cursor: "pointer",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                Terms & Agreements
              </Typography>
            </Link>

            <Link to={"/privacy-policy"} style={{ textDecoration: "none" }}>
              <Typography
                fontSize={mobile ? 10 : 16}
                fontFamily={"Space Grotesk"}
                fontWeight={400}
                zIndex={zIndex + 10}
                sx={{
                  color: "#FFFFFF",
                  "&:hover": { color: "#10ADEF" },
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                Privacy Policy
              </Typography>
            </Link>
          </Box>

          <Typography
            fontSize={mobile ? 8 : 16}
            fontFamily={"Space Grotesk"}
            fontWeight={400}
            marginRight={{ md: 5, xs: 0 }}
            sx={{
              color: "#FFFFFF",
            }}
          >
            @2023 CHAMPS All Rights Reserved
          </Typography>
        </Stack>
      </Box>
      {/* </Box> */}
    </>
  );
};

export default FooterComponent;
