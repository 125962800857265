import { Box, Typography } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { removeUserSelectedCryptos } from "../../Services/Service";
// import { useSelectedCryotos } from "../../constants/useSelectedCryptos";
import styled from "styled-components";
// import { useQueryClient } from "react-query";

const HiddenButton = styled.div`
  display: none;
  position: absolute;
  top: -2px;
  right: -8px;
  cursor: pointer;
  z-index: 10;
`;

const Container = styled.div`
  position: relative;
  background: #022737;
  padding: 10px;
  border-radius: 16px;
  border: 2px solid #11b1f4;
  min-width: 250px;
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover ${HiddenButton} {
    display: block;
  }
`;
// height: 68px;
//   min-width: 280px;
const HeaderCryptoCard = ({ data, selectedCryptos, setSelectedCryptos }) => {
  const userId = sessionStorage.getItem("userId");
  // const queryClient = useQueryClient();
  // const classes = useStyles();

  // const { refetch } = useSelectedCryotos();

  const handleRemoveUserSelected = async (data) => {
    try {
      const body = {
        user: userId,
        cryptoCurrency: data._id,
      };
      const response = await removeUserSelectedCryptos(body);
      if (response) {
        const updatedCryptos = selectedCryptos.filter(
          (crypto) => crypto._id !== data._id
        );
        setSelectedCryptos(updatedCryptos);
        return toast.success("Successfully removed.!");
      }

      // return queryClient.invalidateQueries("selectedCryptos");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.!");
    }
  };

  return (
    <>
      <Container>
        <HiddenButton
          // sx={{ position: "absolute", top: -16, right: -26, display: "none" }}
          onClick={() => handleRemoveUserSelected(data)}
          className="hiddenButton"
        >
          <img
            width="18px"
            height="18px"
            src="/Images/deselect.png"
            alt="power-off-button"
          />
        </HiddenButton>

        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <img
            src={data.image}
            style={{ width: "35px", height: "35px" }}
            alt=""
          />
          <Box>
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
              {data?.symbol}
            </Typography>
            <Typography
              // noWrap
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="caption"
              fontSize={12}
              color={"gray"}
            >
              {data?.name?.length > 14
                ? `${data?.name.slice(0, 14)}...`
                : data?.name}
            </Typography>
          </Box>
        </Box>

        {/* <img src="/Images/line-chart.png" style={{ width: "50px" }} alt="" /> */}

        <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
          <Typography fontSize={14}>${data?.price?.toFixed(5)}</Typography>
          <Typography noWrap sx={{ color: "#AAAAAA", fontSize: 12 }}>
            {data.price_change_percentage_24h > 0
              ? `+${data.price_change_percentage_24h?.toFixed(2)}%`
              : `${data.price_change_percentage_24h?.toFixed(2)}%`}{" "}
            / 24hr
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default HeaderCryptoCard;
