import { useEffect, useState } from "react";
import GameCard from "./GameCard";
import { v4 as uuidv4 } from "uuid";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Box } from "@mui/material";

const FutureSwiper = ({ zIndex }) => {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <GameCard
          heading={"Biggest Loser"}
          image={"/Images/slider-bl.png"}
          link={"/landing/biggestloser"}
          zIndex={zIndex}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <GameCard
          heading={"Crypto Parlay"}
          image={"/Images/slider-dt.png"}
          link={"/landing/cryptoparlay"}
          zIndex={zIndex}
        />
      ),
    },

    {
      key: uuidv4(),
      content: (
        <GameCard
          heading={"Biggest Winner"}
          image={"/Images/slider-bw.png"}
          link={"/landing/biggestwinner"}
          zIndex={zIndex}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "60%", md: "70%" },
        margin: "0 auto",
      }}
    >
      <Carroussel
        cards={cards}
        height="300px"
        width="100%"
        margin="0 auto"
        offset={2}
        showArrows={false}
      />
    </Box>
  );
};

export default FutureSwiper;

export const Carroussel = (props) => {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
      {/* {" "}
        {cards.map((card, index) => (
          <GameCard
            heading={card.content}
            key={index}
            active={index === goToSlide}
          />
        ))}
      </Carousel> */}
    </div>
  );
};
