import * as React from "react";
import Box from "@mui/material/Box";
import {
  getTokenBalance,
  getUserById,
  updateReferralData,
  userUpdateApi,
} from "../../Services/Service";
import FormProvider from "../../Components/RHFTextField/FormProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "clipboard-copy";

import {
  Avatar,
  Button,
  Card,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import RHFTextField from "../../Components/RHFTextField/RHFTextField";
import { LoadingButton } from "@mui/lab";
import { RHFUploadAvatar } from "../../Components/RHFTextField/RHFUploadAvatar";
import Loader from "../../Components/Loader/Loader";
import toast from "react-hot-toast";
import ReferralPopup from "../../Components/Popups/ReferralPopup";
// import { AuthContext } from "../../context/AuthContext";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTheme } from "@emotion/react";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 500,
  },
}));

const Profile = () => {
  const userId = sessionStorage.getItem("userId");
  const userName = sessionStorage.getItem("userName");

  const [user, setUser] = React.useState([]);
  const [isReferralPopupOpen, setIsReferralPopupOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [randomText, setRandomText] = React.useState("");
  // const { user: userData } = React.useContext(AuthContext);
  const [profileJoined, setProfileJoined] = React.useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [referralCount, setReferralCount] = React.useState();
  const [referralReward, setReferralReward] = React.useState();

  React.useEffect(() => {
    const getUser = async () => {
      const res = await getUserById(userId);
      setUser(res.data?.data);
      const response = await getTokenBalance(userId);
      // console.log(response.data);
      const body = {
        userId: userId,
        code: userName,
      };
      const getReferralData = await updateReferralData(body);
      console.log("====================================");
      console.log(getReferralData.data);
      console.log("====================================");
      setProfileJoined(getReferralData.data.data?.referralsCount);
      setReferralCount(response.data?.data?.referralCount);
      setReferralReward(response.data?.data?.referralReward);
    };
    getUser();
  }, [userId, userName]);

  const UpdateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    username: Yup.string().required("User name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    profilePic: Yup.mixed(),
    birthDate: Yup.string(),
    phone: Yup.string().required("Phone number is required"),
  });

  const defaultValues = {
    name: user?.name,
    username: user?.username,
    googleAccount: user?.email,
    email: user?.email,
    gender: user?.gender,
    profilePic:
      user?.profilePic === "undefined" || user?.profilePic === "null"
        ? ""
        : user?.profilePic,
    phone: user?.phone,
    loginWalletAddress:
      user?.loginWalletAddress === "undefined" ||
      user?.loginWalletAddress === "null"
        ? ""
        : user?.loginWalletAddress,
    birthDate: new Date(user?.birthDate),
  };

  const methods = useForm({
    resolver: yupResolver(UpdateSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  React.useEffect(() => {
    if (user) {
      reset(defaultValues);
    }
    if (!user) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = React.useCallback(
    async (data) => {
      try {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("username", data.username);
        formData.append("email", data.email);
        formData.append("gender", data.gender);
        formData.append("phone", data.phone);
        formData.append("profilePic", data.profilePic);
        formData.append("birthDate", data.birthDate);
        formData.append("loginWalletAddress", data.loginWalletAddress);

        const response = await userUpdateApi(userId, formData);
        if (response.status === 200) {
          // alert("Profile update successfully");
          setUser({ ...data, user });
          return toast.success(`Profile updated successfully`, {
            position: "top-right",
          });
        } else {
          return toast.error("Something went wrong");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [setUser, userId, user]
  );

  const handleDrop = React.useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      console.log(file);

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue("profilePic", newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleCopyReferralLink = async () => {
    // await navigator.clipboard.writeText(referralUrl);
    const baseUrl = window.location.origin; // Get the base URL
    const urlWithReferral = `${baseUrl}/signup?referral=${user?.username}`;
    // copyToClipboard(urlWithReferral);
    copy(urlWithReferral)
      .then(() => {
        console.log("Text copied to clipboard:", urlWithReferral);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  React.useEffect(() => {
    const generateRandomString = () => {
      const randomText = Math.random()
        .toString(36)
        .substring(2, 10)
        .toUpperCase();
      return `/${randomText}`;
    };
    const newRandomText = generateRandomString();
    setRandomText(`${user?.username}${newRandomText}`);
    // setProfileJoined(userData?.profileJoined?.profilesJoined?.length);
  }, [user]);

  // React.useEffect(() => {
  //   setProfileJoined(userData?.profileJoined?.profilesJoined?.length);
  // }, [userData]);
  console.log(profileJoined, "profileJoined");

  return (
    <>
      <ReferralPopup
        open={isReferralPopupOpen}
        setOpen={setIsReferralPopupOpen}
        username={user?.username}
        randomText={randomText}
      />
      {user?.name ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  // p: 3,
                  borderRadius: 3,
                  paddingX: { xs: 1, md: 4 },
                  paddingBottom: 4,
                  background: "#03364C",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginBottom={2}
                  // padding={3}
                >
                  <Typography
                    variant="h5"
                    marginTop={3}
                    marginBottom={2}
                    fontWeight={500}
                    sx={{ fontSize: "24px" }}
                  >
                    My Profile
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    mb: 5,
                    display: "flex",
                    flexDirection: { md: "row", xs: "column" },
                    justifyContent: "start",
                    alignItems: { md: "center", xs: "start" },
                    gap: 1,
                  }}
                >
                  <RHFUploadAvatar
                    name="profilePic"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    // helperText={

                    // }
                  />

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    alignItems={"start"}
                  >
                    <Typography variant="body1" fontWeight={500} fontSize={18}>
                      {user?.username}
                    </Typography>

                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={{ xs: 1, md: 2 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingX: 0.5,
                          paddingY: 2,
                          borderRadius: "50px",

                          height: "32px",
                          backgroundColor: "#011f2d",
                        }}
                      >
                        <ProgressBar
                          completed={
                            profileJoined === undefined ? 0 : profileJoined
                          }
                          maxCompleted={
                            referralCount !== undefined ? referralCount : 0
                          }
                          customLabel={`${
                            profileJoined === undefined ? 0 : profileJoined
                          }/${
                            referralCount !== undefined ? referralCount : ""
                          }`}
                          isLabelVisible={true}
                          customLabelStyles={{ color: "#ffd54b" }}
                          // labelColor=""
                          width={isSmallScreen ? "200px" : "300px"}
                          height="25px"
                          labelSize="12px"
                          // padding="5px"
                          labelAlignment="left"
                          baseBgColor={"#011f2d"}
                          bgColor={
                            "linear-gradient(109.59deg, #11b1f4, #085a7d)"
                          }
                        />
                      </Box>
                      <Avatar
                        onClick={() => setIsReferralPopupOpen(true)}
                        sx={{ width: 22, height: 22, cursor: "pointer" }}
                        src="/Images/qr.png"
                        variant="square"
                      />
                    </Box>

                    <div
                      style={{
                        position: "relative",
                        fontSize: "10px",
                        fontFamily: "Poppins",
                        color: "#fff",
                        textAlign: "left",
                        display: "inline-block",
                      }}
                    >
                      {referralCount !== undefined ||
                      referralReward !== undefined ? (
                        `Invite ${referralCount} Users and get ${referralReward} Champs (User must play atleast 3 games)`
                      ) : (
                        <></>
                      )}
                    </div>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    columnGap: 8,
                    rowGap: 3,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontWeight: 500,
                        marginBottom: 1,
                      }}
                    >
                      Username
                    </Typography>
                    <RHFTextField
                      name="username"
                      // label="Username"
                      disabled={true}
                    />
                  </Box>
                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          marginBottom: 1,
                        }}
                      >
                        Google Account{" "}
                      </Typography>
                      {user.loginType === "Email" ? (
                        <Avatar
                          sx={{ width: 16, height: 16 }}
                          src="/Images/verified-badge.png"
                        />
                      ) : (
                        <Avatar
                          sx={{ width: 16, height: 16 }}
                          src="/Images/unverified-badge.png"
                        />
                      )}
                    </Box>
                    <RHFTextField
                      name="email"
                      disabled={user.loginType === "Email" ? true : false}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontWeight: 500,
                        marginBottom: 1,
                      }}
                    >
                      Email
                    </Typography>
                    <RHFTextField
                      name="email"
                      // label="Email"
                      disabled={user.loginType === "Email" ? true : false}
                    />
                  </Box>

                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          marginBottom: 1,
                        }}
                      >
                        Wallet Address
                      </Typography>
                      {user.loginType === "MetaMask" ? (
                        <Avatar
                          sx={{ width: 16, height: 16 }}
                          src="/Images/verified-badge.png"
                        />
                      ) : (
                        <Avatar
                          sx={{ width: 16, height: 16 }}
                          src="/Images/unverified-badge.png"
                        />
                      )}
                    </Box>
                    <RHFTextField
                      name="loginWalletAddress"
                      disabled={user.loginType === "MetaMask" ? true : false}
                    />
                  </Box>

                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          marginBottom: 1,
                        }}
                      >
                        Phone
                      </Typography>
                      {user.loginType === "Phone" ? (
                        <Avatar
                          sx={{ width: 16, height: 16 }}
                          src="/Images/verified-badge.png"
                        />
                      ) : (
                        <Avatar
                          sx={{ width: 16, height: 16 }}
                          src="/Images/unverified-badge.png"
                        />
                      )}
                    </Box>
                    <RHFTextField
                      name="phone"
                      // label="Phone No"
                      type="number"
                      disabled={user.loginType === "Phone" ? true : false}
                    />
                  </Box>
                  <Box>
                    <Box display={"flex"} gap={1}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          marginBottom: 1,
                        }}
                      >
                        Referral Link
                      </Typography>

                      <Avatar
                        onClick={() => setIsReferralPopupOpen(true)}
                        sx={{ width: 16, height: 16, cursor: "pointer" }}
                        src="/Images/qr.png"
                        variant="square"
                      />
                    </Box>
                    <OutlinedInput
                      fullWidth
                      sx={{
                        "& fieldset": { border: "none" },
                        backgroundColor: "#011F2D",
                        borderRadius: "8px",
                        width: "100%",
                      }}
                      name="referral"
                      value={randomText}
                      endAdornment={
                        <InputAdornment position="end">
                          <ClickAwayListener
                            sx={{
                              background: "#011F2D",
                            }}
                          >
                            <LightTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleCopyReferralLink}
                                edge="start"
                              >
                                <ContentCopyIcon
                                  sx={{ color: "#11B1F4" }}
                                  fontSize="small"
                                />
                              </IconButton>
                            </LightTooltip>
                          </ClickAwayListener>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Box>

                <Stack
                  sx={{
                    mt: { xs: 4, md: 8 },
                    mb: { xs: 3, md: 6 },
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: { xs: 1, md: 2.5 },
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                      background:
                        "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                      paddingY: 1,
                      fontSize: { xs: 10, md: 14 },
                      textTransform: "none",
                      borderRadius: "8px",
                      width: { md: "140px", xs: "110px" },
                      "&:hover": {
                        backgroundColor: " #10ADEF95",
                      },
                    }}
                  >
                    {"Save Changes"}
                  </LoadingButton>
                  <Button
                    sx={{
                      background:
                        "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                      color: "white",
                      paddingX: 2,
                      borderRadius: "8px",
                      textTransform: "none",
                      width: { md: "140px", xs: "110px" },
                      fontSize: { xs: 10, md: 14 },
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: " #10ADEF95",
                      },
                    }}
                    onClick={() => reset(defaultValues)}
                  >
                    Discard
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      ) : (
        <Loader marginTop={30} />
      )}
    </>
  );
};

export default Profile;
