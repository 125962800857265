import React from "react";
import Loader from "../Loader/Loader";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import GeneralFaqList from "./GeneralFaqList";
import { useTheme } from "@emotion/react";

const GeneralFaqs = ({
  faqs,
  isSuccess,
  isFetching,
  hasNextPage,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {" "}
      <Box
        id="FAQ_SECTION"
        sx={{
          width: "100%",
          height: "min-content",
          marginTop: 10,
        }}
      >
        <Container>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <Loader />
            </Box>
          ) : (
            <>
              <Grid container spacing={{ md: 20, xs: 1 }}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="caption"
                    fontSize={{ md: "50px", sm: "30px", xs: "26px" }}
                    fontFamily={"Space Grotesk"}
                    fontWeight={500}
                    sx={{ color: "#FFFFFF" }}
                  >
                    General FAQ’s
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Space Grotesk",
                      color: "#FFFFFF",
                      fontSize: { md: "18px", xs: "14px" },
                      fontWeight: 400,
                      width: "100%",
                      lineHeight: 2,
                      textAlign: isMobile && "center",
                      marginY: 2,
                    }}
                  >
                    Everything you need to know about CHAMPS. Can’t find the
                    answer you’re looking for? Please Contact Us at:
                    <Typography
                      sx={{
                        fontFamily: "Space Grotesk",
                        color: "#FFFFFF",
                        fontSize: { md: "18px", xs: "14px" },
                        fontWeight: 400,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      info@cryptochamps.io
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {isSuccess && <GeneralFaqList faqs={faqs} />}

                  {isFetching && isFetchingNextPage && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={2}
                    >
                      <CircularProgress sx={{ color: "#11B1F4" }} size={24} />
                    </Stack>
                  )}
                  {hasNextPage && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={{
                        xs: "center",
                        sm: "center",
                        md: "start",
                      }}
                      marginTop={2}
                    >
                      <Button
                        sx={{
                          paddingY: { md: "10px", xs: "6px" },
                          color: "white",
                          fontWeight: 400,
                          paddingX: { md: "25px", xs: "10px" },
                          textTransform: "none",
                          fontFamily: "Space Grotesk",
                          borderRadius: "64px",
                          fontSize: { xs: "8px", md: "14px" },
                          marginTop: { xs: 1, md: 2 },

                          background:
                            "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                          "&:hover": {
                            backgroundColor: " #10ADEF95",
                          },
                        }}
                        variant="contained"
                        onClick={fetchNextPage}
                      >
                        + Read more
                      </Button>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default GeneralFaqs;
