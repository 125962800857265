import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { RxDiscordLogo } from "react-icons/rx";
import { PiTelegramLogoLight } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { BiBookAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const NavBarDrawer = ({ linksArray }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: 250,
            background: "#0F103C",
            height: "100%",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} marginBottom={3}>
            <img
              style={{
                width: "170px",
                height: "60px",
                marginTop: "15px",
                marginLeft: "24px",
              }}
              src="/Images/chmaps-logo.png"
              alt=""
            />
          </Stack>
          <List>
            {linksArray.map((link, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(link.link);
                  setOpen(false);
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  sx={{
                    fontSize: 16,
                    fontFamily: "Space Grotesk",
                    fontWeight: 400,
                    "&:hover": {
                      color: "#11B1F4",
                    },
                  }}
                >
                  {link.title.toString()}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
          <List sx={{ marginTop: 3 }}>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={1}
              gap={2}
              sx={{
                marginRight: { md: 3 },
              }}
            >
              <a
                href="https://cryptochamps-io.gitbook.io/cryptochamps.io/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <BiBookAlt size={18} />
                </Box>
              </a>

              <a
                href="https://twitter.com/cryptochamps_io?s=21"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <RiTwitterXLine size={18} />
                </Box>
              </a>

              <a
                href="https://discord.gg/usquyPnE"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <RxDiscordLogo size={18} />
                </Box>
              </a>

              <a
                href="https://t.me/CryptoChampsIO"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <PiTelegramLogoLight size={18} />
                </Box>
              </a>
            </Grid>
          </List>
        </Box>
      </Drawer>

      <IconButton onClick={() => setOpen(true)}>
        <MenuIcon
          sx={{
            // color: "#0EA1EE",
            color: "white",
            "&:hover": {
              color: "#11B1F4",
            },
          }}
        />
      </IconButton>
    </>
  );
};

export default NavBarDrawer;
