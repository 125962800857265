import { useTheme } from "@emotion/react";
import {
  Avatar,
  Badge,
  Box,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "transparent",
  padding: "20px",
  fontSize: "16px",
  fontWeight: "400",
  // "#12161d",
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "transparent",
    width: "100%",
    marginTop: "10px",
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "transparent",
}));

const GameTime = ({ homedata }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container sx={{ width: "100%", marginTop: { md: 20 } }}>
      <Grid container spacing={{ md: 5, xs: 2 }}>
        <Grid item xs={12} md={7}>
          {" "}
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "26px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={1.2}
            sx={{ color: "#FFFFFF" }}
          >
            Earn CHAMPS tokens by competing against others
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            sx={{
              fontFamily: "Space Grotesk",
              color: "#FFFFFF",
              fontSize: { md: "18px", xs: "14px" },
              lineHeight: 2,
              fontWeight: 400,
              width: "100%",
              textAlign: isMobile && "center",
              marginY: 2,
            }}
          >
            Enter the exciting world of Fantasy Crypto where you can be a Winner
            or Loser and still WIN. Put your crypto skills to the test
          </Typography>
        </Grid>

        {/* table */}

        <Box
          sx={{
            marginY: "20px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingX: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "1000px",
              overflow: "auto",
              justifyContent: "center",
            }}
          >
            <TableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Rank</StyledTableCell>
                    <StyledTableCell>Player</StyledTableCell>
                    <StyledTableCell>Game</StyledTableCell>
                    <StyledTableCell>Coin Team</StyledTableCell>
                    <StyledTableCell>Profit</StyledTableCell>
                    <StyledTableCell>Score</StyledTableCell>
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {homedata?.recentGameTopPlayer
                    ?.sort((a, b) => a.prizeAmount - b.prizeAmount)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: 26,
                            fontWeight: 500,
                            paddingLeft: 4,
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box display={"flex"}>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              badgeContent={<></>}
                            >
                              <Avatar
                                sx={{ width: 45, height: 45 }}
                                alt="Travis Howard"
                                src={row.userId?.profilePic}
                              />
                            </Badge>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                            >
                              <Typography
                                fontWeight={400}
                                fontSize={16}
                                marginX={1}
                              >
                                {row.userId?.name}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          {row?.game?.winnerType}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box display={"flex"} gap={1}>
                            {row?.selectedCryptos
                              ?.slice(0, 5)
                              .map((crypto, i) => (
                                <Avatar
                                  key={i}
                                  sx={{
                                    width: 26,
                                    height: 26,
                                    border: "1px solid white",
                                    padding: 0.3,
                                  }}
                                  src={crypto?.cryptos?.image}
                                />
                              ))}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            fontSize: isMobile ? 14 : 16,
                            fontWeight: 500,
                            // paddingLeft: 4,
                          }}
                        >
                          {/* {row?.prizeAmount?.toFixed(2)} USDC */}
                          {champsCovertDecimals(
                            row?.prizeAmount,
                            commonData?.tokenDecimal
                          )}{" "}
                          USDC
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: "none", paddingLeft: 4 }}
                        >
                          {row?.averagePercentage?.toFixed(2)}%
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default GameTime;
