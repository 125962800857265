import { Box } from "@mui/material";
import React from "react";

const LogoLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <img
          src="/Images/logo1.png"
          style={{
            width: "105px",
            height: "100px",
          }}
          alt=""
        />

        <img
          style={{
            width: "100px",
            position: "absolute",
            top: "75px",
          }}
          src={"/gif/loader.gif"}
          alt="GIF description"
        />
      </Box>
      {/* <Typography
        variant="caption"
        sx={{
          color: "white",
        }}
      >
        Loading...
      </Typography> */}
    </Box>
  );
};

export default LogoLoader;
