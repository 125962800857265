import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WithdrawPopup({ open, setOpen, amount, id, symbol }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundColor: "white",
            padding: { md: 2, xs: 1 },
            width: isMobile ? "100%" : "420px",
            height: "100%",
            // paddingY: 4,
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "gray",
              margin: 1,
              "&:hover": {
                color: "#11B1F4",
                backgroundColor: "transparent",
              },
            }}
            size="medium"
            onClick={handleClose}
          >
            <CloseIcon fontSize="medium" sx={{ color: "gray" }} />
          </IconButton>
          <img
            style={{
              width: 65,
              height: 65,
              marginTop: 4,
            }}
            src="/Images/popups/success-icon.png"
            alt=""
          />
          <DialogTitle
            sx={{
              color: "black",
              fontWeight: 600,
              fontSize: { xs: 12, sm: 16, md: 18 },
            }}
          >
            Withdraw Successfully Submitted
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                color: "gray",
                fontSize: { xs: 8, sm: 10, md: 14 },
                alignContent: "center",
              }}
              textAlign={"center"}
              id="alert-dialog-slide-description"
            >
              {`Your withdraw of ${Number(amount).toFixed(
                2
              )} ${symbol} has been submitted successfully!`}
              <br />
              {/* {`Transaction Id : ${id}`} */}
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
