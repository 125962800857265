import { Box, Button } from "@mui/material";
import React from "react";
import { useGameConfig } from "../../constants/useGameConfig";

const MultiplayerPicks = ({
  currentPicksTab,
  handleAllGamesClick,
  // isGameConfigLoading,
  // gameConfig,
  handlePicksTabClick,
}) => {
  const { data: gameConfig, isLoading: isGameConfigLoading } = useGameConfig();
  return (
    <>
      {isGameConfigLoading ? (
        <>Loading...</>
      ) : (
        <Box
          sx={{
            background: "#042836",
            padding: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "100px",
            height: 35,
          }}
          gap={2}
        >
          <Button
            disabled={currentPicksTab === ""}
            sx={{
              color: "white",
              width: 130,
              paddingX: 2,
              height: 25,
              borderRadius: "19px",
              "&.Mui-disabled": {
                background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                color: "white",
              },
              textTransform: "none",
              lineBreak: "strict",
            }}
            onClick={handleAllGamesClick}
          >
            All Games
          </Button>

          <>
            {gameConfig
              .sort((a, b) => a - b)
              .map((piktab, index) => (
                <Button
                  key={index}
                  id={index}
                  disabled={currentPicksTab === `${index}`}
                  onClick={handlePicksTabClick}
                  sx={{
                    color: "white",
                    width: 90,
                    height: 25,
                    paddingX: 2,
                    borderRadius: "19px",
                    "&.Mui-disabled": {
                      background:
                        "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                      color: "white",
                    },
                    textTransform: "none",
                  }}
                >
                  {piktab} Picks
                </Button>
              ))}
          </>
        </Box>
      )}
    </>
  );
};

export default MultiplayerPicks;
