// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Radio,
  Stack,
  Typography,
  RadioGroup,
  CardHeader,
  CardContent,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";

import { BsCheckCircleFill } from "react-icons/bs";
import { useState } from "react";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

const OptionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2.5),
  justifyContent: "space-between",
  background: "#011F2D",
  //   transition: theme.transitions.create("all"),
  //   border: `solid 1px ${theme.palette.divider}`,
  borderRadius: "14px",
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 22,
  height: 22,
  backgroundColor: "#085A7D",
  "input:hover ~ &": {
    backgroundColor: "",
  },
}));

// ----------------------------------------------------------------------

export default function DepositePaymentMethods({
  paymentOptions,
  cardOptions,
  isheaderShow,
}) {
  const [values, setValues] = useState("crypto_wallet");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //   const isDesktop = useResponsive('up', 'sm');

  const handleRadioChange = (event) => {
    setValues(event.target.value);
  };

  return (
    <Card sx={{ background: "#03364C", boxShadow: "none" }}>
      <CardHeader
        title="Deposit Method"
        sx={{ display: isheaderShow ? "block" : "none" }}
      />
      <CardContent>
        <>
          <RadioGroup value={values} onChange={handleRadioChange}>
            <Stack spacing={2}>
              {paymentOptions.map((method) => {
                const { value, title, icon, description } = method;

                const hasChildren = values === "credit_card";

                // const selected = values === value;

                return (
                  <OptionStyle
                    key={title}
                    sx={{
                      //   ...(selected && {
                      //     background: "#085A7D",
                      //   }),
                      ...(hasChildren && { flexWrap: "wrap" }),
                    }}
                  >
                    <FormControlLabel
                      value={value}
                      control={
                        <Radio
                          //   sx={{ color: "#145471" }}
                          checkedIcon={
                            <BsCheckCircleFill
                              size={22}
                              style={{ color: "#11B1F4" }}
                            />
                          }
                          icon={<BpIcon />}
                        />
                      }
                      label={
                        <Box
                          sx={{
                            ml: isSmallScreen ? 0 : 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: isSmallScreen ? 30 : 40,
                              height: isSmallScreen ? 30 : 40,
                              marginRight: isSmallScreen ? 1 : 2,
                            }}
                          >
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={icon}
                              alt=""
                            />
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: { xs: 10, md: 16 },
                              }}
                              fontWeight={600}
                            >
                              {title}
                            </Typography>
                            <Typography
                              sx={{
                                color: "text.secondary",
                                fontSize: { xs: 8, md: 12 },
                              }}
                            >
                              {description}
                            </Typography>
                          </Box>
                        </Box>
                      }
                      sx={{ flexGrow: 1, py: 3 }}
                    />

                    {/* {isDesktop && (
                          <Stack direction="row" spacing={1} flexShrink={0}>
                            {icons.map((icon) => (
                              <Image key={icon} alt="logo card" src={icon} />
                            ))}
                          </Stack>
                        )} */}
                    {/* 
                    {hasChildren && (
                      <Collapse in={value === "credit_card"} sx={{ width: 1 }}>
                        <TextField
                          select
                          fullWidth
                          label="Cards"
                          SelectProps={{ native: true }}
                        >
                          {cardOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>

                        <Button
                          size="small"
                          //   startIcon={<BsCheckCircleFill />}
                          sx={{ my: 3, color: "#11B1F4" }}
                        >
                          Add new card
                        </Button>
                      </Collapse>
                    )} */}
                  </OptionStyle>
                );
              })}
            </Stack>
          </RadioGroup>
        </>
      </CardContent>
    </Card>
  );
}
