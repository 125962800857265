import { useTheme } from "@emotion/react";
import { Box, Typography, alpha, useMediaQuery } from "@mui/material";
import React from "react";

const Degentime = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {" "}
      <Box
        sx={{
          height: isMobile ? "300px" : "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "22px",
          }}
          src="/Images/crypto-parlay.png"
          alt=""
        />

        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: alpha("#000000", 0.4),
              width: "200px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "25px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {" "}
              Coming Soon...
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Degentime;
