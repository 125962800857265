import React, { useState, useEffect } from "react";

const CountdownTimer = ({ startTime }) => {
  const [timeRemaining, setTimeRemaining] = useState();

  useEffect(() => {
    const timer = setInterval(() => {
      function calculateTimeRemaining() {
        const easternTime = new Date().toLocaleString("en-US", {
          timeZone: process.env.REACT_APP_DEFAULT_TIME_ZONE,
        });

        const easternTimeInMS = Date.parse(easternTime);
        // const now = new Date().getTime();
        const timeDiff = startTime.getTime() - easternTimeInMS;
        //   console.log(difference, "difference");

        // const now = new Date();
        // const endTime = new Date(startTime);
        // const timeDiff = endTime - now;

        if (timeDiff <= 0) {
          // Timer expired
          return "00:00:00";
        }

        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        return (
          formatTime(hours) +
          ":" +
          formatTime(minutes) +
          ":" +
          formatTime(seconds)
        );
      }

      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  function formatTime(time) {
    return time < 10 ? "0" + time : time;
  }

  return (
    <>
      <span style={{ fontWeight: 500 }}> {timeRemaining}</span>
    </>
  );
};

export default CountdownTimer;
