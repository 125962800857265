import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const WelcomeText = () => {
  const theme = useTheme();
  const isxSmall = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: isxSmall ? "column" : "row",
          alignItems: isxSmall ? "start" : "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontWeight: 300,
            fontFamily: "Montserrat",
          }}
        >
          Hi,
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontWeight: 700,
            fontFamily: "Poppins",
          }}
        >
          Welcome Back
        </Typography>
      </Box>
      {/* <Box> */}
      <Typography
        variant="h3"
        sx={{
          color: "white",
          fontWeight: 300,
          fontFamily: "Poppins",
        }}
      >
        Ready to be a{" "}
        <span style={{ color: "#10ADEF", fontWeight: "700" }}>Champ</span> ?
      </Typography>
    </>
  );
};

export default WelcomeText;
