import { useEffect, useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Input,
  Slide,
  InputAdornment,
  ClickAwayListener,
  Box,
  alpha,
  useMediaQuery,
  Button,
} from "@mui/material";
import { BiSearch } from "react-icons/bi";
import { searchCryptos } from "../../Services/Service";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuggestionCryptoCard from "../common/SuggestionCryptoCard";
import { useTheme } from "@emotion/react";
// import { useQuery } from "react-query";
// utils
// import cssStyles from '../../../utils/cssStyles';
// components
// import Iconify from '../../../components/Iconify';
// import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

// const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  //   ...cssStyles(theme).bgBlur(),
  top: 0,
  //   left: 240,
  right: 0,
  zIndex: 999,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  color: "#000000",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha("#002839", 0.5),
  alignItems: "center",
  justifyContent: "start",
  height: "100%",
  padding: theme.spacing(0, 3),

  //   boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up("sm")]: {
    // paddingLeft: 250,
    height: "100%",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: 250,
    height: "100%",
  },
}));

/* form */

// ----------------------------------------------------------------------

export default function Searchbar({
  selectedCryptos,
  setSelectedCryptos,
  zIndex,
}) {
  // const [cryptos, setCryptos] = useState([]);
  const page = 0;
  const limit = 10;
  // const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const theme = useTheme();
  const isSmallUp = useMediaQuery(theme.breakpoints.up("sm"));
  const userId = sessionStorage.getItem("userId");

  // console.log(cryptos, "RES");
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  // const { refetch } = useQuery(
  //   ["searchCryptos", limit, page, term],
  //   async () => {},
  //   {
  //     refetchOnMount: true,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    const fetchSearchOptions = async () => {
      try {
        const response = await searchCryptos({
          id: userId,
          page: page + 1,
          limit,
          term,
        });
        return response.data?.data?.cryptos;
        // setCryptos(response.data?.data?.cryptos);
        // setCount(response.data.total);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSearchOptions();
  }, [term, limit, page, userId]);
  // console.log(data, "searchCryptos");

  const fetchSuggestions = async (term) => {
    const response = await searchCryptos({
      id: userId,
      page: page + 1,
      limit,
      term,
    });
    return setSuggestions(response.data?.data?.cryptos);
  };

  const handleTitle = async (e) => {
    const newQuery = e.target.value;
    setTerm(newQuery);
    setQuery(newQuery);
    await fetchSuggestions(newQuery);
    console.log(query);
  };

  const handleClose = () => {
    setTerm("");
    setQuery("");
    setSuggestions([]);
    setOpen(false);
  };

  // console.log(suggestions, "SS");

  return (
    // <Box display={"flex"} flexDirection={"column"}>
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <>
            <Button
              sx={{
                background:
                  "linear-gradient(to right, #11B1F4 -30%, #085A7D  100%, #085A7D 100%)",
                // background:
                //   "linear-gradient(90deg, #DEB940 0%, #BC9000 28%, #C7A947 51%, #C79902 71.5%, #F2CA46 100%)",
                paddingY: 1.5,
                // height: "50px",
                // border: 1,
                // borderColor: "#FFE17E",
                paddingX: 3,
                // "&:hover": {
                //   background: "#FFE17E",
                // },
                minWidth: "130px",
                borderRadius: "41px",
                color: "white",
                fontSize: 12,
                textTransform: "none",
              }}
              onClick={handleOpen}
              // variant="outlined"
            >
              + Add Crypto
            </Button>
            {/* </Box> */}
            {/* )} */}
          </>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle sx={{ zIndex: zIndex + 10 }}>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              value={query}
              onChange={handleTitle}
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <BiSearch size={24} color="#D0D4DC" />
                </InputAdornment>
              }
              endAdornment={
                <HighlightOffIcon
                  onClick={() => handleClose()}
                  size={24}
                  color="#D0D4DC"
                  sx={{
                    cursor: "pointer",
                  }}
                />
              }
              sx={{
                mr: 1,
                fontWeight: "fontWeightBold",
                color: "white",
                marginBottom: 4.5,
                marginTop: 4,
              }}
            />

            {suggestions.length === 0 ? (
              <> </>
            ) : (
              <div
                style={{
                  width: isSmallUp ? "100%" : "100vw",
                  // height: "100vh",
                  background: "#011F2D",
                  margin: 0,
                }}
              >
                <Box
                  sx={{
                    zIndex: 999,
                    color: "white",
                    height: "100vh",
                    overflow: "scroll",
                    // width: "100vw",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    margin: 1,
                    paddingBottom: 19,
                    paddingRight: 2,
                    paddingTop: 2,
                    "&::-webkit-scrollbar": { display: "none" },
                    maxWidth: "800px",
                  }}
                >
                  {suggestions
                    .filter((coin) => coin.isExist === false)
                    .map((coin) => {
                      const isCoinSelected = coin.isExist === true;
                      return (
                        <SuggestionCryptoCard
                          key={coin._id}
                          data={coin}
                          // refetch={refetch}
                          isCoinSelected={isCoinSelected}
                          setSuggestions={setSuggestions}
                          setQuery={setQuery}
                          setTerm={setTerm}
                          setOpen={setOpen}
                          selectedCryptos={selectedCryptos}
                          setSelectedCryptos={setSelectedCryptos}
                        />
                      );
                    })}
                </Box>
              </div>
            )}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>

    // </Box>
  );
}
