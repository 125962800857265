// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  // Box,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function GeneralFaqList({ faqs }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  console.log(faqs);
  return (
    <>
      {faqs?.pages?.map((page) =>
        page?.data?.map((accordion, index) => (
          <Accordion
            key={index}
            disableGutters
            elevation={0}
            sx={{
              backgroundColor: "transparent",
              marginTop: { xs: 1, md: 2 },
              paddingY: 1,
              boxShadow: "none",
              borderBottom: 1,
              borderColor: "white",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<AddIcon sx={{ fontSize: 20 }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(45deg)",
                },
              }}
            >
              <Typography
                fontFamily={"Space Grotesk"}
                sx={{
                  fontWeight: 400,
                  fontSize: isSmall ? "14px" : 16,
                  // marginTop: 0.3,
                }}
                variant="subtitle1"
              >
                {accordion?.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                fontFamily={"Space Grotesk"}
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: 2,
                }}
              >
                {accordion?.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </>
  );
}
