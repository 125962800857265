import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const UsdcWalletCard = ({ data, disabled }) => {
  const navigate = useNavigate();
  const { userBalance } = useContext(AuthContext);
  return (
    <Box width={"100%"} height={"180px"} position={"relative"}>
      <img
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          objectFit: "cover",
          borderRadius: "20px",
        }}
        src="/Images/usdc-card.png"
        alt=""
      />
      <Stack
        sx={{
          position: "absolute",
          top: 5,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingX: 3,
          marginTop: 3,
        }}
      >
        <Typography variant="h5" fontWeight={700}>
          USDC
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{
                background: "#552605",
                width: 40,
                height: 40,
                padding: 1,
                display: disabled ? "none" : "block",
                borderRadius: "12px",
                "&:hover": {
                  background: "#6e3a15",
                },
              }}
              onClick={() => navigate("/withdraw")}
            >
              <CallMadeIcon />
            </IconButton>
            <Typography variant="caption" display={disabled ? "none" : "block"}>
              Withdraw
            </Typography>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{
                background: "#FFFFFF",
                width: 44,
                height: 44,
                padding: 1,
                display: disabled ? "none" : "block",
                borderRadius: "12px",
                "&:hover": {
                  background: "#6e3a15",
                },
              }}
              onClick={() => navigate("/withdraw")}
            >
              <CallMadeIcon
                sx={{
                  color: "#552605",
                  stroke: "#552605",
                  strokeWidth: 1,
                }}
                fontSize="medium"
              />
            </IconButton>
            <Typography
              variant="caption"
              display={disabled ? "none" : "block"}
              fontWeight={700}
            >
              Withdraw
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{
                background: "#FFFFFF",
                width: 44,
                height: 44,
                padding: 1,
                display: disabled ? "none" : "block",
                borderRadius: "12px",
                "&:hover": {
                  background: "#6e3a15",
                },
              }}
              onClick={() => navigate("/deposit/usdc")}
            >
              <CallReceivedIcon
                sx={{
                  color: "#552605",
                  stroke: "#552605",
                  strokeWidth: 1,
                }}
                fontSize="medium"
              />
            </IconButton>
            <Typography
              variant="caption"
              display={disabled ? "none" : "block"}
              fontWeight={700}
            >
              Deposit
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          bottom: 40,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "space-evenly",
          paddingX: 3,
          marginTop: 3,
        }}
        gap={1}
      >
        <img
          style={{
            width: "40px",
            height: "40px",
          }}
          src="/Images/usdc-logo-1.png"
          alt=""
        />
        <Typography variant="h5" fontWeight={700}>
          {/* {data?.customToken ? data?.customToken?.toFixed(2) : 0} */}
          {userBalance
            ? // parseFloat(userBalance)?.toFixed(2)
              champsCovertDecimals(userBalance, commonData?.tokenDecimal)
            : "0"}
        </Typography>
        <Typography variant="caption" fontWeight={700} color={"#E2E4E9"}>
          USDC
        </Typography>
      </Stack>
    </Box>
  );
};

export default UsdcWalletCard;
