import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import { getUsersWithProfile } from "../Services/Service";
import { useQuery } from "react-query";

const AvatarGroups = () => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchUsers = async () => {
    try {
      await delay(400);
      const response = await getUsersWithProfile();
      console.log(response.data);
      return response.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["usersWithProfile"],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
  });

  console.log(data, "USERAWP");

  if (isLoading) return <>Loading...</>;

  return (
    <>
      <AvatarGroup max={100}>
        {data?.map((user) => (
          <Avatar
            key={user?._id}
            sx={{
              width: 50,
              height: 50,
              // border: "1px solid white",
              // padding: 0.3,
            }}
            alt="Remy Sharp"
            src={user?.profilePic}
          />
        ))}
      </AvatarGroup>
    </>
  );
};

export default AvatarGroups;
