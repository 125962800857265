import { useQuery } from "react-query";
import { getJoinedGameCount } from "../Services/Service";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const fetchUserJoinedGameCount = async () => {
  const userId = sessionStorage.getItem("userId");
  try {
    await delay(800);
    const response = await getJoinedGameCount({ userId });
    console.log(response.data);
    return response.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const useJoinedGameCount = () =>
  useQuery(
    { queryKey: ["getJoinedGameCount"], queryFn: fetchUserJoinedGameCount },
    {
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      cacheTime: 0,
    }
  );
