import { useTheme } from "@emotion/react";
import { IconButton, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const NotFound = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  return (
    <Stack
      style={{
        backgroundColor: "black",
        width: "100vw",
        height: "100vh",
        objectFit: "contain",
        position: "relative",
      }}
    >
      <IconButton
        aria-label="delete"
        color="secondary"
        onClick={() => navigate(-1)}
        sx={{ position: "absolute", top: 25, left: 25 }}
      >
        <ArrowBackIcon color="#fff" fontSize="large" />
      </IconButton>
      <img
        src="/Images/404.jpg"
        alt="404"
        style={{
          width: "100vw",
          height: mobile ? "500px" : "100vh",
        }}
      />
      {/* <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "150px",
          paddingY: "10px",
          color: "white",
          fontWeight: 900,
          textTransform: "none",
          position: "absolute",
          bottom: 2,
          borderRadius: "10px",
          background: " #bb3456",

          zIndex: 99,
          "&:hover": {
            backgroundColor: "  #d52c45",
          },
        }}
        variant="contained"
        //   onClick={() => navigate("/signup")}
      >
        Go Back
      </Button> */}
    </Stack>
  );
};

export default NotFound;
