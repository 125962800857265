import { useContext, useState } from "react";
// next
// @mui
import { Box, Divider, Typography, Stack, MenuItem } from "@mui/material";
import { FiSettings } from "react-icons/fi";
import MenuPopover from "./MenuPopover";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/home",
  },
  {
    label: "Edit Profile",
    linkTo: "/wallet/edit-profile",
  },
  //   {
  //     label: "Settings",
  //     linkTo: "/settings",
  //   },
];

// ----------------------------------------------------------------------

export default function SettingsPopover({ handleOpenDialog }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { user } = useContext(AuthContext);

  return (
    <>
      <Box
        width={45}
        height={45}
        sx={{
          background: "#064865",
          borderRadius: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          "&:hover": {
            background: "#11B1F4",
          },
        }}
        onClick={handleOpen}
      >
        {" "}
        <FiSettings size={22} color="#D0D4DC" />
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          background: "#011F2D",
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.username}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <NavLink
              style={{
                textDecoration: "none",
                color: "white",
              }}
              key={option.label}
              to={option.linkTo}
              passHref
            >
              <MenuItem key={option.label} onClick={handleClose}>
                {option.label}
              </MenuItem>
            </NavLink>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenDialog();
          }}
          sx={{ m: 1 }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
