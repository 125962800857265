import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const HiddenButton = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  right: -8px;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  background: #022737;
  padding: 10px;
  border-radius: 16px;
  border: 2px solid #11b1f4;
  min-width: 250px;
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover ${HiddenButton} {
    display: block;
  }
`;

const LandingHeaderCryptoCard = ({ data }) => {
  console.log(data);
  return (
    <>
      <Container>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <img
            src={data?.image}
            style={{ width: "35px", height: "35px" }}
            alt=""
          />
          <Box>
            <Typography
              lineHeight={1}
              sx={{ fontWeight: 600, fontSize: 14, marginTop: 0.8 }}
            >
              {data.symbol}
            </Typography>
            <Typography
              variant="caption"
              fontSize={12}
              lineHeight={0}
              color={"gray"}
            >
              {data?.coinId}
            </Typography>
          </Box>
        </Box>

        {/* <img src={data.image} style={{ width: "50px" }} alt="" /> */}

        <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
          <Typography fontSize={14}>${data.price.toFixed(5)}</Typography>
          <Typography sx={{ color: "#AAAAAA", fontSize: 12 }}>
            {data.price_change_percentage_24h > 0
              ? `+${data.price_change_percentage_24h?.toFixed(2)}%`
              : `${data.price_change_percentage_24h?.toFixed(2)}%`}{" "}
            / 24hr
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default LandingHeaderCryptoCard;
