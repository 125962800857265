import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const ChampsWalletCard = ({ disabled }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  return (
    <>
      {" "}
      <Box width={"100%"} height={"180px"} position={"relative"}>
        <img
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            objectFit: "cover",
            borderRadius: "20px",
          }}
          src="/Images/mybalance-card.png"
          alt=""
        />
        <Stack
          sx={{
            position: "absolute",
            top: 5,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 3,
            marginTop: 3,
          }}
        >
          <Typography variant="h5" fontWeight={700}>
            CHAMPS
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  background: "#FFFFFF",
                  width: 44,
                  height: 44,
                  padding: 1,
                  display: disabled ? "none" : "block",
                  borderRadius: "12px",
                  "&:hover": {
                    background: "#053F58",
                  },
                }}
                onClick={() => navigate("/champs-withdraw")}
              >
                <CallMadeIcon
                  sx={{
                    color: "#0A74A0",
                    stroke: "#0A74A0",
                    strokeWidth: 1,
                  }}
                  fontSize="medium"
                />
              </IconButton>
              <Typography
                variant="caption"
                display={disabled ? "none" : "block"}
                fontWeight={700}
              >
                Withdraw
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Stack
          sx={{
            position: "absolute",
            bottom: 40,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-evenly",
            paddingX: 3,
            marginTop: 3,
          }}
          gap={1}
        >
          <img
            style={{
              width: "60px",
              height: "55px",
            }}
            src="/Images/chmp-white-logo.png"
            alt=""
          />
          <Typography variant="h5" fontWeight={700}>
            {/* {parseFloat(user?.champsToken ? user?.champsToken?.toFixed(2) : 0)} */}
            {champsCovertDecimals(user?.champsToken, commonData?.tokenDecimal)}
          </Typography>
          <Typography variant="caption" fontWeight={700} color={"#E2E4E9"}>
            CHAMPS
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default ChampsWalletCard;
