import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BackButton from "../../Components/BackButton";
import "../../Styles/register.css";
import RegisterSidebar from "../../Components/Sidebar/RegisterSidebar";
import { useNavigate } from "react-router-dom";
import { auth, googleAuth } from "../../Utils/firebaseConfig";
import {
  checkUserExists,
  createCustomToken,
  updateFirebaseUid,
  updateMagicWalletAddress,
} from "../../Services/Service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  RecaptchaVerifier,
  getIdToken,
  signInWithCustomToken,
  signInWithPhoneNumber,
} from "firebase/auth";
import ButtonLoader from "../../Components/Loaders/ButtonLoader";
import { useTheme } from "@emotion/react";

import AvatarGroups from "../../Components/AvatarGroup";
import { AuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { magicLinkLogin, makeMagic } from "../../lib/magic";

const LoginWithPhone = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const { setPhoneResult } = useContext(AuthContext);

  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const isxSmall = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setIsMetamaskInstalled(!!window.ethereum);
  }, []);

  const handleGoogleLogin = async () => {
    const googleData = await googleAuth();
    if (googleData) {
      sessionStorage.setItem("token", googleData?.user?.accessToken);
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          const checkUserResult = await checkUserExists();
          if (checkUserResult.data.success) {
            await magicLinkLogin(googleData, checkUserResult);

            sessionStorage.setItem("userId", checkUserResult?.data.data._id);
            sessionStorage.setItem(
              "userName",
              checkUserResult?.data.data.username
            );
            setLoading(false);
            navigate("/home");
          }
        } catch (error) {
          setLoading(false);
          await updateFirebaseUid({
            loginType: "Email",
            email: googleData?.user?.email,
          });
          toast.error(
            "No account found. Want to join? Hit the 'Sign Up' button! "
          );
        }
      } else {
        toast.error("No account linked to the google account found");
      }
    }
  };

  const handlePhoneLogin = async (event) => {
    event.preventDefault();
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      } else {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      }

      let recaptchaVerifier = window.recaptchaVerifier;
      setPhoneResult(phone);
      const formatPh = "+" + phone;

      let confirmation = await signInWithPhoneNumber(
        auth,
        formatPh,
        recaptchaVerifier
      );

      if (confirmation) {
        window.confirmationResult = confirmation;
        // setnumberState("otp");
        toast.success("OTP sent successfully");
        navigate("/login-verification");
      }
    } catch (error) {
      console.log(error.message, "ERROR");
    }
  };

  const handleMetamaskLogin = async () => {
    try {
      if (!isMetamaskInstalled) {
        return toast.error("Metamask is not installed.");
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const singner = provider.getSigner();
      const address = await singner.getAddress();
      console.log(address);
      const customToken = await createCustomToken(address);
      const accessToken = customToken.data?.data;
      const acc_token = await signInWithCustomToken(auth, accessToken);
      sessionStorage.setItem("token", acc_token.user.accessToken);
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          setLoading(true);
          const checkUserResult = await checkUserExists();
          if (checkUserResult.data.success) {
            sessionStorage.setItem("userId", checkUserResult?.data?.data?._id);
            sessionStorage.setItem(
              "userName",
              checkUserResult?.data.data.username
            );
            setLoading(false);
            navigate("/home");
          }
        } catch (error) {
          setLoading(false);
          // setError(
          //   "No account found. Want to join? Hit the 'Sign Up' button! "
          // );
          return toast.error(
            "No account found. Want to join? Hit the 'Sign Up' button!"
          );
        }
      } else {
        toast.error("No account linked to the metamask account found");
        // toast.error("No account linked to the metamask account found");
      }
    } catch (error) {
      console.log(error);
      // toast.error("No account found. Want to join? Hit the 'Sign Up' button!");
    }
  };

  console.log(phone);
  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#03364c",
      }}
    >
      <Grid container>
        <RegisterSidebar src={"/Images/signup-im.png"} />
        {loading ? <ButtonLoader open={loading ? true : false} /> : ""}

        <Grid
          item
          xs={12}
          lg={6}
          sx={{ paddingY: 10 }}
          className="register-right-side"
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={5}
            marginBottom={10}
            padding={isSmall && 1}
          >
            <BackButton />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isSmall && "center",
                // alignItems: "center",
                gap: "40px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isxSmall ? "column" : "row",
                    alignItems: isxSmall ? "start" : "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Hi,
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Welcome Back
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Ready to be a{" "}
                    <span style={{ color: "#10ADEF", fontWeight: "700" }}>
                      Champ
                    </span>{" "}
                    ?
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      // fontWeight: 300,
                      marginTop: 2,
                      marginBottom: 2,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Please enter your Mobile Number for continue!
                  </Typography>
                </Box>
              </Box>

              <Stack
                direction={isSmall ? "column" : "row"}
                justifyContent={"space-between"}
                marginRight={isSmall ? 0 : 10}
                sx={{ maxWidth: "600px", width: "100%" }}
                spacing={3}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={isSmall && "center"}
                  justifyContent={"center"}
                  gap={4}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        // fontWeight: 300,
                        marginBottom: 1,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Your phone number
                    </Typography>

                    <PhoneInput
                      country={"us"}
                      inputProps={{
                        name: "phone",
                        required: true,
                        // autoFocus: true,
                      }}
                      fullWidth="true"
                      inputStyle={{
                        border: "none",
                        paddingLeft: "60px",
                        width: isxSmall ? "200px" : "330px",
                        background: "#011F2D",
                        color: "white",
                        padding: "30px",
                        fontSize: "16px",
                        marginLeft: "70px",
                        borderRadius: "8px",
                      }}
                      buttonStyle={{
                        background: "#011F2D",
                        border: "none",
                        padding: "10px",
                        borderRadius: "8px",
                      }}
                      containerStyle={{
                        background: "#03364C",
                      }}
                      disableDropdown
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                  </Box>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: isxSmall ? "270px" : "400px",
                      paddingY: "10px",
                      color: "#fffff",
                      fontWeight: 900,
                      textTransform: "none",
                      borderRadius: "8px",
                      background: "#11B1F4",
                      "&:hover": {
                        backgroundColor: " #10ADEF95",
                      },
                    }}
                    variant="contained"
                    onClick={handlePhoneLogin}
                  >
                    Verify
                  </Button>
                </Box>

                <Box display={!isSmall && "flex"}>
                  <Divider
                    orientation={isSmall ? "horizontal" : "vertical"}
                    sx={{
                      backgroundColor: " #11B1F4",
                      borderRightWidth: 1,
                      height: !isSmall && "255px",
                    }}
                  />

                  <Box
                    sx={{
                      marginLeft: 2,
                      display: "flex",
                      flexDirection: isSmall ? "row" : "column",
                      gap: isxSmall ? 0.5 : 2,
                      alignItems: "center",
                      justifyContent: isSmall ? "start" : "center",
                      marginTop: isSmall ? 2 : 0,
                      height: !isSmall && "250px",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color={"white"}
                      fontSize={isSmall ? 12 : 14}
                    >
                      Or continue with
                    </Typography>

                    <Box
                      width={50}
                      height={50}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      onClick={handleGoogleLogin}
                      sx={{
                        background: "#011F2D",
                        cursor: "pointer",
                        "&:hover": {
                          border: 1,
                          borderColor: "#11B1F4",
                          background: "none",
                        },
                      }}
                    >
                      <img
                        style={{ width: "45px" }}
                        src="/Images/login-g.png"
                        alt="login"
                      />
                    </Box>

                    <Box
                      width={50}
                      height={50}
                      bgcolor={"#011F2D"}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        border: 1,
                        borderColor: "#11B1F4",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ width: "20px" }}
                        src="/Images/login-p.png"
                        alt="login"
                      />
                    </Box>
                    {/* <Box
                      width={50}
                      height={50}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        background: "#011F2D",
                        cursor: "pointer",
                        "&:hover": {
                          border: 1,
                          borderColor: "#11B1F4",
                          background: "none",
                        },
                      }}
                      onClick={handleMetamaskLogin}
                    >
                      <img
                        style={{ width: "28px" }}
                        src="/Images/login-m.png"
                        alt="login"
                      />
                    </Box> */}
                  </Box>
                </Box>
              </Stack>

              <div
                id="recaptcha-container"
                stlye={{ position: "absolute", zIndex: "999" }}
              ></div>

              {/* {numberState === "verify" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <PhoneInput
                    country={"us"}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    disableDropdown
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />

                  <Button
                    variant="contained"
                    sx={{ textTransform: "none", marginTop: "20px" }}
                    size="small"
                    onClick={handlePhoneLogin}
                    id="sign-in-button"
                  >
                    Verify
                  </Button>
                </div>
              ) : (
                ""
              )}
              {numberState === "otp" ? (
                <>
                  <h5 style={{ margin: 0 }}>Enter OTP received</h5>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{ width: "35px", height: "25px" }}
                  />
                  <Button variant="contained" onClick={handleOTPverify}>
                    check OTP
                  </Button>
                </>
              ) : (
                ""
              )} */}

              {/* <Box position={"relative"} sx={{}}> */}
              <Box
                // sx={{ position: "absolute", left: 0 }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={isSmall && "start"}
                flexDirection={isxSmall ? "column" : "row"}
                gap={1}
              >
                <AvatarGroups />

                <Box display={"flex"} flexDirection={"column"} marginLeft={3}>
                  {" "}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      // fontWeight: 300,
                      fontFamily: "Montserrat",
                      marginBottom: 1,
                    }}
                  >
                    Don’t have an account?{" "}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => navigate("/signup")}
                      sx={{ color: "white" }}
                    >
                      Create Account
                    </Link>
                  </Typography>
                  <Typography variant="caption" color={"white"}>
                    100+ Users joined last Month
                  </Typography>
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginWithPhone;

// //////////////////////////////////////////////////////////

// import React, { useState } from "react";
// import { Box, Button, Grid } from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import "../../Styles/register.css";
// import RegisterSidebar from "../../Components/Sidebar/RegisterSidebar";
// import { useNavigate } from "react-router-dom";
// import { auth, googleAuth } from "../../Utils/firebaseConfig";
// import { checkUserExists } from "../../Services/Service";
// import OTPInput from "react-otp-input";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import ButtonLoader from "../../Components/Loaders/ButtonLoader";

// const Login = () => {
//   const navigate = useNavigate();
//   const [numberState, setnumberState] = useState("initial");
//   const [phone, setPhone] = useState("");
//   const [otp, setOtp] = useState("");
//   const [phoneresult, setPhoneResult] = useState(null);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
//   const handleGoogleLogin = async () => {
//     const googleData = await googleAuth();
//     if (googleData) {
//       sessionStorage.setItem("token", googleData?.user?.accessToken);
//       const token = sessionStorage.getItem("token");
//       if (token) {
//         try {
//           setLoading(true);
//           const checkUserResult = await checkUserExists();
//           if (checkUserResult.data.success) {
//             sessionStorage.setItem("userId", checkUserResult?.data.data._id);
//             sessionStorage.setItem(
//               "userName",
//               checkUserResult?.data.data.username
//             );
//             setLoading(false);
//             navigate("/biggestwinner");
//           }
//         } catch (error) {
//           setLoading(false);
//           setError(
//             "No account found. Want to join? Hit the 'Sign Up' button! "
//           );
//         }
//       } else {
//         setError("No account linked to the google account found");
//       }
//     }
//   };

//   const handlePhoneLogin = async (event) => {
//     event.preventDefault();
//     try {
//       if (!window.recaptchaVerifier) {
//         window.recaptchaVerifier = new RecaptchaVerifier(
//           auth,

//           "recaptcha-container",
//           {
//             size: "invisible",
//           }
//         );
//       } else {
//         window.recaptchaVerifier = new RecaptchaVerifier(
//           auth,

//           "recaptcha-container",
//           {
//             size: "invisible",
//           }
//         );
//       }

//       let recaptchaVerifier = window.recaptchaVerifier;

//       const formatPh = "+" + phone;

//       let confirmation = await signInWithPhoneNumber(
//         auth,
//         formatPh,
//         recaptchaVerifier
//       );

//       if (confirmation) {
//         setPhoneResult(confirmation);
//         setnumberState("otp");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleOTPverify = () => {
//     try {
//       phoneresult
//         .confirm(otp)
//         .then(async (result) => {
//           sessionStorage.setItem("token", result.user.accessToken);
//           const token = sessionStorage.getItem("token");
//           if (token) {
//             try {
//               setLoading(true);
//               const checkUserResult = await checkUserExists();
//               if (checkUserResult.data.success) {
//                 sessionStorage.setItem(
//                   "userId",
//                   checkUserResult?.data.data._id
//                 );
//                 sessionStorage.setItem(
//                   "userName",
//                   checkUserResult?.data.data.username
//                 );
//                 navigate("/biggestwinner");
//                 setLoading(false);
//               }
//             } catch (error) {
//               setLoading(false);
//               setError(
//                 "No account found. Want to join? Hit the 'Sign Up' button! "
//               );
//             }
//           } else {
//             setError("No account linked to the phone number found");
//           }
//           // if(checkUserResult){

//           //   navigate("/biggestWinner")
//           // }else{
//           //   console.log("login error")
//           // }

//           // setSignupDetails(true);
//           // ...
//         })
//         .catch((error) => {
//           // User couldn't sign in (bad verification code?)
//           console.log(error);
//           // window.location.reload()
//           // ...
//         });
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return (
//     <div style={{ height: "100vh", overflow: "hidden" }}>
//       <Grid container>
//         <RegisterSidebar />
//         {loading ? <ButtonLoader open={loading ? true : false} /> : ""}

//         <Grid item xs={12} lg={6} className="register-right-side">
//           <Box>
//             <Box className="rowContainer">
//               <Button
//                 startIcon={<ArrowBackIosIcon />}
//                 sx={{ textTransform: "none", color: "white" }}
//                 onClick={() => navigate(-1)}
//               >
//                 Back
//               </Button>
//               <h4>
//                 Create an account !
//                 <Button
//                   sx={{ textTransform: "none" }}
//                   onClick={() => navigate("/signup")}
//                 >
//                   Sign Up
//                 </Button>
//               </h4>
//             </Box>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 gap: "40px",
//               }}
//             >
//               <h1 style={{ textAlign: "center" }}>Login</h1>
//               {error ? (
//                 <p style={{ margin: 0, color: "red" }}>
//                   {error}
//                   <span
//                     style={{ color: "blue", cursor: "pointer" }}
//                     onClick={() => navigate("/signup")}
//                   >
//                     Sign up
//                   </span>
//                 </p>
//               ) : (
//                 ""
//               )}
//               <Button
//                 variant="contained"
//                 size="large"
//                 sx={buttonStyle}
//                 startIcon={<GoogleIcon />}
//                 onClick={handleGoogleLogin}
//               >
//                 Sign In with Google Account
//               </Button>

//               <Button
//                 variant="contained"
//                 size="large"
//                 sx={buttonStyle}
//                 onClick={() => setnumberState("verify")}
//               >
//                 Sign In with Phone Number
//               </Button>
//               <div
//                 id="recaptcha-container"
//                 stlye={{ position: "absolute", zIndex: "999" }}
//               ></div>
//               {numberState === "verify" ? (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <PhoneInput
//                     country={"us"}
//                     inputProps={{
//                       name: "phone",
//                       required: true,
//                       autoFocus: true,
//                     }}
//                     disableDropdown
//                     value={phone}
//                     onChange={(phone) => setPhone(phone)}
//                   />

//                   <Button
//                     variant="contained"
//                     sx={{ textTransform: "none", marginTop: "20px" }}
//                     size="small"
//                     onClick={handlePhoneLogin}
//                     id="sign-in-button"
//                   >
//                     Verify
//                   </Button>
//                 </div>
//               ) : (
//                 ""
//               )}
//               {numberState === "otp" ? (
//                 <>
//                   <h5 style={{ margin: 0 }}>Enter OTP received</h5>
//                   <OTPInput
//                     value={otp}
//                     onChange={setOtp}
//                     numInputs={6}
//                     renderSeparator={<span>-</span>}
//                     renderInput={(props) => <input {...props} />}
//                     inputStyle={{ width: "35px", height: "25px" }}
//                   />
//                   <Button variant="contained" onClick={handleOTPverify}>
//                     check OTP
//                   </Button>
//                 </>
//               ) : (
//                 ""
//               )}
//               <div id="or">Or</div>
//               <Button
//                 variant="contained"
//                 size="large"
//                 sx={{
//                   ...buttonStyle,
//                   backgroundImage: "linear-gradient(to right, #893BE6,#F19065)",
//                 }}
//                 endIcon={<ArrowForwardIcon />}
//                 onClick={() => navigate("/signup")}
//               >
//                 Sign Up
//               </Button>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };
// const buttonStyle = {
//   maxWidth: "300px",
//   width: "280px",
//   borderRadius: "20px",
//   textTransform: "none",
//   backgroundImage: "linear-gradient(to right, #F9AF3A,#DE5F8F)",
//   color: "white",
// };

// export default Login;
