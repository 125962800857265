import { Grid } from "@mui/material";
import React, { Suspense, useContext } from "react";
import MyProfile from "../../Components/wallet/MyProfile";
import StatsChart from "../../Components/wallet/StatsChart";
import RecentTransactions from "../../Components/wallet/RecentTransactions";
import { AuthContext } from "../../context/AuthContext";
// import WETHCard from "../../Components/wallet/WETHCard";
// import UsdcWalletCard from "../../Components/wallet/UsdcWalletCard";
// import RewardCard from "./CliamReward";

const ChampsWalletCard = React.lazy(() =>
  import("../../Components/wallet/ChampsWalletCard")
);

// const UsdcWalletCardSmall = React.lazy(() =>
//   import("../../Components/wallet/UsdcWalletCardSmall")
// );

const UsdcWalletCard = React.lazy(() =>
  import("../../Components/wallet/UsdcWalletCard")
);

const Wallet = () => {
  const { user } = useContext(AuthContext);

  console.log(user, "user/user");

  return (
    <>
      <Grid container display={"flex"} spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Suspense fallback={<>Loading...</>}>
                <ChampsWalletCard />
              </Suspense>
            </Grid>

            <Grid item xs={12} md={6}>
              <Suspense fallback={<>Loading...</>}>
                <UsdcWalletCard disabled={false} />
              </Suspense>
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <Suspense fallback={<>Loading...</>}>
                <UsdcWalletCardSmall data={user} disabled={false} />
                <WETHCard data={user} disabled={false} />
              </Suspense>
            </Grid> */}
          </Grid>
          <Grid itm xs={12} marginTop={3}>
            <StatsChart />
          </Grid>
          <Grid item xs={12}>
            <RecentTransactions />
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <MyProfile />
        </Grid>
        {/* <Grid item xs={12} md={8}>
          <RewardCard />
        </Grid> */}
      </Grid>
    </>
  );
};

export default Wallet;
