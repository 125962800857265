import { IconButton } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackArrowButton = () => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <IconButton
        aria-label="delete"
        color="secondary"
        onClick={() => navigate(-1)}
        // sx={{ position: "absolute", top: 25, left: 25 }}
      >
        <ArrowBackIcon
          sx={{
            color: "#11b1f4",
          }}
          fontSize="large"
        />
      </IconButton>
    </>
  );
};

export default BackArrowButton;
