import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const AboutUsPage = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: { xs: 4, md: 8 },
          marginBottom: { xs: 6, md: 16 },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ md: "50px", sm: "30px", xs: "28px" }}
          fontFamily={"Space Grotesk"}
          fontWeight={500}
          lineHeight={1.2}
          sx={{ color: "#FFFFFF" }}
        >
          About Us
        </Typography>
      </Box>

      {/*  */}
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: "80%",
            paddingY: 6,
            marginTop: { xs: 6, md: 10 },
            display: "flex",
            flexDirection: "column",
            borderRadius: "7px",
            alignItems: "start",
            justifyContent: "center",
            paddingX: 2,
            marginX: "auto",
            boxShadow: "0 1px 5px gray", // Default shadow for the other sides
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "34px", sm: "30px", xs: "20px" }}
            textAlign={mobile && "center"}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            Founder
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 2 },
              fontSize: { xs: "12px", md: "18px" },
            }}
          >
            Founder is a seasoned technical business development leader, adept
            at orchestrating go-to-market strategies, successfully spearheaded
            initiatives for Fortune 500 companies, resulting in the generation
            of millions of dollars in revenue for their cutting-edge products.
          </Typography>
        </Box>
      </Box>

      {/*  */}
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: "80%",
            paddingY: 6,
            marginTop: { xs: 6, md: 10 },
            display: "flex",
            flexDirection: "column",
            borderRadius: "7px",
            alignItems: "start",
            justifyContent: "center",
            paddingX: 2,
            marginX: "auto",
            boxShadow: "0 1px 5px gray", // Default shadow for the other sides
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "34px", sm: "30px", xs: "20px" }}
            textAlign={mobile && "center"}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            CMO
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 2 },
              fontSize: { xs: "12px", md: "18px" },
            }}
          >
            With over a decade of expertise in successful marketing, the Chief
            Marketing Officer has played a pivotal role in leading various
            crypto projects, contributing to the generation of more than 20
            million dollars in revenue.
          </Typography>
        </Box>
      </Box>

      {/*  */}

      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: "80%",
            paddingY: 6,
            marginTop: { xs: 6, md: 10 },
            display: "flex",
            flexDirection: "column",
            borderRadius: "7px",
            alignItems: "start",
            justifyContent: "center",
            paddingX: 2,
            marginX: "auto",
            boxShadow: "0 1px 5px gray", // Default shadow for the other sides
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "34px", sm: "30px", xs: "20px" }}
            textAlign={mobile && "center"}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            BDM
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 2 },
              fontSize: { xs: "12px", md: "18px" },
            }}
          >
            The Business Development Manager, with a proven track record, played
            a crucial role in steering Altura NFT to success and demonstrated
            exceptional skills in creating valuable networking opportunities
            with prominent brands in the industry.
          </Typography>
        </Box>
      </Box>

      {/*  */}

      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "row", xs: "column" },
          marginTop: { xs: 6, md: 10 },
          gap: 6,
          width: { md: "82%", xs: "88%" },
          alignItems: "center",
          justifyContent: "center",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            width: "85%",
            paddingY: 4,
            height: { sm: 300, md: 300 },
            boxShadow: "0 1px 5px gray",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: !mobile && "center",
            paddingX: mobile ? 2 : 6,
            justifyContent: mobile && "center",
          }}
        >
          {" "}
          <Typography
            variant="caption"
            fontSize={{ md: "34px", sm: "30px", xs: "20px" }}
            textAlign={mobile && "center"}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            Dev Team
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 1.5 },
              fontSize: { xs: "12px", md: "18px" },
              textAlign: "center",
            }}
          >
            The formidable full-stack development team, complemented by an
            expert frontend designer and a seasoned video developer,
            collectively boasts over a decade of invaluable experience,
            demonstrating their proficiency in delivering outstanding results
            across critical projects.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "85%",
            paddingY: 4,
            // paddingBottom: 12,
            height: { sm: 300, md: 300 },
            boxShadow: "0 1px 5px gray",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: !mobile && "center",
            paddingX: mobile ? 2 : 6,
            justifyContent: mobile && "center",
          }}
        >
          {" "}
          <Typography
            variant="caption"
            fontSize={{ md: "34px", sm: "30px", xs: "20px" }}
            textAlign={mobile && "center"}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            Advisors
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 1.5 },
              fontSize: { xs: "12px", md: "18px" },
              textAlign: "center",
            }}
          >
            The advisory team comprises seasoned crypto OGs with a wealth of
            experience, having played instrumental roles in the success of
            various crypto projects, including the notable Altura NFT.
          </Typography>
        </Box>
      </Box>

      {/*  */}
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: "80%",
            paddingY: 6,
            marginTop: { xs: 6, md: 10 },
            display: "flex",
            flexDirection: "column",
            borderRadius: "7px",
            alignItems: "start",
            justifyContent: "center",
            paddingX: 2,
            marginX: "auto",
            boxShadow: "0 1px 5px gray", // Default shadow for the other sides
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "34px", sm: "30px", xs: "20px" }}
            textAlign={mobile && "center"}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            Community Managers
          </Typography>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: { xs: 1.6, md: 2 },
              fontSize: { xs: "12px", md: "18px" },
            }}
          >
            Our community managers bring a wealth of experience from previous
            crypto projects, showcasing their expertise in fostering vibrant and
            engaged communities within the crypto space.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AboutUsPage;
