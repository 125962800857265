import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "@emotion/react";
import RegisterSidebar from "../Sidebar/RegisterSidebar";
import BackButton from "../BackButton";
import WelcomeText from "../Auth/WelcomeText";

const AuthLayout = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      {" "}
      <div
        style={{
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#0F103C",
        }}
      >
        <Grid container>
          <RegisterSidebar src={"/Images/login-im.png"} />
          {/* {loading ? <ButtonLoader open={loading ? true : false} /> : ""} */}

          <Grid
            item
            xs={12}
            lg={6}
            sx={{ paddingY: 10 }}
            className="register-right-side"
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={5}
              marginBottom={10}
              padding={isSmall && 1}
            >
              <BackButton />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isSmall && "center",
                  // alignItems: "center",
                  gap: "40px",
                }}
              >
                <Box>
                  <WelcomeText />
                  {/* </Box> */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      marginTop: 2,
                      marginBottom: 2,
                      fontFamily: "Poppins",
                    }}
                  >
                    Please select option below to continue.
                  </Typography>
                </Box>

                <Outlet />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AuthLayout;
