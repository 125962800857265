// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function FaqsList({ faqs }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {faqs.reverse().map((accordion, index) => (
        <Accordion
          key={index}
          disableGutters
          elevation={0}
          sx={{
            backgroundColor: "#03364C",
            // borderRadius: "20px",
            marginTop: 2,
            paddingY: 1,
            // paddingBottom: "1rem",
            boxShadow: "none",
            border: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <Box
                sx={{
                  padding: 0.1,
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddIcon sx={{ fontSize: 20 }} />
              </Box>
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              // borderRadius: "10px",
              // backgroundColor: "#03364C",

              // marginBottom: 2,
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(45deg)",
              },
            }}
          >
            <Typography
              sx={{
                color: "#10ADEF",
                fontWeight: 600,
                fontSize: isSmall ? 18 : 22,
              }}
            >
              {index + 1}
            </Typography>{" "}
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: isSmall ? 14 : 16,
                marginLeft: 2,
                marginTop: 0.3,
              }}
              variant="subtitle1"
            >
              {accordion?.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: 2,
              }}
            >
              {accordion?.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
