import {
  Avatar,
  Box,
  Card,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { useTheme } from "@emotion/react";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const TodayWinners = ({ todayWinners }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {" "}
      <Box
        height={"100%"}
        marginTop={3}
        sx={{
          width: isMobile ? "100%" : "300px",
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: alpha("#000000", 0.7),
            borderRadius: "25px",
            padding: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              marginTop: 1.5,
              marginBottom: 2,
            }}
          >
            Today Winners
          </Typography>
          {/*  */}

          {todayWinners?.length === 0 ||
          todayWinners === undefined ||
          todayWinners === null ? (
            <>
              <Typography textAlign={"center"} marginTop={4}>
                There are no winners.
              </Typography>
            </>
          ) : (
            <Swiper
              slidesPerView={3}
              spaceBetween={5}
              // freeMode={true}
              // slidesPerGroup={1}
              // centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[
                // FreeMode,

                Pagination,
                Autoplay,
              ]}
              className="mySwiper"
              style={{
                width: "100%",
                paddingBottom: "2.5rem",
              }}
            >
              {todayWinners?.map((user, i) => (
                <SwiperSlide
                  key={i}
                  style={{ height: "110px", width: "100px" }}
                >
                  <Box
                    sx={{
                      paddingX: 2,
                      display: "flex",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#04587B",
                      borderRadius: "12px",
                    }}
                  >
                    <Avatar
                      sx={{ width: 60, height: 60 }}
                      src={user?.userId?.profilePic}
                    />
                    <Typography
                      marginTop={1}
                      fontSize={10}
                      variant="caption"
                      noWrap
                    >
                      {/* {user?.prizeAmount?.toFixed(2)} USDC */}
                      {champsCovertDecimals(
                        user?.prizeAmount,
                        commonData?.tokenDecimal
                      )}{" "}
                      USDC
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Card>
      </Box>
    </>
  );
};

export default TodayWinners;
