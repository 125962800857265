import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { QRCodeSVG } from "qrcode.react";
import copy from "clipboard-copy";

import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 500,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReferralPopup = ({ open, setOpen, username, randomText }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showCopied, setShowCopied] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const baseUrl = window.location.origin; // Get the base URL
  const referralUrl = `${baseUrl}/signup?referral=${username}`;

  const handleCopyReferralLink = async () => {
    copy(referralUrl)
      .then(() => {
        console.log("Text copied to clipboard:", referralUrl);
        setShowCopied(true);
        setTimeout(() => {
          setShowCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          "& .MuiDialog-paper": {
            borderRadius: "25px",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
            padding: { md: 2, xs: 1, sm: 2 },
            width: isMobile ? "100%" : "400px",
            height: isMobile ? "290px" : "400px",
            position: "relative",
            borderRadius: "25px",
            gap: isMobile ? 2 : 4,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", sm: "32px", md: "32px" },
              fontWeight: "600",
              fontFamily: "Poppins",
              color: "#011f2d",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            Refer a Friend
          </Typography>

          {/* <img
            src="/Images/qrcode.png"
            alt=""
            style={{ width: "140px", height: "140px" }}
          /> */}
          <QRCodeSVG
            value={referralUrl}
            size={128}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
          />

          <OutlinedInput
            // fullWidth
            sx={{
              "& fieldset": { border: "none" },
              backgroundColor: "#011F2D",
              borderRadius: "8px",
              width: "100%",
              height: isMobile ? "40px" : "50px",
            }}
            inputProps={{
              style: {
                fontSize: isMobile ? 16 : 18,
              },
            }}
            name="referral"
            value={randomText}
            endAdornment={
              <InputAdornment position="end">
                <ClickAwayListener
                  sx={{
                    background: "#011F2D",
                  }}
                >
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={showCopied}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleCopyReferralLink}
                      edge="start"
                    >
                      <ContentCopyIcon
                        sx={{ color: "#11B1F4" }}
                        fontSize="small"
                      />
                    </IconButton>
                  </LightTooltip>
                </ClickAwayListener>
              </InputAdornment>
            }
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ReferralPopup;
