import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import FaqsList from "./FaqsList";
import { getAllFaqs } from "../../Services/Service";
import { useQuery } from "react-query";
import "./faq.css";
import Loader from "../../Components/Loader/Loader";
import { useTheme } from "@emotion/react";

const RootStyle = styled("div")(({ theme }) => ({
  //   paddingTop: 1,
  [theme.breakpoints.up("md")]: {
    // paddingTop: 3,
  },
}));

const Faq = () => {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchAllFaqs = async () => {
    try {
      await delay(400);
      const response = await getAllFaqs();
      const sortedData = response.data?.data?.data?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      return sortedData;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery(
    {
      queryKey: ["faqData"],
      queryFn: fetchAllFaqs,
    },
    {
      refetchOnMount: true,
      cacheTime: 0,
    }
  );

  console.log(data);
  return (
    <Box
      sx={{
        width: "100%",
        height: "min-content",
        backgroundColor: "#051016",
        margin: 0,
        top: 0,
        position: "absolute",
      }}
    >
      <RootStyle>
        <Container sx={{ mb: 10 }} className="faq-list">
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontWeight: 700,
              fontFamily: "Montserrat",
              marginY: 4,
              fontSize: isSmall ? 26 : 34,
            }}
          >
            Frequently Asked <span style={{ color: "#10ADEF" }}>Questions</span>{" "}
          </Typography>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <Loader />
            </Box>
          ) : (
            <>
              <Grid container spacing={10}>
                <Grid item xs={12} md={8}>
                  <FaqsList faqs={data} />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            </>
          )}
        </Container>
      </RootStyle>
    </Box>
  );
};

export default Faq;
