// import ProgressBar from "@ramonak/react-progress-bar";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import React from "react";
import "@progress/kendo-theme-default/dist/all.css";

const JoinedPlayersProgressBar = ({
  participantsLength,
  maxPlayer,
  minPlayer,
}) => {
  return (
    <>
      {" "}
      <ProgressBar
        // completed={participantsLength}
        // maxCompleted={maxPlayer}
        // customLabel={`${participantsLength}/${maxPlayer}`}
        // isLabelVisible={true}
        // width="140px"
        // height="25px"
        // labelSize="12px"
        // labelAlignment="left"
        // baseBgColor={participantsLength > minPlayer ? "#FFABAB" : "#89B673"}
        // bgColor={participantsLength > minPlayer ? "#F93E3E" : "#519432"}
        value={participantsLength}
        labelVisible={true}
        max={maxPlayer}
        min={0}
        style={{
          backgroundColor:
            participantsLength > maxPlayer / 2 ? "#FFABAB" : "#89B673",
          borderRadius: "30px",
          color: "white",
          height: "30px",
        }}
        progressStyle={{
          backgroundColor:
            participantsLength > maxPlayer / 2 ? "#F93E3E" : "#519432",
          borderRadius: "30px",
        }}
        label={(props) => {
          return <strong>{`${participantsLength}/${maxPlayer}`}</strong>;
        }}
        labelPlacement={"center"}
      />
    </>
  );
};

export default JoinedPlayersProgressBar;
