import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";

const HowToPlayStep = ({ image, step, content }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          gap: { xs: 2, sm: 3, md: 4 },
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="caption"
            fontSize={{ md: "40px", sm: "20px", xs: "16px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={2}
            sx={{ color: "#FFFFFF" }}
          >
            {step}
          </Typography>
          <Box
            sx={{
              width: "100%",
              marginY: 1,
              display: isSmallScreen ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={image}
              alt=""
              style={{
                width: "100%",
                height: "215px",
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography
            fontFamily={"Space Grotesk"}
            sx={{
              color: "#FFFFFF",
              lineHeight: 2,
              maxWidth: "700px",
              fontWeight: 400,
              marginTop: 1,
              fontSize: { md: "18px", xs: "12px" },
            }}
          >
            {content}
          </Typography>
        </Box>
        <img
          src={image}
          alt=""
          style={{
            width: isSmallScreen ? "216px" : "450px",
            height: isSmallScreen ? "215px" : "323px",
            marginBottom: 1,
            objectFit: "contain",
            display: isSmallScreen ? "none" : "block",
          }}
        />
      </Box>
    </>
  );
};

export default HowToPlayStep;
