import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  createBlockchainTransaction,
  getBlockChianConfig,
} from "../../Services/Service";
import DepositePaymentMethods from "./DepositPaymentMethods";
import DepositPopup from "../../Components/Popups/DepositPopup";
// import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import ConfirmDialogue from "../../Components/Modals/ConfirmDialogue";
import toast from "react-hot-toast";
import { ethers } from "ethers";

import USDCTokenABI from "../../Contract/USDC.json";
import Loader from "../../Components/Loader/Loader";
import NewAbi from "../../Contract/NewAbi.json";
import { AuthContext } from "../../context/AuthContext";
import { makeMagic } from "../../lib/magic";

const CARDS_OPTIONS = [
  { value: "ViSa1", label: "**** **** **** 1212 - Jimmy Holland" },
  { value: "ViSa2", label: "**** **** **** 2424 - Shawn Stokes" },
  { value: "MasterCard", label: "**** **** **** 4545 - Cole Armstrong" },
];

// const useStyles = makeStyles({
//   customNumberField: {
//     "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     '& input[type="number"]': {
//       "-moz-appearance": "textfield",
//     },
//   },
// });

const WETHDepositePage = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const ["WETH", setCurrency] = useState("");
  const [config, setConfig] = useState();
  const [contract, setContract] = useState();
  const [tokenAmount, setTokenAmount] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [wethToUsdcAmount, setWethToUsdcAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [wethAmount, setWethAmount] = useState();

  // const [convertionLoading, setConvertionLoading] = useState(false);

  const ARBITRUM_MAINNET_JSON_RPC_URL = "https://arbitrum.llamarpc.com";
  const CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET =
    "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
  const WETH_ARBITRUM_MAINNET = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  const USDCe_ARBITRUM_MAINNET = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
  // const path = [WETH_ARBITRUM_MAINNET, USDCe_ARBITRUM_MAINNET];

  const { user } = useContext(AuthContext);

  useEffect(() => {
    setWethAmount(
      user?.walletData?.find((item) => item._id?.paymentMethod === "WETH")
    );
  }, [user]);

  const PAYMENT_OPTIONS = [
    {
      value: "crypto_wallet",
      title: "Metamask Wallet",
      description: `Balance : ${
        wethAmount?.totalAmount ? wethAmount?.totalAmount : 0
      } WETH`,
      icon: "/Images/crypto-wallet.png",
    },
  ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data, isLoading } = useQuery(
    ["walletConfig"],
    async () => {
      try {
        await delay(200); // Simulate a delay of 2 seconds (2000 milliseconds)
        const result = await getBlockChianConfig();
        setContract(
          result.data?.paymentMethod?.find((item) => item.symbol === "WETH")
        );
        return result.data;
      } catch (error) {
        // seth2hLoading(false);
        console.log(error);
      }
    },
    {
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    setConfig(data);
  }, [data]);

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // const providerr = new ethers.providers.JsonRpcProvider(
  //   ARBITRUM_MAINNET_JSON_RPC_URL
  // );
  // const camelotRouter = new ethers.Contract(
  //   CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
  //   NewAbi,
  //   providerr
  // );

  // // const amount = ethers.utils.parseUnits(tokenAmount ? tokenAmount : "0", 18);
  // camelotRouter.functions.getAmountsOut(amount, path).then((amounts) => {
  //   const [wethIn, usdcOut] = amounts[0];
  //   setWethToUsdcAmount(ethers.utils.formatUnits(usdcOut, 6));
  // });

  // console.log(wethToUsdcAmount, "WUA");
  // console.log(
  //   ethers.utils.parseUnits(tokenAmount ? tokenAmount : "0").toString(),
  //   "PD"
  // );

  const convertWethToUSDC = async (amount) => {
    setLoading(true);
    try {
      const path = [WETH_ARBITRUM_MAINNET, USDCe_ARBITRUM_MAINNET];
      const provider = new ethers.providers.JsonRpcProvider(
        ARBITRUM_MAINNET_JSON_RPC_URL
      );
      const camelotRouter = new ethers.Contract(
        CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
        NewAbi,
        provider
      );

      const tokenAmountt = ethers.utils.parseUnits(amount ? amount : "0", 18);
      await camelotRouter.functions
        .getAmountsOut(tokenAmountt, path)
        .then((amounts) => {
          const [wethIn, usdcOut] = amounts[0];
          console.log(wethIn);
          console.log(
            "Amount of USDCe from WETH: ",
            ethers.utils.formatUnits(usdcOut, 6)
          );
          setWethToUsdcAmount(ethers.utils.formatUnits(usdcOut, 6));
        })
        .finally(() => setLoading(false))
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    convertWethToUSDC(tokenAmount);
  }, [tokenAmount]);

  console.log(wethToUsdcAmount, "wethToUsdcAmount");

  const handleTransfer = async (e) => {
    e.preventDefault();
    // if ("WETH" === "") return toast.error("Please choose a token!");
    setDialogOpen(false);
    const notification = toast.loading("Depositing your tokens...");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const usdcContract = new ethers.Contract(
      contract?.contractAddress,
      USDCTokenABI,
      signer
    );

    // if ("WETH" === "USDC") {
    //   try {
    //     const transaction = await usdcContract.transfer(
    //       config?.adminWallet,
    //       tokenAmount * 1e6,
    //       {
    //         gasLimit: 300000,
    //       }
    //     );
    //     await transaction.wait();
    //     console.log("Transaction hash:", transaction);
    //     if (transaction.hash) {
    //       const dataOne = {
    //         walletAddress: transaction.from,
    //         userId: sessionStorage.getItem("userId"),
    //         token: tokenAmount,
    //         transactionHash: transaction.hash,
    //         paymentMethod: "WETH",
    //         transactionType: "Deposit",
    //         exchangedToken: tokenAmount,
    //       };
    //       const res = await createBlockchainTransaction(dataOne);
    //       console.log(res);
    //       toast.success("Token deposited successfully!", { id: notification });
    //       setIsSuccessDialogOpen(true);
    //       return res;
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     toast.error("Whoops something went wrong!", {
    //       id: notification,
    //     });
    //   }
    // } else {
    try {
      const transaction = await usdcContract.transfer(
        config?.adminWallet,
        ethers.utils.parseUnits(tokenAmount ? tokenAmount : "0").toString(),
        {
          gasLimit: 300000,
        }
      );
      await transaction.wait();
      console.log("Transaction hash:", transaction);
      if (transaction.hash) {
        const dataOne = {
          walletAddress: transaction.from,
          userId: sessionStorage.getItem("userId"),
          token: (
            Number(wethToUsdcAmount) -
            Number(wethToUsdcAmount) * 0.02
          ).toString(),
          transactionHash: transaction.hash,
          paymentMethod: "WETH",
          transactionType: "Deposit",
          exchangedToken: tokenAmount,
        };
        console.log(dataOne);
        const res = await createBlockchainTransaction(dataOne);
        console.log(res);
        toast.success("Token deposited successfully!", { id: notification });
        setIsSuccessDialogOpen(true);
        return res;
      }
    } catch (e) {
      console.log(e);
      toast.error("Whoops something went wrong!", {
        id: notification,
      });
    }
    // }
    // }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DepositPopup
        open={isSuccessDialogOpen}
        setOpen={setIsSuccessDialogOpen}
        amount={tokenAmount}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            width={{ sm: "100%" }}
            sx={{
              overflow: "hidden",
              background: "#03364C",
              borderRadius: "27px",
              // padding: 4,
              // height: "100%",
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              // gap: 2,
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{ paddingX: 2 }}>
              Deposit
            </Typography>

            <Box padding={2}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Enter Amount
              </Typography>

              <Box
                display={"flex"}
                sx={{
                  background: "#011F2D",
                  borderRadius: "8px",
                  padding: isSmallScreen ? 0 : 1,
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  // className={classes.customNumberField}
                  value={tokenAmount}
                  onChange={handleTokenAmountChange}
                  placeholder="Enter token amount"
                  inputProps={{
                    style: {
                      color: "white",
                      width: "100%",
                      fontWeight: 600,
                      fontSize: isSmallScreen ? 12 : 16,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? 12 : 16,
                          }}
                        >
                          {contract?.symbol}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#011F2D",
                    borderRadius: "8px",
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                {/* <TextField
                  id="outlined-select-currency"
                  select
                  variant="outlined"
                  // label="Select Token"
                  inputProps={{
                    style: {
                      color: "white",
                      width: "300px",
                    },
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#011F2D",
                    borderRadius: "8px",
                    width: isSmallScreen ? "40%" : "30%",
                  }}
                  value={currecy}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                    setContract(
                      config?.paymentMethod?.find(
                        (item) => item.symbol === e.target.value
                      )
                    );
                  }}
                >
                  {config?.paymentMethod
                    ?.filter((item) => item?.isEnable === true)
                    .map((option) => (
                      <MenuItem key={option.symbol} value={option.symbol}>
                        {option.symbol}
                      </MenuItem>
                    ))}
                </TextField> */}
              </Box>
            </Box>

            <DepositePaymentMethods
              cardOptions={CARDS_OPTIONS}
              paymentOptions={PAYMENT_OPTIONS}
              isheaderShow={true}
            />

            <Box paddingX={2}>
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{ marginBottom: 2 }}
              >
                Deposit Summary
              </Typography>

              <Box
                sx={{
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  height: { md: 65, xs: 50 },
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    fontWeight: 500,
                    fontSize: isSmallScreen ? 14 : 22,
                  }}
                >
                  {tokenAmount ? tokenAmount : 0.0}
                  <Typography
                    variant="caption"
                    marginLeft={1}
                    sx={{
                      fontSize: isSmallScreen ? 8 : 12,
                    }}
                  >
                    ETH =
                  </Typography>
                </Typography>

                {loading ? (
                  <Typography marginLeft={1}>Loading...</Typography>
                ) : (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      fontWeight: 500,
                      fontSize: isSmallScreen ? 12 : 22,
                      marginLeft: 1,
                    }}
                  >
                    {wethToUsdcAmount
                      ? Number(wethToUsdcAmount).toFixed(2)
                      : 0.0}
                    <Typography variant="caption" marginLeft={1}>
                      USDC
                    </Typography>
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                background: "#011F2D",
                borderRadius: "19px",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginTop: 3,
                marginX: 2,
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Frequency
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  One Time
                </Typography>
              </Stack>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  $
                  {wethToUsdcAmount ? Number(wethToUsdcAmount).toFixed(2) : 0.0}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Transaction Charges
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  {config?.transactionCharge}%
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                  color={"white"}
                >
                  {wethToUsdcAmount
                    ? (
                        Number(wethToUsdcAmount) -
                        (Number(wethToUsdcAmount) * config?.transactionCharge) /
                          100
                      ).toFixed(2)
                    : 0.0}
                </Typography>
              </Stack>
            </Box>

            <Box paddingX={2} marginTop={4}>
              <Button
                sx={{
                  color: "white",
                  width: "100%",
                  paddingY: 2,
                  borderRadius: "8px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  textTransform: "none",
                  fontWeight: 600,
                }}
                onClick={() => handleDialogOpen()}
                disabled={!wethToUsdcAmount || !tokenAmount || loading}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "#03364C",
              borderRadius: "27px",
              padding: 4,
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              paddingBottom: 12,
              height: "100%",
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{}}>
              Deposit Summary
            </Typography>
  
            <Box
              sx={{
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                height: 65,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 500,
                  fontSize: 22,
                }}
              >
                155.00{" "}
                <Typography variant="caption" marginLeft={1}>
                  USDC =
                </Typography>
              </Typography>
  
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 500,
                  fontSize: 22,
                  marginLeft: 1,
                }}
              >
                3435.34839{" "}
                <Typography variant="caption" marginLeft={1}>
                  ETH
                </Typography>
              </Typography>
            </Box>
  
            <Box
              sx={{
                background: "#011F2D",
                borderRadius: "19px",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="caption" fontSize={14} fontWeight={600}>
                  Frequency
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={14}
                  fontWeight={400}
                  color={"gray"}
                >
                  One Time
                </Typography>
              </Stack>
  
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="caption" fontSize={14} fontWeight={600}>
                  Ammount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={14}
                  fontWeight={400}
                  color={"gray"}
                >
                  $155.00
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="caption" fontSize={14} fontWeight={600}>
                  Transaction Charges
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={14}
                  fontWeight={400}
                  color={"gray"}
                >
                  5%
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="caption" fontSize={14} fontWeight={600}>
                  Total Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={14}
                  fontWeight={600}
                  color={"white"}
                >
                  162.75
                </Typography>
              </Stack>
            </Box>
  
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Promo Code
              </Typography>
  
              <TextField
                type="text"
                fullWidth
                inputProps={{
                  style: {
                    color: "gray",
                    width: "300px",
                  },
                }}
                sx={{
                  "& fieldset": { border: "none" },
                  backgroundColor: "#011F2D",
                  borderRadius: "8px",
                  width: "100%",
                }}
              />
            </Box>
  
            <Button
              sx={{
                color: "white",
                width: "100%",
                paddingY: 2,
                borderRadius: "8px",
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                textTransform: "none",
                fontWeight: 600,
              }}
            >
              Confirm Payment
            </Button>
          </Box>
        </Grid> */}

        <ConfirmDialogue
          open={isDialogOpen}
          handleClose={handleDialogClose}
          title="Confirmation"
          content={`You want to deposit ${tokenAmount} WETH ?`}
          handleConfirm={handleTransfer}
        />
      </Grid>
    </>
  );
};

export default WETHDepositePage;
