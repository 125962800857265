import { createContext, useState } from "react";
import { useQuery } from "react-query";
import { getAllNotifications } from "../Services/Service";

export const NotificationContext = createContext("");

const NotificationProvider = (props) => {
  const userId = sessionStorage.getItem("userId");
  const [page, setPage] = useState(1);
  const limit = 10;
  const [count, setCount] = useState(0);
  const [isGrouped, setGrouped] = useState([]);
  const [unReadCount, setUnReadCount] = useState(0);

  const sortNotifications = (notifications) => {
    return notifications.sort((a, b) => {
      // Assuming createdAt is a string in ISO format
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateB - dateA; // Sort in descending order (newest first)
    });
  };

  const groupNotificationsByDate = (notifications) => {
    return notifications.reduce((grouped, notification) => {
      const date = new Date(notification.createdAt).toLocaleDateString();

      if (!grouped[date]) {
        grouped[date] = [];
      }

      grouped[date].push(notification);
      return grouped;
    }, {});
  };

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data, isLoading, refetch } = useQuery(
    ["notificationData", page, limit],
    async () => {
      try {
        // await delay(200);
        if (userId) {
          const notificationData = await getAllNotifications(
            userId,
            page,
            limit
          );
          setCount(notificationData?.data?.data?.totalCount);
          setUnReadCount(notificationData?.data?.data?.unReadCount);
          const sortedNotifications = sortNotifications(
            notificationData.data.data?.notification
          );
          const groupedNotifications =
            groupNotificationsByDate(sortedNotifications);
          setGrouped(groupedNotifications);
          return notificationData?.data?.data;
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    {
      // refetchOnMount: true,
      cacheTime: 0,
      // refetchOnReconnect: true,
      // refetchInterval: 10000,
      retry: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <NotificationContext.Provider
      value={{
        unReadCount,
        setUnReadCount,
        isGrouped,
        setPage,
        refetch,
        data,
        isLoading,
        page,
        limit,
        count,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
