import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import HowToPlayStep from "../../Components/Landing/HowToPlayStep";
import HowToPlayStepLeft from "../../Components/Landing/HowToPlayStepLeft";

const HowToPlayPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div
        style={{
          width: "300px",
          position: "absolute",
          // borderRadius: "50%",
          borderBottomRightRadius: "30%",
          overflow: "hidden",
          top: 120,
          display: isSmallScreen ? "none" : "block",
          background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
          filter: "blur(146px)",
          height: "208px",
          opacity: "0.6",
        }}
      />
      <Container
        sx={{
          marginBottom: { md: 12, xs: 4 },
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: { md: 8, xs: 2 },
            marginBottom: { md: 12, xs: 6 },
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={1.2}
            sx={{ color: "#FFFFFF" }}
          >
            How To Play
          </Typography>
        </Box>
        <div
          style={{
            width: "50%",
            position: "absolute",
            borderRadius: "50%",
            left: -20,
            top: 10,
            display: isSmallScreen ? "block" : "none",
            background: "linear-gradient(109.59deg, #11b1f4, #085a7d)",
            filter: "blur(106px)",
            height: "208px",
            opacity: "0.6",
          }}
        />

        <Box display={"flex"} flexDirection={"column"} gap={{ md: 6, xs: 2 }}>
          <HowToPlayStep
            image={"/Images/HTP/step1.png"}
            step={"Step 1"}
            content={`Step into the gaming universe through our sleek home page after logging in. Discover the triumphs and challenges of the biggest winners and losers, adding a dynamic touch to your gaming journey. Stay on top of the action with real-time updates on "Degan Time" and dive into your personalized adventure with the "My Games" section. The stage is set — let the games begin!`}
          />
          <HowToPlayStepLeft
            image={"/Images/HTP/step2.png"}
            step={"Step 2"}
            content={`Embark on your gaming adventure by selecting the biggest winner category. Take your pick from 4 to 8, unlocking a spectrum of possibilities. Navigate through upcoming, running, and finished matches, exploring the dynamic landscape of competition. Delve into the match details and, when the perfect opportunity arises, click the "Join" button to immerse yourself in the thrill of the game. Your journey toward victory begins with a choice — make it now and be a part of the excitement!!`}
          />
          <HowToPlayStep
            image={"/Images/HTP/step3.png"}
            step={"Step 3"}
            content={`Curate your winning strategy by handpicking a formidable team of 6 crypto players. Utilize the intuitive "+" icon to seamlessly add these digital assets to your roster. Witness the thrilling showdown as your chosen crypto warriors engage in battles against formidable opponents. The key to triumph lies in your strategic selections - should your crypto lineup emerge victorious, the sweet taste of success awaits. Dive into the excitement and craft your path to glory with a carefully chosen team of crypto players!`}
          />
          <HowToPlayStepLeft
            image={"/Images/HTP/step4.png"}
            step={"Step 4"}
            content={`Track your coin wins and losses in real-time through the "My Game Live Score" feature. Witness the performance of the coins you've selected as they unfold right before your eyes. Stay informed and engaged as the dynamics of your chosen coins play out, enhancing your gaming experience. Keep a close eye on the live score to gauge the success of your strategies and make informed decisions for a truly immersive gameplay.`}
          />
        </Box>
      </Container>
    </>
  );
};

export default HowToPlayPage;
