import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import RegisterSidebar from "../../Components/Sidebar/RegisterSidebar";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import OTPInput from "react-otp-input";
import AvatarGroups from "../../Components/AvatarGroup";
import { checkNumber } from "../../Services/Service";
import toast from "react-hot-toast";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../Utils/firebaseConfig";
import { makeMagic } from "../../lib/magic";
import { LoadingButton } from "@mui/lab";
// import { checkUserExists } from "../../Services/Service";

const SignupOTPVerifyPage = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const isxSmall = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [otp, setOtp] = useState("");
  // const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [loginType, setloginType] = useState("");

  const { state } = useLocation();
  const { phone } = state;

  console.log(phone, "PH");

  const handleOTPverify = () => {
    setVerifyLoading(true);
    try {
      window.confirmationResult
        .confirm(otp)
        .then(async (result) => {
          const uniqueNumber = await checkNumber(phone);
          console.log(uniqueNumber, "uniqueNumber");
          if (uniqueNumber.data) {
            setVerifyLoading(false);
            toast.error("Account already exists,Please login to continue");
          } else {
            await makeMagic.openid.loginWithOIDC({
              jwt: result?.user.accessToken,
              providerId: process.env.REACT_APP_MAGIC_PROVIDER_ID,
            });
            // console.log(uniqueNumber)
            console.log(result);
            // localStorage.setItem("token",result.user.accessToken)
            sessionStorage.setItem("token", result.user.accessToken);
            // setloginType("Phone");
            // setSignupDetails(true);
            setVerifyLoading(false);
            return navigate("/signup-details", {
              state: { loginType: "Phone", phone: phone },
            });
          }
          setVerifyLoading(false);
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          setVerifyLoading(false);
          console.log(error);
          toast.error(
            "Invalid OTP. Please double-check the code you entered and try again."
          );
          // ...
        });
    } catch (error) {
      setVerifyLoading(false);
      console.log(error);
      toast.error("Something went wrong");
    }
    setVerifyLoading(false);
  };

  const handleResendCode = async (event) => {
    event.preventDefault();
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      } else {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      }

      let recaptchaVerifier = window.recaptchaVerifier;
      const formatPh = "+" + phone;

      let confirmation = await signInWithPhoneNumber(
        auth,
        formatPh,
        recaptchaVerifier
      );

      if (confirmation) {
        window.confirmationResult = confirmation;
        toast.success("OTP sent successfully");
        // setnumberState("otp");
        // navigate("/login-verification");
      }
    } catch (error) {
      console.log(error.message, "ERROR");
      toast.error("Something went wrong");
    }
  };

  // const handleOTPverify = () => {
  //   try {
  //     window.confirmationResult
  //       .confirm(otp)
  //       .then(async (result) => {
  //         sessionStorage.setItem("token", result.user.accessToken);
  //         const token = sessionStorage.getItem("token");
  //         if (token) {
  //           try {
  //             setLoading(true);
  //             const checkUserResult = await checkUserExists();
  //             if (checkUserResult.data.success) {
  //               sessionStorage.setItem(
  //                 "userId",
  //                 checkUserResult?.data.data._id
  //               );
  //               sessionStorage.setItem(
  //                 "userName",
  //                 checkUserResult?.data.data.username
  //               );
  //               navigate("/home");
  //               setLoading(false);
  //             }
  //           } catch (error) {
  //             setLoading(false);
  //             setError(
  //               "No account found. Want to join? Hit the 'Sign Up' button! "
  //             );
  //           }
  //         } else {
  //           setError("No account linked to the phone number found");
  //         }
  //         // if(checkUserResult){

  //         //   navigate("/biggestWinner")
  //         // }else{
  //         //   console.log("login error")
  //         // }

  //         // setSignupDetails(true);
  //         // ...
  //       })
  //       .catch((error) => {
  //         // User couldn't sign in (bad verification code?)
  //         console.log(error);
  //         // window.location.reload()
  //         // ...
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#03364c",
      }}
    >
      <Grid container>
        <RegisterSidebar src={"/Images/verify-im.png"} />

        <Grid
          item
          xs={12}
          lg={6}
          sx={{ paddingY: 10 }}
          className="register-right-side"
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={5}
            marginBottom={10}
            padding={isSmall && 1}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                color: "white",
                border: 1,
                borderRadius: "10px",
                height: 50,
                width: "30px",
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon />
            </Button>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isSmall && "center",
                // alignItems: "center",
                gap: "40px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isxSmall ? "column" : "row",
                    alignItems: isxSmall ? "start" : "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Hi,
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Welcome Back
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      fontWeight: 300,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Ready to be a{" "}
                    <span style={{ color: "#10ADEF", fontWeight: "700" }}>
                      Champ
                    </span>{" "}
                    ?
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      // fontWeight: 300,
                      marginTop: 2,
                      marginBottom: 2,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Verify your Phone Number
                  </Typography>
                </Box>
              </Box>

              <Stack
                direction={isSmall ? "column" : "row"}
                justifyContent={"space-between"}
                marginRight={isSmall ? 0 : 10}
                sx={{ maxWidth: "600px", width: "100%" }}
                spacing={3}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={isSmall && "center"}
                  justifyContent={"center"}
                  gap={4}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        // fontWeight: 300,
                        marginBottom: 2,
                        fontFamily: "Montserrat",
                      }}
                    >
                      Enter your OTP code here
                    </Typography>

                    {/* <OTPInput
                        // value={otp}
                        // onChange={setOtp}
                        numInputs={6}
                        renderSeparator={
                          <span style={{ marginRight: "15px" }}>{""}</span>
                        }
                        renderInput={(props) => (
                          <input
                            style={{
                              border: "none",
                              backgroundColor: "#011F2D",
                              height: isxSmall ? "35px" : "50px",
                              width: isxSmall ? "35px" : "50px",
                              borderRadius: "8px",
                              color: "white",
                            }}
                            // {...props}
                          />
                        )}
                        inputStyle={{ width: "35px", height: "25px" }}
                      /> */}

                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span style={{ width: "8px" }} />}
                      renderInput={(props) => (
                        <input
                          {...props}
                          class="form-control"
                          style={{
                            background: "#011F2D",
                            width: mobile ? "20px" : "20px",
                            // height: mobile ? "35px" : "50px",
                            color: "white",
                            fontSize: "18px",
                            boxShadow: "none",
                            border: "none",
                            borderRadius: "8px",
                            padding: mobile ? "10px" : "20px",
                          }}
                        />
                      )}
                      shouldAutoFocus={false}
                    />
                    <Stack
                      direction={isxSmall ? "column" : "row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          // fontWeight: 300,
                          marginTop: 2,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Didn't you receive any code?
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#11B1F4",
                          // fontWeight: 300,
                          marginTop: 2,
                          fontFamily: "Montserrat",
                          cursor: "pointer",
                        }}
                        onClick={handleResendCode}
                      >
                        RESEND NEW CODE
                      </Typography>
                    </Stack>
                  </Box>
                  <LoadingButton
                    loading={verifyLoading}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: isxSmall ? "300px" : "400px",
                      paddingY: "10px",
                      color: "#fffff",
                      fontWeight: 900,
                      textTransform: "none",
                      borderRadius: "8px",
                      background: "#11B1F4",
                      "&:hover": {
                        backgroundColor: " #10ADEF95",
                      },
                    }}
                    variant="contained"
                    onClick={handleOTPverify}
                  >
                    Verify
                  </LoadingButton>
                </Box>

                <Box display={!isSmall && "flex"}>
                  <Divider
                    orientation={isSmall ? "horizontal" : "vertical"}
                    sx={{
                      backgroundColor: " #11B1F4",
                      borderRightWidth: 1,
                      height: !isSmall && "255px",
                    }}
                  />

                  <Box
                    sx={{
                      marginLeft: 2,
                      display: "flex",
                      flexDirection: isSmall ? "row" : "column",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: isSmall ? "start" : "center",
                      marginTop: isSmall ? 2 : 0,
                      height: !isSmall && "250px",
                    }}
                  >
                    <Typography variant="caption" color={"white"}>
                      Or continue with
                    </Typography>

                    <Box
                      width={50}
                      height={50}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      // onClick={handleGoogleLogin}
                      sx={{
                        background: "#011F2D",
                        cursor: "pointer",
                        "&:hover": {
                          border: 1,
                          borderColor: "#11B1F4",
                          background: "none",
                        },
                      }}
                    >
                      <img
                        style={{ width: "45px" }}
                        src="/Images/login-g.png"
                        alt="login"
                      />
                    </Box>

                    <Box
                      width={50}
                      height={50}
                      bgcolor={"#011F2D"}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        border: 1,
                        borderColor: "#11B1F4",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ width: "20px" }}
                        src="/Images/login-p.png"
                        alt="login"
                      />
                    </Box>
                    {/* <Box
                      width={50}
                      height={50}
                      borderRadius={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        background: "#011F2D",
                        cursor: "pointer",
                        "&:hover": {
                          border: 1,
                          borderColor: "#11B1F4",
                          background: "none",
                        },
                      }}
                    >
                      <img
                        style={{ width: "28px" }}
                        src="/Images/login-m.png"
                        alt="login"
                      />
                    </Box> */}
                  </Box>
                </Box>
              </Stack>

              {/* {numberState === "otp" ? ( */}

              {/* <Box position={"relative"} sx={{}}> */}
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={{ md: "row", xs: "column" }}
                gap={2}
                justifyContent={isSmall && "start"}
              >
                <AvatarGroups />

                <Box display={"flex"} flexDirection={"column"} marginLeft={3}>
                  {" "}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      // fontWeight: 300,
                      fontFamily: "Montserrat",
                      marginBottom: 1,
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => navigate("/login")}
                      sx={{ color: "white" }}
                    >
                      Login
                    </Link>
                  </Typography>
                  <Typography variant="caption" color={"white"}>
                    100+ Users joined last Month
                  </Typography>
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignupOTPVerifyPage;
