import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, IconButton, alpha, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogoutPopup({ open, setOpen, handleSubmit }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundColor: "white",
            padding: 2,
            width: isMobile ? "100%" : "350px",
            height: "100%",
            paddingY: 4,
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "gray",
              margin: 1,
              "&:hover": {
                color: "#11B1F4",
                backgroundColor: "transparent",
              },
            }}
            size="medium"
            onClick={handleClose}
          >
            <CloseIcon fontSize="medium" sx={{ color: "gray" }} />
          </IconButton>
          <img
            style={{
              width: 65,
              height: 65,
              marginTop: 4,
            }}
            src="/Images/popups/cancel-symbol.png"
            alt=""
          />
          <DialogTitle
            sx={{
              color: "black",
              fontWeight: 600,
            }}
          >
            Logout?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                color: "gray",
                textAlign: "center",
              }}
              id="alert-dialog-slide-description"
            >
              You quitting already?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: "#F41111",
                color: "white",
                textTransform: "none",
                paddingX: { xs: 1, sm: 2, md: 2 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 14 },
                "&:hover": {
                  backgroundColor: alpha("#F41111", 0.5),
                },
              }}
              onClick={handleSubmit}
            >
              Ready to Leave
            </Button>
            <Button
              sx={{
                backgroundColor: "#58B112",
                color: "white",
                textTransform: "none",
                paddingX: { xs: 1, sm: 2, md: 2 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 14 },
                "&:hover": {
                  backgroundColor: alpha("#58B112", 0.5),
                },
              }}
              onClick={handleClose}
            >
              Keep Playing
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
