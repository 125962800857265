import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  // useMediaQuery,
} from "@mui/material";
import React from "react";
// import { useNavigate } from "react-router-dom";
import "./future.styles.css";
import FutureSwiper from "./FutureSwiper";
import GameSliderHomePage from "./HomePageSlider";
import { useTheme } from "@emotion/react";
// import { useTheme } from "@emotion/react";

const FutureComponent = () => {
  // const theme = useTheme();
  // const mobile = useMediaQuery(theme.breakpoints.down("md"));

  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const zIndex = 10;

  return (
    <>
      <Container>
        <Box
          sx={{
            marginTop: { md: 15, xs: 10 },
            marginBottom: { md: 2, xs: 1 },
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ md: "16px", sm: "30px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            sx={{ color: "#FFFFFF" }}
          >
            Feature
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "24px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={1.2}
            sx={{ color: "#FFFFFF" }}
          >
            <Box component="span" sx={{ color: "#11B1F4" }}>
              Combining
            </Box>{" "}
            the Concept of Options and Sports Betting Within a{" "}
            <Box component="span" sx={{ color: "#11B1F4" }}>
              Single Game
            </Box>
          </Typography>
          {/* <Box
            sx={{
              // position: "relative",
              marginY: 4,
              width: "100%",
            }}
          > */}
          <Box
            className="menu-card"
            sx={{
              width: "100%",
              height: { md: "100px", xs: "48px" },
              background:
                "linear-gradient(109deg, rgba(15, 16, 60, 0.13) 12.78%, rgba(9, 146, 223, 0.31) 51.05%, rgba(15, 16, 60, 0.10) 94.01%)",
              borderRadius: "8px",
              // border: 1,
              // marginY: { xs: 4, md: 12 },
              mt: { xs: 4, md: 12 },
              mb: isMobile ? 4 : 8,
              // borderColor: "white",
              boxShadow: "0 1px 5px gray", // Default shadow for the other sides
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 20,
            }}
          >
            <Typography
              fontSize={{ md: "22px", xs: "10px" }}
              fontFamily={"Space Grotesk"}
              fontWeight={400}
              marginLeft={{ xs: 1, sm: 10, md: 20 }}
              sx={{
                color: "gray",
                "&:hover": { color: "#10ADEF" },
                cursor: "pointer",
                zIndex: zIndex,
              }}
            >
              Create Account
            </Typography>

            {/* <img
                style={{ width: "230px", height: "230px" }}
                src="/Images/element-cIrcle.png"
                alt=""
              /> */}
            <Box
              sx={{
                width: { xs: "78px", md: "200px" },
                height: { xs: "78px", md: "200px" },
                border: 1.5,
                borderColor: "#26325b",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "58px", md: "147px" },
                  height: { xs: "58px", md: "147px" },
                  border: 1.5,
                  borderColor: "#26325b",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "dashed",
                }}
              >
                {" "}
                <Typography
                  fontSize={{ md: "22px", xs: "10px" }}
                  fontFamily={"Space Grotesk"}
                  fontWeight={400}
                  sx={{
                    color: "white",
                    "&:hover": { color: "#10ADEF" },
                    cursor: "pointer",
                    zIndex: zIndex,
                  }}
                >
                  Play Game
                </Typography>
              </Box>
              <Box
                className="future-background-gradient"
                sx={{
                  zIndex: zIndex - 2,
                }}
              />
            </Box>
            <Typography
              fontSize={{ md: "22px", xs: "10px" }}
              fontFamily={"Space Grotesk"}
              fontWeight={400}
              marginRight={{ xs: 1, sm: 10, md: 20 }}
              sx={{
                color: "gray",
                "&:hover": { color: "#10ADEF" },
                cursor: "pointer",
                zIndex: zIndex,
              }}
            >
              Earn CHAMPS
            </Typography>
          </Box>
          {/* </Box> */}
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              // background:
              //   "linear-gradient(to right, #0F103C 20%, #0a5b92  50%, #0F103C 80%)",
              // border: 1,
              // border: 1,
              // borderBlockEndColor: "white",
              borderRadius: "8px",
              marginTop: { md: 8, xs: 6 },
              height: { md: "80px", xs: "50px" },
              boxShadow: "0 1px 5px gray", // Default shadow for the other sides
            }}
            // style={boxStyle}
          >
            <Typography
              fontSize={{ md: 16, xs: 10 }}
              fontFamily={"Space Grotesk"}
              fontWeight={400}
              sx={{
                color: "gray",
                "&:hover": { color: "#10ADEF" },
                cursor: "pointer",
              }}
            >
              Create Account
            </Typography>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                fontSize={{ md: 16, xs: 10 }}
                fontFamily={"Space Grotesk"}
                fontWeight={400}
                sx={{
                  color: "white",
                  "&:hover": { color: "#10ADEF" },
                  cursor: "pointer",
                }}
              >
                Play Game
              </Typography>
            </Box>

            <Typography
              fontSize={{ md: 16, xs: 10 }}
              fontFamily={"Space Grotesk"}
              fontWeight={400}
              sx={{
                color: "gray",
                "&:hover": { color: "#10ADEF" },
                cursor: "pointer",
              }}
            >
              Earn CHAMPS
            </Typography>
          </Box> */}
        </Box>
      </Container>

      {/* <Container
        sx={{
          // marginY: 10,
          marginTop: { md: 10, xs: 5 },
          overflow: "auto",
          // height: "content-fit",
        }}
      > */}
      {/* <Grid
        container
        spacing={{ md: 4, xs: 6 }}
        sx={{ display: "flex", padding: 2, overflow: "visible" }}
        // height={{ md: "600px", xs: "100%" }} */}
      {/* > */}
      {/* Future Swiper */}
      {/* <Container
        sx={{
          paddingTop: { md: 10, sm: 5, xs: 0 },
          marginBottom: { xs: 5, sm: 10, md: 14 },
        }}
      >
        <FutureSwiper zIndex={zIndex} />
      </Container> */}
      {/* <FutureSwiper cardData={cardData} /> */}

      {/* <Grid item xs={12} md={4}>
            <Box
              className="future-card"
              sx={{
                width: "100%",
                height: { md: "461px", sm: "461px", xs: "250px" },
                borderRadius: "8px",
                boxShadow: "0 1px 5px gray", // Default shadow for the other sides

                paddingY: 2,
                display: "flex",

                gap: mobile ? 2 : 4,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "0.8s",
                // borderColor: "white",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  height: "60%",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="/Images/slider-bw.png"
                  alt=""
                />
              </Box>
              <Typography
                variant="caption"
                fontSize={{ md: "35px", sm: "18px", xs: "16px" }}
                fontFamily={"Space Grotesk"}
                fontWeight={500}
                textAlign={"center"}
                sx={{ color: "#FFFFFF" }}
              >
                Biggest Winner
              </Typography>
              <Button
                className="future-button"
                sx={{
                  paddingY: mobile ? "6px" : "10px",
                  color: "white",
                  fontWeight: 500,
                  paddingX: "25px",
                  textTransform: "none",
                  fontFamily: "Space Grotesk",
                  //   marginBottom: 2,
                  display: "none",
                  fontSize: mobile ? 8 : 14,
                  borderRadius: "64px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",

                  "&:hover": {
                    backgroundColor: " #10ADEF95",
                  },
                }}
                variant="contained"
                onClick={() => navigate("/landing/biggestwinner")}
              >
                Get Started
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              className="future-card"
              sx={{
                width: "100%",

                height: { md: "461px", sm: "461px", xs: "250px" },
                paddingY: 2,
                borderRadius: "8px",
                display: "flex",
                gap: mobile ? 2 : 4,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "0.8s",
                boxShadow: "0 1px 5px gray", // Default shadow for the other sides
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  height: "60%",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="/Images/slider-bl.png"
                  alt=""
                />
              </Box>
              <Typography
                variant="caption"
                fontSize={{ md: "35px", sm: "18px", xs: "16px" }}
                fontFamily={"Space Grotesk"}
                fontWeight={500}
                textAlign={"center"}
                sx={{ color: "#FFFFFF" }}
              >
                Biggest Loser
              </Typography>

              <Button
                className="future-button"
                sx={{
                  paddingY: mobile ? "6px" : "10px",
                  color: "white",
                  fontWeight: 500,
                  paddingX: "25px",
                  textTransform: "none",
                  display: "none",
                  fontFamily: "Space Grotesk",
                  //   marginBottom: 2,
                  fontSize: mobile ? 8 : 14,
                  borderRadius: "64px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",

                  "&:hover": {
                    backgroundColor: " #10ADEF95",
                  },
                }}
                variant="contained"
                onClick={() => navigate("/landing/biggestloser")}
              >
                Get Started
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              className="future-card"
              sx={{
                width: "100%",
                height: { md: "461px", sm: "461px", xs: "250px" },
                borderRadius: "8px",
                paddingY: 2,
                display: "flex",
                gap: mobile ? 2 : 4,
                flexDirection: "column",
                alignItems: "center",
                transition: "0.8s",
                justifyContent: "center",
                marginBottom: { xs: 5, sm: 5, md: 2 },
                boxShadow: "0 1px 5px gray", // Default shadow for the other sides
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  height: "60%",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src="/Images/slider-dt.png"
                  alt=""
                />
              </Box>
              <Typography
                variant="caption"
                fontSize={{ md: "35px", sm: "18px", xs: "16px" }}
                fontFamily={"Space Grotesk"}
                fontWeight={500}
                textAlign={"center"}
                sx={{ color: "#FFFFFF" }}
              >
                Crypto Parlay
              </Typography>
              <Button
                className="future-button"
                sx={{
                  paddingY: mobile ? "6px" : "10px",
                  color: "white",
                  fontWeight: 500,
                  paddingX: "25px",
                  textTransform: "none",
                  fontFamily: "Space Grotesk",
                  //   marginBottom: 2,
                  display: "none",
                  fontSize: mobile ? 8 : 14,
                  borderRadius: "64px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",

                  "&:hover": {
                    backgroundColor: " #10ADEF95",
                  },
                }}
                variant="contained"
                onClick={() => navigate("/landing/cryptoparlay")}
              >
                Get Started
              </Button>
            </Box>
          </Grid> */}
      {/* </Grid> */}
      {/* 
        <Stack
          direction={"row"}
          overflow={"auto"}
          spacing={4}
          sx={{ width: mobile ? "1200px" : "object-fit" }}
        >
          <Box
            sx={{
              width: "402px",
              height: { md: "461px", xs: "object-fit" },
              borderRadius: "8px",
              border: 1,
              display: "flex",
              gap: 4,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderColor: "white",
            }}
          >
            <Box
              sx={{
                width: "80%",
                height: "60%",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src="/Images/slider-bw.png"
                alt=""
              />
            </Box>
            <Typography
              variant="caption"
              fontSize={{ md: "35px", sm: "16px", xs: "12px" }}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
              textAlign={"center"}
              sx={{ color: "#FFFFFF" }}
            >
              Biggest Winner
            </Typography>
          </Box>

          <Box
            sx={{
              width: { md: "402px", xs: "80%" },
              height: { xs: "200px", md: "object-fit" },
              paddingY: mobile ? 0 : 5,
              borderRadius: "8px",
              border: 1,
              display: "flex",
              gap: 4,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderColor: "white",
              //   marginTop: -4,
            }}
          >
            <Box
              sx={{
                width: "80%",
                height: "60%",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src="/Images/slider-bl.png"
                alt=""
              />
            </Box>
            <Typography
              variant="caption"
              fontSize={{ md: "35px", sm: "16px", xs: "12px" }}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
              textAlign={"center"}
              sx={{ color: "#FFFFFF" }}
            >
              Biggest Loser
            </Typography>

            <Button
              sx={{
                paddingY: "10px",
                color: "white",
                fontWeight: 500,
                paddingX: "25px",
                textTransform: "none",
                fontFamily: "Space Grotesk",
                marginBottom: 2,
                borderRadius: "64px",
                background:
                  "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",

                "&:hover": {
                  backgroundColor: " #10ADEF95",
                },
              }}
              variant="contained"
              onClick={() => {}}
            >
              Get Started
            </Button>
          </Box>

          <Box
            sx={{
              width: "402px",
              height: { md: "461px", xs: "object-fit" },
              borderRadius: "8px",
              border: 1,
              display: "flex",
              gap: 4,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderColor: "white",
            }}
          >
            <Box
              sx={{
                width: "80%",
                height: "60%",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src="/Images/slider-dt.png"
                alt=""
              />
            </Box>
            <Typography
              variant="caption"
              fontSize={{ md: "35px", sm: "16px", xs: "12px" }}
              fontFamily={"Space Grotesk"}
              fontWeight={500}
              textAlign={"center"}
              sx={{ color: "#FFFFFF" }}
            >
             Crypto Parlay
            </Typography>
          </Box>
        </Stack> */}
      {/* </Container> */}

      {/* NEW UI */}
      <GameSliderHomePage />
    </>
  );
};

export default FutureComponent;
