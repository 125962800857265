import React, { useState } from "react";
import { merge } from "lodash";
import BaseOptionChart from "../Chart/BaseOptionChart";
// import ReactApexChart from "../Chart";
import {
  Box,
  Button,
  CircularProgress,
  // Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@emotion/react";
import { FiCalendar } from "react-icons/fi";
import { useQuery } from "react-query";
import { getWinAndLossChartData } from "../../Services/Service";

const StatsChart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userId = sessionStorage.getItem("userId");

  const [seriesData, setSeriesData] = useState("month");
  const [currentTab, setCurrentTab] = useState("1");
  const [isWins, seIsWins] = useState(true);
  // const [years, setYears] = useState([]);
  // const [dataFilter, setDataFilter] = useState();

  const [totalAdded, setTotalAdded] = useState([]);

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
    if (e.target.id === "2") {
      return seIsWins(false);
    } else seIsWins(true);
  };

  const tabs = [
    {
      id: "1",
      tabTitle: "Wins",
      chartData: [
        {
          data: [
            {
              name: "Total",
              data: totalAdded,
            },
            // { name: "Total Expenses", data: [10, 34, 13, 56, 77, 88, 99, 77, 45] },
          ],
        },
      ],
    },
    {
      id: "2",
      tabTitle: "Loss",
      chartData: [
        {
          data: [
            {
              name: "Total Income",
              data: totalAdded,
            },
            // { name: "Total Expenses", data: [10, 34, 13, 56, 77, 88, 99, 77, 45] },
          ],
        },
      ],
    },
  ];

  const options = [
    { option: "Weekly", value: "week" },
    { option: "Monthly", value: "month" },
    { option: "Yearly", value: "year" },
  ];

  //   const handleChangeSeriesData = (event) => {
  //     setSeriesData(Number(event.target.value));
  //   };

  const chartOptions = merge(BaseOptionChart(), {
    colors: ["#11B1F4"],
    stroke: {
      width: 4,
    },
    legend: { position: "top", horizontalAlign: "right" },
    chart: {
      foreColor: "#ffffff",
      toolbar: {
        show: false,
      },
      // id: "area-chart",
    },
    // xaxis: {
    //   type: "datetime",
    // },
    xaxis: {
      categories:
        seriesData === "month"
          ? [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ]
          : seriesData === "week"
          ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
          : [2022, 2023, 2024, 2025],
    },

    grid: {
      borderColor: "#ffffff12",
      borderWidth: 1,
    },

    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `<div class="custom-tooltip-wallet">
        <span class="custom-tooltip-wallet-title" >Total</span>
        <div class="custom-tooltip-wallet-value">${w.globals.series[seriesIndex][dataPointIndex]} <span>USDC</span> </div></div>`;
      },
      enabled: true,
      style: {
        fontSize: "20px",
        fontFamily: "Roboto",
      },
      theme: "#085A7D",
      borderColor: "#ffffff12",
      borderWidth: 2,
    },
  });

  const handleChangeSeriesData = (event) => {
    setSeriesData(event.target.value);
  };

  const getCurrentWeek = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 0);
    const difference = now - startOfYear;
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const currentWeek = Math.floor(difference / oneWeek);

    return currentWeek;
  };

  console.log(getCurrentWeek(), "getCurrentWeek");

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const { data, isLoading } = useQuery(
    ["Winandloss", userId, isWins, seriesData],
    async () => {
      try {
        await delay(200);
        const response = await getWinAndLossChartData({
          userId,
          isWins: isWins,
          seriesData: seriesData,
        });
        setTotalAdded(processData(response.data?.data));
        return response.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  console.log(data, "WAL");

  // Create a new Date object
  var currentDate = new Date();

  // Get the current year
  var currentYear = currentDate.getFullYear();

  const processData = (data) => {
    // Create an array to represent all months (from 1 to 12)
    if (seriesData === "month") {
      const allMonths = Array.from({ length: 12 }, (_, index) => index + 1);
      // Create a map to store totalSpend for each month
      const monthMap = new Map(
        data
          .filter((entry) => entry._id.year === currentYear)
          .map((item) => [item._id?.month, item?.totalAdded?.toFixed(2)])
      );
      console.log(monthMap, "monthMap");

      // Fill in the gaps with zero for months without data
      const processedData = allMonths.map((month) => monthMap.get(month) || 0);
      console.log(processedData, "MA");
      return processedData;
    } else if (seriesData === "week") {
      const allMonths = Array.from({ length: 7 }, (_, index) => index + 1);
      // Create a map to store totalSpend for each month
      const monthMap = new Map(
        data
          .filter((entry) => entry._id.week === getCurrentWeek())
          .map((item) => [item._id?.dayOfWeek, item?.totalAdded?.toFixed(2)])
      );

      // Fill in the gaps with zero for months without data
      const processedData = allMonths.map((month) => monthMap.get(month) || 0);
      console.log(processedData, "PD");
      return processedData;
    } else if (seriesData === "year") {
      const allMonths = Array.from(
        { length: 5 },
        (_, index) => index + (currentYear - 2)
      );
      // Create a map to store totalSpend for each month
      const monthMap = new Map(
        data.map((item) => [item._id?.year, item?.totalAdded?.toFixed(2)])
      );

      console.log(monthMap, "yearMap");

      // Fill in the gaps with zero for months without data
      const processedData = allMonths.map((month) => monthMap.get(month) || 0);
      console.log(processedData, "MA");
      return processedData;
    }
  };

  console.log(totalAdded, "TADD");

  console.log(seriesData, "SD");

  return (
    <>
      <Box
        sx={{
          background: "#03364C",
          borderRadius: "27px",
          paddingY: 4,
          overflow: "auto",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
            <Typography
              variant="h5"
              fontWeight={500}
              sx={{ marginX: 4, marginBottom: 2 }}
            >
              Stats
            </Typography>

            <Box
              sx={{
                background: "#042836",
                padding: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "100px",
                width: "250px",
                height: 40,
              }}
              gap={1}
            >
              {tabs.map((tab, index) => (
                <Button
                  key={index}
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                  sx={{
                    color: "white",
                    width: "100%",
                    height: "100%",
                    paddingY: 2,
                    borderRadius: "19px",
                    "&.Mui-disabled": {
                      background:
                        "linear-gradient(109.59deg, #11b1f4, #085a7d)",
                      color: "white",
                    },
                    textTransform: "none",
                  }}
                >
                  {tab.tabTitle}
                </Button>
              ))}
            </Box>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            marginRight={isMobile ? 1 : 2}
            sx={{
              paddingBottom: isMobile ? 10 : 0,
            }}
          >
            <FiCalendar size={28} />
            <TextField
              select
              fullWidth
              value={seriesData}
              SelectProps={{ native: true }}
              onChange={handleChangeSeriesData}
              sx={{
                width: "82px",
                "& fieldset": { border: "0 !important" },
                "& select": {
                  pl: 1,
                  py: 0.5,

                  pr: "24px !important",
                  typography: "subtitle2",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: 0.75,
                  bgcolor: "background.neutral",
                },
                "& .MuiNativeSelect-icon": {
                  top: 4,
                  right: 0,
                  width: 20,
                  height: 20,
                },
              }}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.option}
                </option>
              ))}
            </TextField>
          </Box>
        </Stack>

        {isLoading ? (
          <>
            {/* <Box>
              <Skeleton
                sx={{ height: 190, marginX: 2, borderRadius: 5 }}
                animation="wave"
                variant="rectangular"
              />
            </Box> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 250,
                marginBottom: 2,
              }}
            >
              <CircularProgress sx={{ color: "#11B1F4" }} />
            </Box>
          </>
        ) : (
          <>
            <Box>
              {tabs.map(
                (tab, index) =>
                  currentTab === `${tab.id}` && (
                    <Box
                      key={index}
                      sx={{ mt: 3, mx: isMobile ? 0 : 3 }}
                      dir="ltr"
                    >
                      {tab.chartData.map((item) => (
                        <ReactApexChart
                          type="area"
                          series={item.data}
                          // series={[
                          //   {
                          //     name: "Total Added",
                          //     data: formatChartData(monthlyData),
                          //   },
                          // ]}
                          options={chartOptions}
                          height={250}
                          width={isMobile ? 300 : "100%"}
                        />
                      ))}
                    </Box>
                  )
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default StatsChart;
