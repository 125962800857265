import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  IconButton,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const DegentimeLanding = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg">
      <IconButton
        aria-label="delete"
        color="secondary"
        sx={{
          marginTop: 5,
        }}
        onClick={() => navigate(-1)}
        // sx={{ position: "absolute", top: 25, left: 25 }}
      >
        <ArrowBackIcon
          sx={{
            color: "#11b1f4",
          }}
          fontSize="large"
        />
      </IconButton>
      <Box
        sx={{
          height: isMobile ? "300px" : "100%",
          width: "100%",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "22px",
          }}
          src="/Images/degen-cs.png"
          alt=""
        />

        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: alpha("#000000", 0.4),
              width: "200px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "25px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "white",
              }}
            >
              {" "}
              Coming Soon...
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DegentimeLanding;
