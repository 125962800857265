import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <Box
        sx={{
          textTransform: "none",
          color: "white",
          border: 1,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": { color: "#10ADEF" },
          height: 40,
          width: 40,
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosIcon fontSize={"16px"} />
      </Box>
    </>
  );
};

export default BackButton;
