import { Box } from "@mui/material";
import React from "react";

const MultiplayerGameStatus = ({ status }) => {
  return (
    <>
      {" "}
      <Box
        sx={{
          height: 35,
          width: 80,
          borderRadius: "20px",
          background: status === "Ongoing" ? "#00FF66" : "#FF0000",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: status === "Ongoing" ? "black" : "white",
        }}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Box>
    </>
  );
};

export default MultiplayerGameStatus;
